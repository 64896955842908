import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import clsx from "clsx";
import { memo } from "react";

const MuiCheckbox = ({
  label,
  labelId,
  className,
  helperText,
  fullWidth,
  variant,
  sx,
  value,
  error,
  ...props
}) => {
  return (
    <FormControl
      className={clsx(className)}
      fullWidth={fullWidth}
      variant={variant}
      sx={sx}
      error={error}
    >
      <FormControlLabel
        control={<Checkbox checked={value} labelid={labelId} value={value} />}
        {...props}
        label={label}
      />
      {helperText && (
        <FormHelperText id={`${labelId}-helper`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(MuiCheckbox);
