import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ShipmentTitle = () => {
  const shipmentData = useSelector((state) => state.shipment);

  return (
    <div className="flex text-xs">
      <span><Link className="hover:text-amber-500" to="/shipments">Shipments</Link></span>
      <span className="mx-1">{`>`}</span>
      <span>{shipmentData.customerShipmentReferenceId}</span>
    </div>
  );
};

export default ShipmentTitle;
