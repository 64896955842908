import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getExportingCompanies = createAsyncThunk(
  "exportingCompany/getCompanies",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/exporting-company`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const getAllExportingCompany = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_APIURL}/api/v1/exporting-company`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const getExportingCompanyById = createAsyncThunk(
  "exportingCompany/getById",
  async ({ userId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/exporting-company/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

const exportingCompaniesSlice = createSlice({
  name: "exportingCompanies",
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExportingCompanies.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export default exportingCompaniesSlice.reducer;
