import { useDispatch, useSelector } from "react-redux";
import {
  deleteFileByHash,
  downloadErteknyilatkozat,
  getShipmentFiles,
  uploadDocument,
} from "../store/shipmentSlice";
import FileBlock from "../components/FileBlock";
import { Button, CircularProgress } from "@mui/material";
import { openSnackbar } from "../../../store/variablesSlice";
import DeleteFileDialog from "../components/dialogs/DeleteFileDialog";
import { useState } from "react";
import { styled } from "@mui/system";
import FileBlockCreateAdd from "../components/FileBlockCreateAdd";

const StyledButton = styled(Button)`
  &.MuiButton-root.Mui-disabled {
    background-color: #f3f3f3;
  }
`;

const ShipmentDocumentTab = ({ shipmentId, ...props }) => {
  const dispatch = useDispatch();
  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
  const [fileHashToDelete, setFileHashToDelete] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const shipmentData = useSelector((state) => state.shipment);

  const handleUpload = async (event) => {
    const files = Array.from(event.target.files);
    console.log("event.fileType:", event.fileType);

    if (files.length > 0) {
      for (const file of files) {
        // files.forEach((file, index) => {
        const formData = new FormData();
        formData.append(`uploadedFile`, file);
        formData.append(`relationId`, Number(shipmentId));
        formData.append(`relationType`, "shipment");
        formData.append(`relationFileType`, event.fileType);
        formData.append(
          `destination`,
          `${shipmentData.customerShipmentReferenceId}`
        );

        await dispatch(uploadDocument(formData))
          .then((results) => console.log(results))
          .catch((e) => console.error(e));
      }

      dispatch(openSnackbar({ message: "File(s) uploaded successfully" }));
      dispatch(getShipmentFiles({ shipmentId: Number(shipmentId) }));
    } else {
      console.error("No files selected");
    }
  };

  const handleDeleteDialog = async (event) => {
    setDeleteFileDialogOpen(true);
    setFileHashToDelete(event.fileHash);
  };

  const handleDelete = async () => {
    dispatch(deleteFileByHash({ fileHash: fileHashToDelete }))
      .then(() => {
        setDeleteFileDialogOpen(false);
        setFileHashToDelete(null);
        dispatch(openSnackbar({ message: "File deleted successfully" }));
      })
      .catch((e) => console.error(e));
  };

  const handleErteknyilatkozatDownload = async () => {
    return new Promise((resolve, reject) => {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${shipmentData.customerShipmentReferenceId} - Erteknyilatkozat.pdf`;
  
      dispatch(
        downloadErteknyilatkozat({
          shipmentCustomerReferenceId: shipmentData.customerShipmentReferenceId,
          pdfTypes: "valueStatement",
        })
      )
        .then((res) => {
          link.href = URL.createObjectURL(new Blob([res.payload.data]));
          link.click();
          setButtonLoading(false);
          return resolve(true);
        })
        .catch((e) => {
          console.error(e);
          setButtonLoading(false);
          return reject({ error: e });
        });
    })
  };

  const handleCmrDownload = async () => {
    return new Promise((resolve, reject) => {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${shipmentData.customerShipmentReferenceId} - CMR.pdf`;
  
      dispatch(
        downloadErteknyilatkozat({
          shipmentCustomerReferenceId: shipmentData.customerShipmentReferenceId,
          pdfTypes: "cmr",
        })
      )
        .then((res) => {
          link.href = URL.createObjectURL(new Blob([res.payload.data]));
          link.click();
          setButtonLoading(false);
          return resolve(true);
        })
        .catch((e) => {
          console.error(e);
          setButtonLoading(false);
          return reject({ error: e });
        });
    })
  };

  const handleMeghatalmazasDownload = async () => {
    return new Promise((resolve, reject) => {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${shipmentData.customerShipmentReferenceId} - Meghatalmazas.pdf`;

      dispatch(
        downloadErteknyilatkozat({
          shipmentCustomerReferenceId: shipmentData.customerShipmentReferenceId,
          pdfTypes: "authorization",
        })
      )
        .then((res) => {
          link.href = URL.createObjectURL(new Blob([res.payload.data]));
          link.click();
          setButtonLoading(false);
          return resolve(true);
        })
        .catch((e) => {
          console.error(e);
          setButtonLoading(false);
          return reject({ error: e });
        });
    });
  };

  if (!shipmentData.files) return null;

  return (
    <>
      <div className="bg-[#fbfbfb] p-3">
        <FileBlockCreateAdd
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`Kalkuláció`}
          disabled={true}
          fileType={`calculation`}
        />

        <div className="separator"></div>

        <FileBlockCreateAdd
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleGenerate={handleErteknyilatkozatDownload}
          handleDelete={handleDeleteDialog}
          label={`Értéknyilatkozat`}
          fileType={`value-statement`}
        />

        <div className="separator"></div>

        <FileBlockCreateAdd
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleGenerate={handleCmrDownload}
          handleDelete={handleDeleteDialog}
          label={`CMR`}
          fileType={`cmr`}
        />

        <div className="separator"></div>

        <FileBlockCreateAdd
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleGenerate={handleMeghatalmazasDownload}
          handleDelete={handleDeleteDialog}
          label={`Meghatalmazás`}
          fileType={`letter-of-auth`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`AWB`}
          fileType={`awb`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`Ground Handling pickup authorization`}
          fileType={`groundHandlingPickupAuthorization`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`Ground Handling release notification`}
          fileType={`groundHandlingReleaseNotification`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`T1 from arrival to CC`}
          fileType={`t1FromArrivaltoCc`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`CMR from arrival to CC`}
          fileType={`cmrFromArrivalToCc`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`CMR from arrival to CC signed and closed`}
          fileType={`cmrFromArrivalToCcSignedClosed`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`IOSS CC docs`}
          fileType={`iossCcDocs`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`Inspection docs`}
          fileType={`inspectionDocs`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`CMR from CC to depot`}
          fileType={`cmrFromCcToDepot`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`CMR from CC to depot signed & closed`}
          fileType={`cmrFromCcToDepotSignedClosed`}
        />

        <div className="separator"></div>

        <FileBlock
          shipmentData={shipmentData}
          handleUpload={handleUpload}
          handleDelete={handleDeleteDialog}
          label={`Other`}
          fileType={`other`}
        />
      </div>
      <DeleteFileDialog
        dialogOpen={deleteFileDialogOpen}
        handleClose={setDeleteFileDialogOpen}
        onSubmitDelete={handleDelete}
      />
    </>
  );
};

export default ShipmentDocumentTab;
