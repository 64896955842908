import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setSession, signInWithEmailAndPassword } from "../../../services/jwtservice";

export const submitLogin =
  ({ email, password }, navigation) =>
  async (dispatch) => {
    return signInWithEmailAndPassword(email, password)
      .then((response) => {
        //    dispatch(getOneWayNotification());
        //    dispatch(
        //    storeBrowserInfo({
        //        browser: `${browserName} ${fullBrowserVersion}`,
        //        os: `${osName} ${osVersion}`,
        //        resolution: `${window.screen.width}x${window.screen.height}`,
        //    })
        //    );
        navigation("/");
      })
      .catch((errors) => {
        return errors;
        //    return dispatch(
        //    showMessage({
        //        message: `${errors?.data?.errors[0]} (${errors.status})`,
        //        variant: "error",
        //    })
        //    );
      });
  };

export const signInWithEmailAndPasswords = createAsyncThunk(
  "auth/login",
  async ({ email, password, navigation }) => {
    
    let response;

    try {
      response = await axios.post(
        `${process.env.REACT_APP_APIURL}/api/auth/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.accessToken) {
        setSession(response.data.accessToken);
      }

      navigation("/");
    } catch (e) {
      console.log('sign in error:', e);
    }

    return response;
  }
);

export const signInWithToken = createAsyncThunk(
  "auth/token",
  async ({ token }) => {
    
    let response;

    try {
      response = await axios.post(
        `${process.env.REACT_APP_APIURL}/api/auth/access-token`, {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          withCredentials: true,
        }
      );

      // if (response.status === 201) {
        // setSession(response.data.accessToken);
      // setSession(token);
      return response;
      // }
    } catch (e) {
      // console.log(e);
      return e.response;
      // throw new Error('Failed to login with token.')
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    workspaceId: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signInWithEmailAndPasswords.fulfilled, (state, action) => {
      return action.payload.data.user;
    });
    builder.addCase(signInWithToken.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export default authSlice.reducer;
