import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import MuiCheckbox from "../../../components/checkbox/Checkbox";
import ParcelItemBox from "../components/ParcelItemBox";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { update } from "../store/parcelSlice";
import { DateTime } from "luxon";
import { memo } from "react";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const ParcelInfoBlock = (props) => {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations.data);
  const parcelData = useSelector((state) => state.parcel);
  const ddVariables = useSelector((state) => state.variables);
  const systemEnums = useSelector((state) => state.enums);
  const exportingCompanies =
    useSelector((state) => state.exportingCompanies) || [];

  const defaultValues = {
    status: parcelData.status,
    exportingCompanyId: parcelData.exportingCompanyId || 0,
    parcelCustomerId: parcelData.parcelCustomerId || "",
    parcelTrackingId: parcelData.parcelTrackingId || "",
    arrivalToCcAct: null,
    parcelType: parcelData.parcelType,
    parcelDescription: parcelData.parcelDescription || "",
    grossWeight: parcelData.grossWeight
      ? parseFloat(parcelData.grossWeight)
      : 0,
    unitOfWeight: parcelData.unitOfWeight,

    parcelWidth: parcelData.parcelWidth || 0,
    parcelHeight: parcelData.parcelHeight || 0,
    parcelLength: parcelData.parcelLength || 0,

    recipientId: parcelData.recipientCustomerId || "",
    recipientName: parcelData.recipientName,
    recipientCountry: parcelData.recipientCountry,
    recipientCity: parcelData.recipientCity,
    recipientZipCode: parcelData.recipientZipCode,
    recipientTaxId: parcelData.recipientTaxId || "",
    recipientEmail: parcelData.recipientEmail,
    recipientStreetName: parcelData.recipientStreetName,
    recipientHouseNumber: parcelData.recipientHouseNumber,
    recipientAdditionalAddressText: parcelData.recipientAdditionalAddressText,
    recipientPhone: parcelData.recipientPhone,
    recipientAddressNote: parcelData.recipientNotes || "",
    recipientType: parcelData.recipientType,

    ccDateAct: parcelData.customsClearanceDate
      ? DateTime.fromISO(parcelData.customsClearanceDate)
      : null,
    ccSubmissionDate: parcelData.ccSubmissionDate
      ? DateTime.fromISO(parcelData.ccSubmissionDate)
      : null,
    ccCompletionDate: parcelData.ccCompletionDate
      ? DateTime.fromISO(parcelData.ccCompletionDate)
      : null,
    ccInspectionDate: parcelData.ccInspectionDate
      ? DateTime.fromISO(parcelData.ccInspectionDate)
      : null,
    ccDestructionDate: parcelData.ccDestructionDate
      ? DateTime.fromISO(parcelData.ccDestructionDate)
      : null,
    ccLocationId: parcelData.ccLocationId || 0,
    ccStatusId: parcelData.ccStatusId || 0,
    ccId: parcelData.ccId || "",
    inspectionRequestedByAuth: !!parcelData.inspectionRequested,
    ccDocumentInspection: !!parcelData.ccDocumentInspection,
    ccPhysicalInspection: !!parcelData.ccPhysicalInspection,
    consumerProtectionInspection: !!parcelData.consumerProtectionInspection,
    inspectionNotes: parcelData.inspectionNotes || "",

    paymentProofFile: parcelData?.documents?.length
      ? parcelData.documents.filter(
          (obj) => obj.fileType === "paymentProofFileUrl"
        )[0].file
      : "",
    orderFile1: "",
    orderFile2: "",

    lastMileDeliveryPartnerName: parcelData.lastMileDeliveryPartnerName,
    deliveryCountry: parcelData.deliveryCountry,
    contactPersonName: parcelData.contactPersonName,
    contactPersonEmail: parcelData.contactPersonEmail,
    contactPersonPhone: parcelData.contactPersonPhone,
    regionalDeliveryDepotName: parcelData.regionalDeliveryDepotName,
    regionalDeliveryDepotCountry: parcelData.regionalDeliveryDepotCountry,
    regionalDeliveryDepotCity: parcelData.regionalDeliveryDepotAddress,
    deliveryDepotName: parcelData.deliveryDepotName,
    deliveryDepotCountry: parcelData.deliveryDepotCountry,
    deliveryDepotCity: parcelData.deliveryDepotAddress,
    deliveryStatus: 0,
  };

  const {
    // register,
    handleSubmit,
    control,
    // watch,
    // formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    dispatch(
      update({
        payload: {
          ...data,
          grossWeight: parseFloat(data.grossWeight),
        },
        parcelId: parcelData.id,
      })
    );
    // console.log(data)
  };

  const parcelItemsBlock = () => {
    const items = [];
    if (parcelData?.items?.length) {
      let idx;
      for (const item of parcelData.items) {
        items.push(<ParcelItemBox key={`item-${idx}`} itemData={item} />);
        idx++;
      }
    }

    return items;
  };

  // console.log("SUBMISS DÁTUM:", DateTime.fromISO(parcelData.ccSubmissionDate));
  // console.log("BROWSER IANA:", navigator);
  if (
    !locations ||
    !parcelData ||
    !ddVariables ||
    !systemEnums ||
    !exportingCompanies
  )
    return null;

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div>
          <div className="w-full flex justify-between">
            <div className="w-[45%]">
              <InputLabel>{"Status"}</InputLabel>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    color="secondary"
                    variant="standard"
                    size="small"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    label="Status"
                  >
                    {[...ddVariables.SHIPMENT_STATUS]
                      .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
                      .map((item, idx) => (
                        <MenuItem key={`statusKey-${idx}`} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </div>
            <div className="w-[45%]">
              <InputLabel>{"Customer"}</InputLabel>
              <Controller
                control={control}
                name="exportingCompanyId"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    color="secondary"
                    variant="standard"
                    size="small"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    label="Customer"
                  >
                    <MenuItem value={0}>Please select</MenuItem>
                    {exportingCompanies.map((item, idx) => (
                      <MenuItem key={`exportingCompany-${idx}`} value={item.id}>
                        {item.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </div>
          <Button type="submit">Save</Button>
        </div>

        <div className="my-8">
          <Typography variant="h6" component="h4">
            Basic information
          </Typography>
          <div>
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Parcel ID"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="parcelCustomerId"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Parcel ID (last mile partner)"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="parcelTrackingId"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Arrival to CC Actual"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="arrivalToCcAct"
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterLuxon}
                      adapterLocale={navigator.language}
                    >
                      <DateTimePicker
                        {...field}
                        variant="standard"
                        ampm={false}
                        fullWidth
                        label={`${timeZone} time`}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Type of parcel"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="parcelType"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      {Object.entries(systemEnums.parcelType).map(
                        ([key, value], idx) => (
                          <MenuItem key={`pType-${idx}`} value={value}>
                            {value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Parcel description"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="parcelDescription"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Gross weight"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="grossWeight"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="type"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Unit of weight"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="unitOfWeight"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Number of items"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Typography>{parcelData?.items?.length}</Typography>
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Number of HS code"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Typography>{parcelData.numberOfDifferentHsCode}</Typography>
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Parcel width (cm)"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="parcelWidth"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Parcel height (cm)"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="parcelHeight"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Parcel length (cm)"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="parcelLength"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="my-8">
          <Typography variant="h6" component="h4">
            Recipient
          </Typography>
          <div>
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient ID"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientId"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient country"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientCountry"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient street name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientStreetName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient house number"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientHouseNumber"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient additional address text"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientAdditionalAddressText"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient city"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientCity"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient zip code"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientZipCode"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient tax id"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientTaxId"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient email"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientEmail"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient phone"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientPhone"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient address note"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientAddressNote"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Recipient type"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="recipientType"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      {Object.entries(systemEnums.recipientType).map(
                        ([key, value], idx) => (
                          <MenuItem key={`rType-${idx}`} value={value}>
                            {key}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="my-8">
          <Typography variant="h6" component="h4">
            ITEMS
          </Typography>
          <div>{parcelItemsBlock()}</div>
        </div>

        <div className="my-8">
          <Typography variant="h6" component="h4">
            Customs clearance
          </Typography>
          <div>
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"CC submission date & time"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="ccSubmissionDate"
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterLuxon}
                      adapterLocale={navigator.language}
                    >
                      <DateTimePicker
                        {...field}
                        variant="standard"
                        ampm={false}
                        fullWidth
                        label={`${timeZone} time`}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"CC completion date & time"}</InputLabel>
              </div>
              <div className="w-7/12 flex">
                <Controller
                  control={control}
                  name="ccCompletionDate"
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterLuxon}
                      adapterLocale={navigator.language}
                    >
                      <DateTimePicker
                        {...field}
                        variant="standard"
                        ampm={false}
                        fullWidth
                        label={`${timeZone} time`}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
            
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"CC inspection date & time"}</InputLabel>
              </div>
              <div className="w-7/12 flex">
                <Controller
                  control={control}
                  name="ccInspectionDate"
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterLuxon}
                      adapterLocale={navigator.language}
                    >
                      <DateTimePicker
                        {...field}
                        variant="standard"
                        ampm={false}
                        fullWidth
                        label={`${timeZone} time`}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
            
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"CC destruction date & time"}</InputLabel>
              </div>
              <div className="w-7/12 flex">
                <Controller
                  control={control}
                  name="ccDestructionDate"
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterLuxon}
                      adapterLocale={navigator.language}
                    >
                      <DateTimePicker
                        {...field}
                        variant="standard"
                        ampm={false}
                        fullWidth
                        label={`${timeZone} time`}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"CC location"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="ccLocationId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {locations.map((item, idx) => (
                        <MenuItem key={`ccLocation-${idx}`} value={item.id}>
                          {item.locationName}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"CC status"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="ccStatusId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {ddVariables?.CC_STATUS &&
                        ddVariables.CC_STATUS.map((item, idx) => (
                          <MenuItem key={`ccStatus-${idx}`} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div>
              <Controller
                control={control}
                name="ccDocumentInspection"
                render={({ field: { onChange, value } }) => (
                  <MuiCheckbox
                    checked={value}
                    onChange={onChange}
                    error={false}
                    label="CC document inspection"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="ccPhysicalInspection"
                render={({ field: { onChange, value } }) => (
                  <MuiCheckbox
                    checked={value}
                    onChange={onChange}
                    error={false}
                    label="CC physical inspection"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="consumerProtectionInspection"
                render={({ field: { onChange, value } }) => (
                  <MuiCheckbox
                    checked={value}
                    onChange={onChange}
                    error={false}
                    label="Customer Protection inspection"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                    }}
                  />
                )}
              />
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"CC Id"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="ccId"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="type"
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <Controller
                name="inspectionNotes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={false}
                    fullWidth
                    multiline
                    rows={5}
                    label="Inspection notes"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="my-8">
          <Typography variant="h6" component="h4">
            Last mile
          </Typography>
          <div>
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Last mile delivery partner name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="lastMileDeliveryPartnerName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Delivery country"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="deliveryCountry"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Contact person name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="contactPersonName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Contact person email"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="contactPersonEmail"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Contact person phone"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="contactPersonPhone"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Regional delivery depot name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="regionalDeliveryDepotName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Regional delivery depot country"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="regionalDeliveryDepotCountry"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Regional delivery depot city"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="regionalDeliveryDepotCity"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Delivery depot name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="deliveryDepotName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Delivery depot country"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="deliveryDepotCountry"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Delivery depot city"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="deliveryDepotCity"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Last mile delivery status"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={control}
                  name="deliveryStatus"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {Object.entries(systemEnums.lastMileDeliveryStatus).map(
                        ([key, value], idx) => (
                          <MenuItem key={`deliveryStatus-${idx}`} value={key}>
                            {value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="my-8">
          <Typography variant="h6" component="h4">
            Last mile delivery attempts
          </Typography>
          <div></div>
        </div>
      </form>
      {/*locationsData.map((item, idx) => {
        return <div key={idx}>{JSON.stringify(item)}</div>;
      })*/}
    </div>
  );
};

export default memo(ParcelInfoBlock);
