import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import PalletListTable from "../components/PalletListTable";
import ParcelListTable from "../components/ParcelListTable";
import CartonListTable from "../components/CartonListTable";
import CollectionParcelListTable from "../components/CollectionParcelListTable";

const ShipmentParcelListTab = (props) => {
  const [tabValue, setTabValue] = useState("1");
  const shipmentData = useSelector((state) => state.shipment);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <TabContext value={tabValue} sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList sx={{ backgroundColor: '#fbfbfb'}} onChange={handleChange} aria-label="lab API tabs example">
          <Tab label={`Pallet (${shipmentData.nrOfPallets})`} value="1" />
          <Tab label={`Carton (${shipmentData.nrOfCartons})`} value="2" />
          <Tab
            label={`Collection parcel (${shipmentData.nrOfCollectionParcels})`}
            value="3"
          />
          <Tab label={`Parcel (${shipmentData.nrOfParcels})`} value="4" />
        </TabList>
      </Box>
      <div className="flex">
        <TabPanel value="1" sx={{ width: "100%", padding: "24px 0" }}>
          <PalletListTable
            setTabValue={setTabValue}
            shipmentId={shipmentData.id}
            customerShipmentReferenceId={
              shipmentData.customerShipmentReferenceId
            }
          />
        </TabPanel>
        <TabPanel value="2" sx={{ width: "100%", padding: "24px 0" }}>
          <CartonListTable
            setTabValue={setTabValue}
            shipmentId={shipmentData.id}
            customerShipmentReferenceId={
              shipmentData.customerShipmentReferenceId
            }
          />
        </TabPanel>
        <TabPanel value="3" sx={{ width: "100%", padding: "24px 0" }}>
          <CollectionParcelListTable
            setTabValue={setTabValue}
            shipmentId={shipmentData.id}
            customerShipmentReferenceId={
              shipmentData.customerShipmentReferenceId
            }
          />
        </TabPanel>
        <TabPanel value="4" sx={{ width: "100%", padding: "24px 0" }}>
          <ParcelListTable
            setTabValue={setTabValue}
            shipmentId={shipmentData.id}
            customerShipmentReferenceId={
              shipmentData.customerShipmentReferenceId
            }
          />
        </TabPanel>
      </div>
    </TabContext>
  );
};

export default ShipmentParcelListTab;
