import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getShipments = createAsyncThunk("shipments/getShipments", async () => {
  const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/v1/shipment`, {
    headers: {
      "Content-Type": "application/json",
    },
    // withCredentials: true,
  });

  return response;
});

export const getById = createAsyncThunk(
  "shipments/getById",
  async ({ shipmentId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/${shipmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const create = createAsyncThunk(
  "shipments/create",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

const shipmentsAdapter = createEntityAdapter({});

export const { selectAll: selectShipments, selectById: selectProjectById } =
  shipmentsAdapter.getSelectors((state) => state.shipments);

const shipmentsSlice = createSlice({
  name: "shipments",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShipments.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export const { setShipmentsSearchText } = shipmentsSlice.actions;

export default shipmentsSlice.reducer;
