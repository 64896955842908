import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime, Settings } from "luxon";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

// Set Luxon Adapter timezone to user local TZ
Settings.defaultZone = timeZone;

const LineHaulBlock = ({
  lineHaulId,
  companies,
  shippingMethods,
  locations,
  partnerType,
  handleRemove,
  ...props
}) => {
  const [contactPersonName, setContactPersonName] = useState(
    props.partnerData.contactPersonName || ""
  );
  const [contactPersonPhone, setContactPersonPhone] = useState(
    props.partnerData.contactPersonPhone || ""
  );
  const [contactPersonEmail, setContactPersonEmail] = useState(
    props.partnerData.contactPersonEmail || ""
  );
  const { register, setValue } = useFormContext();

  const shipperCompanyId = useWatch({
    name: `${partnerType}[${lineHaulId}].shipperCompanyId`,
  });

  useEffect(() => {
    const companyData = companies.filter(
      (item) => item.id === shipperCompanyId
    )[0];

    if (companyData) {
      setContactPersonName(companyData.contactPersonName);
      setContactPersonPhone(companyData.contactPersonPhone);
      setContactPersonEmail(companyData.contactPersonEmail);
    }
  }, [companies, shipperCompanyId]);

  return (
    <Box>
      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Shipper company name"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Select
            {...register(`${partnerType}[${lineHaulId}].shipperCompanyId`)}
            defaultValue={props.partnerData.shipperCompanyId || 0}
            fullWidth
            color="secondary"
            variant="standard"
            size="small"
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            <MenuItem value={0}>Please select</MenuItem>
            {companies.map((item, idx) => (
              <MenuItem key={`company-${idx}`} value={item.id}>
                {item.companyName}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Shipping vehicle ID"}</InputLabel>
        </div>
        <div className="w-7/12">
          <TextField
            {...register(`${partnerType}[${lineHaulId}].shippingVehicleId`)}
            defaultValue={props.partnerData.shippingVehicleId || ""}
            fullWidth
            variant="standard"
            type="text"
          />
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Shipping method"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Select
            {...register(`${partnerType}[${lineHaulId}].shippingMethod`)}
            defaultValue={props.partnerData.shippingMethod || 0}
            fullWidth
            color="secondary"
            variant="standard"
            size="small"
            MenuProps={{
              disableScrollLock: true,
            }}
            label="Shipping method to EU"
          >
            <MenuItem value={0}>Please select</MenuItem>
            {Object.entries(shippingMethods).map(([key, value], idx) => (
              <MenuItem key={`shmethods-${idx}`} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Truck driver name"}</InputLabel>
        </div>
        <div className="w-7/12">
          <TextField
            {...register(`${partnerType}[${lineHaulId}].truckDriverName`)}
            defaultValue={props.partnerData.truckDriverName || ""}
            fullWidth
            variant="standard"
            type="text"
          />
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Truck driver phone"}</InputLabel>
        </div>
        <div className="w-7/12">
          <TextField
            {...register(`${partnerType}[${lineHaulId}].truckDriverPhone`)}
            defaultValue={props.partnerData.truckDriverPhone || ""}
            fullWidth
            variant="standard"
            type="text"
          />
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Etransport ID"}</InputLabel>
        </div>
        <div className="w-7/12">
          <TextField
            {...register(`${partnerType}[${lineHaulId}].etransportId`)}
            defaultValue={props.partnerData.etransportId || ""}
            fullWidth
            variant="standard"
            type="text"
          />
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Offloading booking slot from"}</InputLabel>
        </div>
        <div className="w-7/12">
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={navigator.language}
          >
            <DateTimePicker
              {...register(
                `${partnerType}[${lineHaulId}].offloadingBookingSlotFrom`
              )}
              onChange={(e) =>
                setValue(
                  `${partnerType}[${lineHaulId}].offloadingBookingSlotFrom`,
                  e
                )
              }
              value={
                props.partnerData.offloadingBookingSlotFrom
                  ? DateTime.fromISO(
                      props.partnerData.offloadingBookingSlotFrom
                    )
                  : null
              }
              variant="standard"
              // sx={{ width: 260 }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
              label={`${timeZone} time`}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Offloading booking slot to"}</InputLabel>
        </div>
        <div className="w-7/12">
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={navigator.language}
            {...register(
              `${partnerType}[${lineHaulId}].offloadingBookingSlotTo`
            )}
          >
            <DateTimePicker
              {...register(
                `${partnerType}[${lineHaulId}].offloadingBookingSlotTo`
              )}
              onChange={(e) =>
                setValue(
                  `${partnerType}[${lineHaulId}].offloadingBookingSlotTo`,
                  e
                )
              }
              value={
                props.partnerData.offloadingBookingSlotTo
                  ? DateTime.fromISO(props.partnerData.offloadingBookingSlotTo)
                  : null
              }
              variant="standard"
              // sx={{ width: 260 }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
              label={`${timeZone} time`}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Partner shipment reference ID"}</InputLabel>
        </div>
        <div className="w-7/12">
          <TextField
            {...register(`${partnerType}[${lineHaulId}].partnerShipmentRefId`)}
            defaultValue={props.partnerData.partnerShipmentRefId || ""}
            fullWidth
            variant="standard"
            type="text"
          />
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Shipping from"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Select
            {...register(
              `${partnerType}[${lineHaulId}].shippingFromLocationId`
            )}
            defaultValue={props.partnerData.shippingFromLocationId || 0}
            fullWidth
            color="secondary"
            variant="standard"
            size="small"
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            <MenuItem value={0}>Please select</MenuItem>
            {locations.map((item, idx) => (
              <MenuItem key={`loadingLocation-${idx}`} value={item.id}>
                {item.locationName}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Shipping to"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Select
            {...register(`${partnerType}[${lineHaulId}].shippingToLocationId`)}
            defaultValue={props.partnerData.shippingToLocationId || 0}
            fullWidth
            color="secondary"
            variant="standard"
            size="small"
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            <MenuItem value={0}>Please select</MenuItem>
            {locations.map((item, idx) => (
              <MenuItem key={`loadingLocation-${idx}`} value={item.id}>
                {item.locationName}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Contact person name"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Typography>{contactPersonName}</Typography>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Contact person phone"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Typography>{contactPersonPhone}</Typography>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Contact person email"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Typography>{contactPersonEmail}</Typography>
        </div>
      </div>

      <div className="flex justify-end">
        <Button onClick={handleRemove}>Remove line haul</Button>
      </div>
    </Box>
  );
};

export default LineHaulBlock;
