import axios from "axios";

export const signInWithEmailAndPassword = (email, password) => {
  return new Promise((resolve, reject) => {
      axios
      .post(`${process.env.REACT_APP_APIURL}/api/auth/login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        console.log('signInWithEmailAndPassword', response);
        if (response.data.accessToken) {
          setSession(response.data.accessToken);

          resolve(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
          // console.log(error.response.data)
          // console.log(error.response.status)
          // console.log(error.response.headers)
        }
      });
  });
};

export const signInWithToken = (token) => {
  console.log('signInWithToken')

  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIURL}/api/auth/access-token`, {},
      {
        headers: { 
          Authorization: `Bearer ${token}`
        },
        withCredentials: true,
      })
      .then(response => {
        if (response.status === 201) {
          setSession(response.data.accessToken)

          resolve(true)
        } else {
          logout()
          reject(new Error('Failed to login with token.'))
        }
      })
      .catch(error => {
        // this.logout();
        reject(new Error('Failed to login with token.'))
      })
  })
}

export const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem('jwt_access_token', accessToken)

    try {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      // console.log('AX DEF:', axios.defaults);
    } catch (e) {
      console.log('axios default beállítása nem sikerült:', e);
    }
  } else {
    console.log('axios default beállítása nem sikerült: nincs access token');
    localStorage.removeItem('jwt_access_token')
    delete axios.defaults.headers.common.Authorization
  }
}

export const logout = (navigate) => {
  localStorage.removeItem('jwt_access_token')
  delete axios.defaults.headers.common.Authorization

  navigate('/login', { replace: true });
}