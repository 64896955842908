import { useContext, useRef, useCallback, Component, useEffect } from "react";
import AppContext from "../../AppContext";
import { useLocation, matchRoutes, useNavigate } from "react-router";
import Layout from "../layout";
import withRouter from "../../services/withrouter";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDynamicDataVariables,
  getEnums,
} from "../../store/variablesSlice";

const PageLayout = () => {
  // const settings = useSelector(({ fuse }) => fuse.settings.current)
  // const defaultSettings = useSelector(({ fuse }) => fuse.settings.defaults)
  // console.log('PAGELAYOUT PROPS:', props);

  const appContext = useContext(AppContext);
  const authData = useSelector((state) => state.auth);
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { routes } = appContext;
  const location = useLocation();
  const { pathname } = location;
  const matched = matchRoutes(routes, pathname)[0];
  const newSettings = useRef(null);
  console.log("matched:", matched);

  //  useEffect(() => {
  //    const runInitialTasks = async () => {
  //      await Promise.all([
  //        dispatch(getAllDynamicDataVariables()),
  //        dispatch(getEnums()),
  //      ]);
  //
  //      setLoading(false)
  //    };
  //
  //    runInitialTasks();
  //  }, []);

  useEffect(() => {
    if (authData.email) {
      console.log("getAllDynamicDataVariables lefut");
      dispatch(getAllDynamicDataVariables()).then(() => dispatch(getEnums()));
    }
  }, [dispatch, authData]);

  const shouldAwaitRender = useCallback(() => {
    console.log("shouldAwaitRender inner");
    let _newSettings;
    /**
     * On Path changed
     */
    // if (prevPathname !== pathname) {
    if (matched && matched.route) {
      /**
       * if matched route has settings
       */

      const routeSettings = matched;

      _newSettings = routeSettings;
    }

    // if (!_.isEqual(newSettings.current, _newSettings)) {
    newSettings.current = _newSettings;
    // }
  }, [matched]);

  // if (loading) return null;

  // shouldAwaitRender();

  // console.log(newSettings.current);
  const isFunctionComponent =
    typeof matched.route.component === "function" &&
    !Component.prototype.render;
  // console.log("isFunctionComponent", isFunctionComponent);



  if (isFunctionComponent) {
    // console.log("isFunctionComponentre FUT");
    matched.route.component({ navigate });
    return <></>;
  } else {
    // console.log("LAYOUTRA FUT");
    return (
      <>
        <Layout {...matched} />
      </>
    );
  }
}

export default withRouter(PageLayout);
