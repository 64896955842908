import { useDispatch } from "react-redux";
import withRouter from "../../services/withrouter";
import { memo, useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { getById } from "./store/shipmentsSlice";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ShipmentInfoTab from "./tabs/ShipmentInfo";
import { getLocations } from "../locations/store/locationsSlice";
import { getCompanies } from "../companies/store/companiesSlice";
import CommentBlock from "./components/CommentBlock";
import ShipmentTitle from "./components/ShipmentTitle";
import ShipmentDocumentTab from "./tabs/ShipmentDocument";
import ShipmentParcelListTab from "./tabs/ShipmentParcelList";
import { cleanState, getShipmentFiles } from "./store/shipmentSlice";
import { getExportingCompanies } from "../exporting-companies/store/exportingCompaniesSlice";
import T1Button from "./components/T1Button";

const ShipmentView = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingOthers, setLoadingOthers] = useState(true);
  const shipmentId = props.params.shipmentId;
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // useEffect, ami lefut, amikor a komponens mountolódik vagy shipmentId megváltozik
  useEffect(() => {
        dispatch(getLocations()).then(() => {
          dispatch(getCompanies()).then(() => {
            dispatch(getExportingCompanies()).finally(() => {
              setLoadingOthers(false);
            });
        })})
  }, []);

  useEffect(() => {
    const runInitialTasks = async () => {
      await Promise.all([
        dispatch(getById({ shipmentId: Number(shipmentId) })),
      ]);
    };

    dispatch(cleanState());
    runInitialTasks().then(() =>
      dispatch(getShipmentFiles({ shipmentId: Number(shipmentId) })).then(() =>
        setLoading(false)
      )
    );
  }, [shipmentId, dispatch]);

  if (loading || loadingOthers) return null;

  console.log("shipmentview render after LOADING");
  return (
    <Box width={"100%"}>
      <ShipmentTitle />
      <TabContext value={tabValue} sx={{ width: "80%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Shipment info" value="1" />
            <Tab label="Documents" value="2" />
            <Tab label="Parcel list" value="3" />
            <T1Button shipmentId={shipmentId} />
          </TabList>
        </Box>
        <div className="flex">
          <TabPanel value="1" sx={{ width: "80%" }}>
            <ShipmentInfoTab loading={loading} />
          </TabPanel>
          <TabPanel value="2" sx={{ width: "80%" }}>
            <ShipmentDocumentTab shipmentId={Number(shipmentId)} />
          </TabPanel>
          <TabPanel value="3" sx={{ width: "100%" }}>
            <ShipmentParcelListTab />
          </TabPanel>
          {tabValue === "3" ? null : (
            <div className="w-[20%] mt-3">
              <div>
                <CommentBlock
                  label={"Comments / notes"}
                  multiline
                  rows={6}
                  fullWidth
                />
              </div>
              <div>History</div>
            </div>
          )}
        </div>
      </TabContext>
      {/*locationsData.map((item, idx) => {
        return <div key={idx}>{JSON.stringify(item)}</div>;
      })*/}
      
    </Box>
  );
};

export default withRouter(memo(ShipmentView));
