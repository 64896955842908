import { Box, Button, lighten } from "@mui/material";
import withRouter from "../../services/withrouter";
import DataTable from "../../components/datatable/Datatable";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "./components/BreadCrumb";

const columns = [
  {
    accessorKey: "id",
    header: "ID",
    size: 10, //small column
    maxSize: 10,
    grow: false, //don't allow this column to grow (if layoutMode is grid)
  },
  {
    accessorKey: "companyName",
    header: "Company name",
  },
  {
    accessorKey: "companyTypeName",
    header: "Type",
    size: 100, //small column
    maxSize: 100,
    grow: false,
  },
  {
    accessorKey: "countryName",
    header: "Country",
  },
  {
    header: "Address",
    accessorFn: (row) => `${row.city} ${row.address}`,
  },
];

const CompaniesList = (props) => {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <BreadCrumb />
      Companies
      <DataTable
        endpointUrl={`/api/v1/company`}
        predefinedColumns={columns}
        enableDensityToggle={false}
        initialState={{
          density: "compact",
        }}
        // enableTopToolbar={false}
        enableColumnActions={false}
        muiTableBodyRowProps={({ row }) => ({
          //implement row selection click events manually
          onClick: () => {
            navigate(`/companies/${row.id}`);
            // console.log('DAVIDD id:', JSON.stringify(row) )
          },
          sx: {
            cursor: "pointer",
          },
        })}
        renderTopToolbar={({ table }) => {
          const handleDeactivate = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert("deactivating " + row.getValue("name"));
            });
          };

          const handleActivate = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert("activating " + row.getValue("name"));
            });
          };

          const handleRemove = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert("contact " + row.getValue("name"));
            });
          };

          return (
            <Box
              sx={(theme) => ({
                backgroundColor: lighten(
                  theme.palette.background.default,
                  0.05
                ),
                display: "flex",
                gap: "0.5rem",
                p: "8px",
                justifyContent: "end",
              })}
            >
              <Box>
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                  <Button
                    color="info"
                    // onClick={handleDeactivate}
                    variant="contained"
                    disableElevation
                    component={Link}
                    to="/companies/add"
                  >
                    New
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    </div>
  );
};

export default withRouter(CompaniesList);
