import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import routes from "./routes";
import "./App.css";
import Authorization from "./services/authorization";
import AppContext from "./AppContext";
import PageLayout from "./components/page-layout/page-layout";
import { ThemeProvider, createTheme } from "@mui/material";
import SnackBar from "./components/snackbar/SnackBar";
import Sidebar from "./components/sidebar";
import ErrorDialog from "./pages/shipments/components/dialogs/ErrorDialog";
import PlannerTruckerDialog from "./components/planner/PlannerTruckerDialog";

function App() {
  // console.log("app started");
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  const theme = createTheme({
    typography: {
      // fontFamily: 'Chakra Petch',
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      primary: {
        main: "#ff9f0c",
      },
      secondary: {
        // main: '#edf2ff',
        main: "#444",
      },
    },
    props: {
      MuiButtonBase: {
        TouchRippleProps: {
          classes: {
            root: {
              color: "#fff",
            },
          },
        },
      },
    },
    components: {
      // Name of the component
      // MuiButton: {
      //   styleOverrides: {
      //     // Name of the slot
      //     root: {
      //       // Some CSS
      //       textTransform: "none",
      //       fontFamily: "inherit",
      //     },
      //   },
      // },
      MuiListItemIcon: {
        // Name of the rule
        styleOverrides: {
          root: {
            minWidth: 35,
          }
        },
      },
      MuiTouchRipple: {
        // Name of the rule
        child: {
          // Some CSS
          color: "#fff",
        },
      },
      // MuiSelect: {
      //   styleOverrides: {
      //     standard: {
      //       borderColor: '#FFFFFF',
      //     },
      //     root: {
      //       color: "#FFFFFF",
      //       borderColor: "#FFFFFF !important",
      //       ":before": {
      //         color: "#FFFFFF",
      //         borderColor: "ffffff40 !important",
      //       },
      //     }
      //   }
      // },
      // MuiInput: {
      //   styleOverrides: {
      //     root: {
      //       color: "#FFFFFF",
      //       // borderColor: "#FFFFFF !important",
      //       ":before": {
      //         borderColor: "#ffffff40 !important",
      //       },
      //       '& .Mui-disabled': {
      //         color: "#FFFFFF",
      //       },
      //     },
      //   },
      //
      // },
      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       color: "#FFFFFF",
      //       borderColor: "#FFFFFF !important",
      //       ":before": {
      //         borderColor: "#FFFFFF !important",
      //       },
      //       fontSize: 14,
      //     },
      //     label: {
      //       color: "#FFFFFF",
      //     },
      //   }
      // },
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     root: {
      //       color: "#FFFFFF",
      //       borderColor: "#FFFFFF",
      //       transition: (['border-color', 'box-shadow']),
      //       ":before": {
      //         borderColor: "#FFFFFF !important",
      //       },
      //       "&.Mui-focused fieldset": {
      //         borderColor: "green !important",
      //         borderWidth: "1px !important",
      //       },
      //       // "&:hover fieldset": {
      //       //   borderColor: "transparent !important",
      //       // },
      //       '& .Mui-focused': {
      //         borderColor: "#FFFFFF !important",
      //       },
      //       "fieldset": {
      //         borderColor: "rgba(255,255,255,.3) !important",
      //         transition: 'border-color 0.2s ease-in-out',
      //       },
      //     },
      //     notchedOutline: {
      //       color: "#FFFFFF",
      //       borderColor: "rgba(255,255,255,.3)",
      //     },
      //     label: {
      //       color: "#FFFFFF",
      //     },
      //     input: {
      //       padding: 9,
      //     },
      //   }
      // },
      // MuiInputLabel: {
      //   styleOverrides: {
      //     root: {
      //       color: "#FFFFFF",
      //       fontFamily: "inherit",
      //       disabled: {
      //         color: "#FFFFFF",
      //       },
      //       '& .Mui-disabled': {
      //         color: "#FFFFFF",
      //       },
      //       fontSize: 14,
      //     },
      //
      //   }
      // },
      // MuiFormLabel: {
      //   styleOverrides: {
      //     root: {
      //       color: "#FFFFFF",
      //       fontFamily: "inherit",
      //       disabled: {
      //         color: "#FFFFFF",
      //       },
      //       '& .Mui-disabled': {
      //         color: "#FFFFFF",
      //       }
      //     },
      //     '& .Mui-disabled': {
      //       color: "#FFFFFF",
      //     }
      //   }
      // },
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       color: "#FFFFFF",
      //       fontFamily: "inherit",
      //       disabled: {
      //         color: "#FFFFFF",
      //       },
      //       '& .Mui-disabled': {
      //         color: "#FFFFFF",
      //       }
      //     },
      //     '& .Mui-disabled': {
      //       color: "#FFFFFF",
      //     }
      //   }
      // },
    },
  });

  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Authorization>
            <Sidebar />
            <PageLayout />
            <PlannerTruckerDialog />
          </Authorization>
          <SnackBar />
          <ErrorDialog />
        </ThemeProvider>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
