import React, { memo, useEffect } from "react";
import Box from "@mui/material/Box";

function Layout(props) {
  console.log("Rendering Layout...");
  useEffect(() => {
    document.title = `${props.route.title} - DragonSP`; // Quick solution
  }, [props.route.title]);

  return (
    <div className="flex">
      {props.route.layout ? (
        <Box
          component="nav"
          sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
        </Box>
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${240}px)` },
        }}
      >
        {props.route.layout ? (
          <div className="flex">
            {props.route.children
              ? props.route.children[0].component
              : React.cloneElement(props.route.component, { ...props })}
          </div>
        ) : (
          props.route.component
        )}
      </Box>
    </div>
  );
}

export default memo(Layout);
