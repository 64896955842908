import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

const DisconnectDialog = ({
  dialogOpen,
  handleClose,
  onSubmitDisconnect,
  entityName,
  idsToTransfer,
  ...props
}) => {
  const methods = useForm({});

  return (
    <Dialog open={dialogOpen} onClose={() => handleClose(false)}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(() => onSubmitDisconnect())}
          autoComplete="off"
        >
          <DialogTitle>Disconnect from shipment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Warning: this action will remove the{" "}
              {entityName.toLocaleLowerCase()} from this shipment
              <br />
              {entityName}(s) ({idsToTransfer.join(",")}) transfer to
              UNALLOCATED SHIPMENT.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)}>Cancel</Button>
            <Button type="submit">Disconnect</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default DisconnectDialog;
