import { logout } from "../services/jwtservice";
import { ShipmentsList, ShipmentView } from "../pages/shipments";
import UsersList from "../pages/users/UsersList";
import CompaniesList from "../pages/companies/CompaniesList";
import LocationsList from "../pages/locations/LocationsList";
import Dashboard from "../pages/dashboard/Dashboard";
import { ParcelView } from "../pages/parcels";
import Login from "../pages/login";
import CompanyAdd from "../pages/companies/CompanyAdd";
import CompanyEdit from "../pages/companies/CompanyEdit";
import LocationAdd from "../pages/locations/LocationAdd";
import LocationEdit from "../pages/locations/LocationEdit";
import { UserEdit } from "../pages/users";
import UserAdd from "../pages/users/UserAdd";
import ShipmentCreate from "../pages/shipments/ShipmentCreate";
import Planner from "../pages/planner/Planner";

const routes = [
  {
    path: "/",
    title: "Dashboard",
    role: ["user", "admin"],
    layout: true,
    component: <Dashboard />,
  },
  {
    path: "/planner",
    title: "Planner",
    role: ["user", "admin"],
    layout: true,
    component: <Planner />,
  },
  {
    path: "/users",
    title: "Users",
    role: ["user", "admin"],
    layout: true,
    component: <UsersList />,
  },
  {
    path: "/users/add",
    title: "Add new user",
    role: ["admin"],
    layout: true,
    component: <UserAdd />,
  },
  {
    path: "/users/:userId",
    title: "Edit user",
    role: ["admin"],
    layout: true,
    component: <UserEdit />,
  },
  {
    path: "/shipments",
    title: "Shipments",
    role: ["user", "admin"],
    layout: true,
    component: <ShipmentsList />,
  },
  {
    path: "/shipments/add",
    title: "Add new shipment",
    role: ["user", "admin"],
    layout: true,
    component: <ShipmentCreate />,
  },
  {
    path: "/shipments/:shipmentId",
    title: "Shipments page",
    role: ["user", "admin"],
    layout: true,
    component: <ShipmentView />,
  },
  {
    path: "/parcels/:parcelId",
    title: "Parcel detail page",
    role: ["user", "admin"],
    layout: true,
    component: <ParcelView />,
  },
  {
    path: "/companies",
    title: "Companies",
    role: ["user", "admin"],
    layout: true,
    component: <CompaniesList />,
  },
  {
    path: "/companies/add",
    title: "Add new company",
    role: ["user", "admin"],
    layout: true,
    component: <CompanyAdd />,
  },
  {
    path: "/companies/:companyId",
    title: "Edit company",
    role: ["user", "admin"],
    layout: true,
    component: <CompanyEdit />,
  },
  {
    path: "/locations",
    title: "Locations",
    role: ["user", "admin"],
    layout: true,
    component: <LocationsList />,
  },
  {
    path: "/locations/add",
    title: "Add new location",
    role: ["user", "admin"],
    layout: true,
    component: <LocationAdd />,
  },
  {
    path: "/locations/:locationId",
    title: "Edit location",
    role: ["user", "admin"],
    layout: true,
    component: <LocationEdit />,
  },
  {
    index: true,
    path: "/login",
    title: "Login",
    layout: false,
    role: [],
    component: <Login />,
  },
  {
    path: "/logout",
    title: "Logout",
    layout: false,
    role: [],
    component: ({ navigate }) => {
      console.log("LOGOUT!");
      logout(navigate);
    },
  },
];

export default routes;
