import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const transferPallet = createAsyncThunk(
  "pallet/transfer",
  async (payload) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/pallet/transfer`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const disconnectPallet = createAsyncThunk(
  "pallet/disconnect",
  async (payload) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/pallet/disconnect`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const groupingPallet = createAsyncThunk(
  "pallet/grouping",
  async (payload) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/pallet/grouping`,
      {
        palletsData: {
          ids: payload.ids,
        },
        groupEntityType: payload.groupEntityType,
        groupEntityCustomerRefName: payload.groupEntityCustomerRefName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updatePallet = createAsyncThunk(
  "pallet/update",
  async ({ data, palletId }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/pallet/${palletId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updatePalletBulkStatus = createAsyncThunk(
  "pallet/updatePalletBulkStatus",
  async ({ shipmentId, status }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/pallet/bulk-status`,
      {
        actualShipmentId: shipmentId,
        status: status,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const deletePallet = createAsyncThunk(
  "pallet/delete",
  async ({ palletId }) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_APIURL}/api/v1/pallet/${palletId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);
