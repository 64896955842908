import { Snackbar } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../../store/variablesSlice";

const SnackBar = () => {
  const dispatch = useDispatch();
  const snackbarSettings = useSelector((state) => state.snackbar);
  const [state] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const { open, message } = snackbarSettings;

  const handleClose = () => {
    dispatch(closeSnackbar())
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
      message={message}
      key={vertical + horizontal}
    />
  );
};

export default SnackBar;
