import { InputLabel, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteFileByHash, getParcelFiles, uploadDocument } from "../store/parcelSlice";
import { Link } from "react-router-dom";
import CloudDownload from "@mui/icons-material/CloudDownload";
import FileBlock from "../../shipments/components/FileBlock";
import { openSnackbar } from "../../../store/variablesSlice";
import { memo, useState } from "react";
import DeleteFileDialog from "../../shipments/components/dialogs/DeleteFileDialog";

const ParcelDocumentTab = ({ parcelId, ...props }) => {
  const dispatch = useDispatch();
  const parcelData = useSelector((state) => state.parcel);
  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
  const [fileHashToDelete, setFileHashToDelete] = useState(null);

  const parcelDocumentsBlock = () => {
    const items = [];
    if (parcelData?.documents?.length) {
      let i = 0;
      for (const item of parcelData.documents) {
        items.push(
          <div key={`link-${i}`} className="relative mb-5 flex">
            <div className="w-5/12 self-center">
              <InputLabel>{item.fileType}</InputLabel>
            </div>
            <div className="w-7/12">
              <TextField
                fullWidth
                variant="standard"
                type="text"
                value={item.file}
                InputProps={{
                  endAdornment: (
                    <Link to={`${item.file}`} target="_blank">
                      <CloudDownload />
                    </Link>
                  ),
                  readOnly: true,
                  sx: { cursor: "default" },
                }}
              />
            </div>
          </div>
        );
        i++;
      }
    }

    return items.length ? items : <Typography>No files available</Typography>;
  };

  const handleUpload = async (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 0) {
      for (const file of files) {
        // files.forEach((file, index) => {
        const formData = new FormData();
        formData.append(`uploadedFile`, file);
        formData.append(`relationId`, Number(parcelId));
        formData.append(`relationType`, "parcel");
        formData.append(`relationFileType`, event.fileType);
        formData.append(
          `destination`,
          `${parcelData.customerShipmentReferenceId}`
        );

        await dispatch(uploadDocument(formData))
          .then((results) => console.log(results))
          .catch((e) => console.error(e));
      }

      dispatch(openSnackbar({ message: "File(s) uploaded successfully" }));
      dispatch(getParcelFiles({ parcelId: Number(parcelId) }));
    } else {
      console.error("No files selected");
    }
  };

  const handleDeleteDialog = async (event) => {
    setDeleteFileDialogOpen(true);
    setFileHashToDelete(event.fileHash);
  };

  const handleDelete = async () => {
    dispatch(deleteFileByHash({ fileHash: fileHashToDelete }))
      .then(() => {
        setDeleteFileDialogOpen(false);
        setFileHashToDelete(null);
        dispatch(openSnackbar({ message: "File deleted successfully" }));
      })
      .catch((e) => console.error(e));
  };

  if (!parcelData.files) return null;

  return (
    <div className="bg-[#fbfbfb] p-3">
      <FileBlock
        shipmentData={parcelData}
        handleUpload={handleUpload}
        handleDelete={handleDeleteDialog}
        label={`Inspection files`}
        fileType={`inspectionFiles`}
      />

      <div className="separator"></div>

      <FileBlock
        shipmentData={parcelData}
        handleUpload={handleUpload}
        handleDelete={handleDeleteDialog}
        label={`Payment proof file(s)`}
        fileType={`paymentProofFile`}
      />

      <div className="separator"></div>

      <FileBlock
        shipmentData={parcelData}
        handleUpload={handleUpload}
        handleDelete={handleDeleteDialog}
        label={`Order file(s)`}
        fileType={`orderFiles`}
      />

      <div className="separator"></div>

      <FileBlock
        shipmentData={parcelData}
        handleUpload={handleUpload}
        handleDelete={handleDeleteDialog}
        label={`Other`}
        fileType={`other`}
      />

      <div className="separator"></div>

      <div>{parcelDocumentsBlock()}</div>

      <DeleteFileDialog
        dialogOpen={deleteFileDialogOpen}
        handleClose={setDeleteFileDialogOpen}
        onSubmitDelete={handleDelete}
      />
    </div>
  );
};

export default memo(ParcelDocumentTab);
