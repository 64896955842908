"use client";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, matchRoutes, useNavigate } from "react-router";
import { signInWithEmailAndPasswords, submitLogin } from "./store/loginSlice";
import * as yup from "yup";
import Checkbox from "../../components/checkbox/Checkbox";
import Alert from "@mui/material/Alert";
import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AppContext from "../../AppContext";

export default function Login(props) {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { routes } = appContext;
  const location = useLocation();
  const { pathname } = location;
  const matched = matchRoutes(routes, pathname)[0];
  const [showAlertBox, setShowAlertBox] = useState(null);

  useEffect(() => {
    document.title = `${matched.route.title} - DragonSP`; // Quick solution
  }, [matched.route.title]);

  const schema = yup.object().shape({
    loginEmail: yup
      .string()
      .email("login.YOU_MUST_ENTER_VALID_EMAIL")
      .required("login.YOU_MUST_ENTER_EMAIL"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    register,
    submit,
    handleSubmit,
    control,
    watch,
    // formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    dispatch(
      signInWithEmailAndPasswords({
        email: data.email,
        password: data.password,
        navigation,
      })
      // submitLogin({ email: data.email, password: data.password }, navigation)
    )
      .then((response) => {
        if (response.statusCode === 404) {
          setShowAlertBox(response.message);
        }
      })
      .catch((error) => {})
      .finally(() => {
        // setDialog(false);
      });
  };

  const passClickHandler = async (values) => {
    console.log("ok");
    /*
    dispatch(forgotPassword({ email: values.loginEmail }))
      .then((response) => {
        dispatch(
          showMessage({
            message: `${t("login.FORGOT_PASSWORD_EMAIL_SENT")}`,
            variant: "success",
          })
        );
      })
      .finally(() => {
        setDialog(false);
      });
    */
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <section className="h-screen">
          <div className="h-full">
            {/* <!-- Left column container with background--> */}
            <div className="g-6 flex h-full flex-wrap items-center justify-center">
              {/* <!-- Right column container --> */}
              <div className="mb-12 md:mb-0 md:w-6/12 lg:w-4/12 xl:w-4/12 xl:max-w-md lg:max-w-md">
                {showAlertBox ? (
                  <div className="mb-6">
                    <Alert severity="error">{showAlertBox}</Alert>
                  </div>
                ) : null}
                {/* <!--Sign in section--> */}

                {/* <!-- Email input --> */}
                <div className="relative mb-6">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                        label={"E-mail address"}
                      />
                    )}
                  />
                </div>

                {/* <!-- Password input --> */}
                <div className="relative mb-6">
                  <Controller
                    control={control}
                    name="password"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="standard"
                        type="password"
                        label={"Password"}
                      />
                    )}
                  />
                </div>

                <div className="mb-6 flex items-center justify-between">
                  {/* <!-- Remember me checkbox --> */}
                  <Checkbox id="remember-me" label="Remember me" />

                  {/* <!--Forgot password link--> */}
                  <Button disableElevation color="secondary">
                    Forgot password
                  </Button>
                </div>

                {/* <!-- Login button --> */}
                <div className="text-center">
                  <Button
                    variant="outlined"
                    type="submit"
                    disableElevation
                    color="primary"
                  >
                    Sign In
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}
