import styled from "@emotion/styled";
import { Button, Icon, InputLabel, CircularProgress } from "@mui/material";
import { memo, useState } from "react";
import { downloadFileByHash } from "../store/shipmentSlice";
import { useDispatch, useSelector } from "react-redux";
// import { styled } from "@mui/system";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledButton = styled(Button)`
  &.MuiButton-root.Mui-disabled {
    background-color: #f3f3f3;
  }
`;

const FileBlockCreateAdd = ({
  shipmentData,
  handleUpload,
  handleGenerate,
  handleDelete,
  deleteDialog,
  disabled,
  label,
  fileType,
  ...props
}) => {
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);
  const userAuthData = useSelector((state) => state.auth);

  const handleDownload = async ({ fileName, fileHash }) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `${fileName}`;

    await dispatch(downloadFileByHash({ fileHash: fileHash }))
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.payload.data]));
        link.click();
      })
      .catch((e) => console.log(e));
  };

  const handleDeleteDialog = async ({ fileHash }) => {
    handleDelete({ fileHash: fileHash });
  };

  return (
    <div className="relative">
      <div className="flex justify-between">
        <div className="w-5/12 self-center">
          <InputLabel
            sx={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
          >
            {label}
          </InputLabel>
        </div>
        <div className="">
          <Button
            component="label"
            role={undefined}
            variant="contained"
            disabled={!!disabled}
            tabIndex={-1}
            sx={{
              fontSize: 12,
              marginRight: "0.5rem",
            }}
            startIcon={
              <Icon className="material-symbols-outlined" sx={{ fontSize: 20 }}>
                upload
              </Icon>
            }
          >
            Add
            <VisuallyHiddenInput
              type="file"
              multiple
              onChange={(e) => handleUpload({ fileType: fileType, ...e })}
            />
          </Button>

          <StyledButton
            onClick={async () => {
              setButtonLoading(true);
              const res = await handleGenerate();
              if (res) {
                setButtonLoading(false);
              }
            }}
            disabled={buttonLoading || !!disabled}
            style={{ position: "relative" }}
          >
            {buttonLoading && (
              <CircularProgress size={24} style={{ position: "absolute" }} />
            )}
            Generate
          </StyledButton>
        </div>
      </div>
      {shipmentData?.files
        .filter((file) => file.relationFileType === fileType)
        .map((file, idx) => (
          <div className="text-sm py-1 flex" key={`file-${idx}`}>
            <div
              className="w-4/5 hover:cursor-pointer hover:text-sky-600"
              onClick={(e) => {
                console.log("DL clicked");
                handleDownload({
                  fileName: file.filename,
                  fileHash: file.fileHash,
                  ...e,
                });
              }}
            >
              {file.filename}
            </div>

            {userAuthData?.accessRightId === 1 ? (
              <div>
                <Icon
                  className="material-symbols-outlined hover:cursor-pointer hover:text-amber-600"
                  sx={{ fontSize: 20 }}
                  onClick={(e) => {
                    console.log("Delete clicked");
                    handleDeleteDialog({ fileHash: file.fileHash });
                  }}
                >
                  delete
                </Icon>
              </div>
            ) : null}
          </div>
        ))}
    </div>
  );
};

export default memo(FileBlockCreateAdd);
