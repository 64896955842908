import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getById = createAsyncThunk(
  "shipments/getById",
  async ({ shipmentId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/${shipmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const create = createAsyncThunk("shipments/create", async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/v1/shipment`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
});

export const update = createAsyncThunk(
  "shipments/update",
  async ({ payload, shipmentId }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/${shipmentId}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updatePlannerData = createAsyncThunk(
  "shipments/updatePlannerData",
  async ({ payload, shipmentId }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/${shipmentId}/planner`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const uploadDocument = createAsyncThunk(
  "shipments/upload",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/file`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  }
);

export const sendT1Request = createAsyncThunk(
  "shipments/sendT1Request",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/t1-email`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const getParcelsByShipmentId = createAsyncThunk(
  "shipments/getParcelsByShipmentId",
  async ({ shipmentId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/${shipmentId}/parcels`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const getAllCarton = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_APIURL}/api/v1/carton`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const getAllShipment = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_APIURL}/api/v1/shipment`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const getAllPallet = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_APIURL}/api/v1/pallet`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const getAllCollectionParcel = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_APIURL}/api/v1/collection-parcel/all`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const getShipmentFiles = createAsyncThunk(
  "shipments/getShipmentFiles",
  async ({ shipmentId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/${shipmentId}/files`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const downloadFileByHash = createAsyncThunk(
  "shipments/downloadFileByHash",
  async ({ fileHash }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/file/filenamebyhash/${fileHash}`,
      {
        //headers: {
        //  "Content-Type": "application/json",
        //},
        responseType: "blob",
      }
    );

    return response;
  }
);

export const deleteFileByHash = createAsyncThunk(
  "shipments/deleteFileByHash",
  async ({ fileHash }) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_APIURL}/api/v1/file/${fileHash}`,
      {}
    );

    return response;
  }
);

export const downloadErteknyilatkozat = createAsyncThunk(
  "shipments/downloadErteknyilatkozat",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/pdf-generator/generate`,
      payload,
      { responseType: "blob" }
    );

    return response;
  }
);

export const downloadBulkTExport = createAsyncThunk(
  "shipments/downloadAllTExcel",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/excel/export-all-t-excel`,
      payload,
      { responseType: "blob" }
    );

    return response;
  }
);

const shipmentAdapter = createEntityAdapter({});

export const { selectAll: selectShipments, selectById: selectProjectById } =
  shipmentAdapter.getSelectors((state) => state.shipment);

const initialState = {
  lastMileDeliveryPartners: [],
  lineHaulPartners: [],
  shippingToEuPartners: [],
  files: [],
  pallets: [],
  cartons: [],
  collectionParcels: [],
  parcels: [],
};

const shipmentSlice = createSlice({
  name: "shipment",
  initialState: initialState,
  reducers: {
    cleanState() {
      return initialState;
    },
    addShippingToEuPartners(state) {
      state.shippingToEuPartners.push({}); // Add new empty object to lineHaulBlocks
    },
    removeShippingToEuPartner(state, action) {
      state.shippingToEuPartners = state.shippingToEuPartners.filter(
        (partner, i) => i !== action.payload.idx
      );
    },
    addLineHaulBlock(state) {
      state.lineHaulPartners.push({}); // Add new empty object to lineHaulBlocks
    },
    removeLineHaulBlock(state, action) {
      state.lineHaulPartners = state.lineHaulPartners.filter(
        (partner, i) => i !== action.payload.idx
      );
    },
    addLastMileDeliveryPartnerBlock(state) {
      state.lastMileDeliveryPartners.push({}); // Add new empty object to lastMileDeliveryPartners
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getById.fulfilled, (state, action) => {
      return { ...state, ...action.payload.data };
    });
    builder.addCase(update.fulfilled, (state, action) => {
      return action.payload.data;
    });
    builder.addCase(deleteFileByHash.fulfilled, (state, action) => {
      return {
        ...state,
        files: state.files.filter(
          (file) => file.fileHash !== action.meta.arg.fileHash
        ),
      };
    });
    builder.addCase(getShipmentFiles.fulfilled, (state, action) => {
      return { ...state, files: action.payload.data };
    });
    builder.addCase(getParcelsByShipmentId.fulfilled, (state, action) => {
      return { ...state, parcels: [...state.parcels, ...action.payload.data] };
    });
    builder.addCase(uploadDocument.fulfilled, (state, action) => {});
  },
});

export const {
  cleanState,
  addLineHaulBlock,
  removeLineHaulBlock,
  addLastMileDeliveryPartnerBlock,
  addShippingToEuPartners,
  removeShippingToEuPartner,
} = shipmentSlice.actions;
export default shipmentSlice.reducer;
