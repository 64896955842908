import {
  Box,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Button,
  Select,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import MuiCheckbox from "../../../components/checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { DateTime, Settings } from "luxon";
import {
  addLastMileDeliveryPartnerBlock,
  addLineHaulBlock,
  removeLineHaulBlock,
  addShippingToEuPartners,
  removeShippingToEuPartner,
  create,
} from "../store/shipmentSlice";
import LineHaulBlock from "../components/LineHaulBlock";
import LastMileDeliveryPartnerBlock from "../components/LastMileDeliveryPartnerBlock";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { openErrorDialog, openSnackbar } from "../../../store/variablesSlice";
import { yupResolver } from "@hookform/resolvers/yup";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

// Set Luxon Adapter timezone to user local TZ
Settings.defaultZone = timeZone;

const schema = yup.object().shape({
  status: yup.number().required("Please select shipment status"),
  exportingCompanyId: yup
    .number()
    .required("Please select an exporting company"),
  shipmentId: yup.string().required("Please enter a shipment ID"),
  cbm: yup.number().notRequired(),
  customerShipmentReferenceId: yup
    .string()
    .required("Please enter a customer shipment ID"),
  totalNrCartons: yup
    .number()
    .required("Please enter the total number of cartons"),
  totalNrParcels: yup
    .number()
    .required("Please enter the total number of parcels"),
  totalWeight: yup.number().required("Please enter the gross weight"),
  arrivalLocationId: yup.number().required("Please select an arrival location"),
  shippingVehicleId: yup.string().required("Please select a shipping vehicle"),
  shippingMethod: yup.string().required("Please enter a shipping method"),
  shippingCompanyShipmentId: yup
    .string()
    .required("Please enter a shipping company shipment ID (AWB)"),
  regOfShipmentFromCustomerAct: yup
    .string()
    .required("Please enter the registration of shipment from customer act"),
  arrivalToEuEst: yup
    .string()
    .required("Please enter the estimated arrival to EU"),
  arrivalToEuAct: yup.string().when("status", {
    is: (val) => ![1, 2, 3].includes(val),
    then: () =>
      yup.string().required("Please enter the actual date of arrival to EU"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  groundHandlingNotificationEst: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4].includes(val),
    then: () =>
      yup
        .string()
        .required(
          "Please enter the estimated date of Ground handling notification for pickup"
        ),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  groundHandlingNotificationAct: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 6].includes(val),
    then: () =>
      yup
        .string()
        .required(
          "Please enter the actual date of Ground handling notification for pickup"
        ),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  pickupEst: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 6].includes(val),
    then: () =>
      yup.string().required("Please enter the estimated date of Pickup"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  pickupAct: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 5, 6].includes(val),
    then: () => yup.string().required("Please enter the actual date of Pickup"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  arrivalToCcEst: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 5, 6].includes(val),
    then: () =>
      yup.string().required("Please enter the estimated date of Arrival to CC"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  arrivalToCcAct: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 5, 6, 7, 8].includes(val),
    then: () =>
      yup.string().required("Please enter the actual date of Arrival to CC"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  customsClearanceSubEst: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 5, 6, 7, 8].includes(val),
    then: () =>
      yup
        .string()
        .required(
          "Please enter the estimated date of Customs cleareance submission"
        ),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  customsClearanceCompEst: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 5, 6, 7, 8].includes(val),
    then: () =>
      yup
        .string()
        .required(
          "Please enter the estimated date of Customs cleareance completion"
        ),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  customsClearanceCompAct: yup.string().when("status", {
    is: (val) => [11, 12, 13, 14].includes(val),
    then: () =>
      yup
        .string()
        .required(
          "Please enter the actual date of Customs cleareance completion"
        ),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  pickupByLineHaulEst: yup.string().when("status", {
    is: (val) => [12, 13, 14].includes(val),
    then: () =>
      yup
        .string()
        .required("Please enter the estimated date of Pickup by line haul"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  pickupByLineHaulAct: yup.string().when("status", {
    is: (val) => [12, 13, 14].includes(val),
    then: () =>
      yup
        .string()
        .required("Please enter the actual date of Pickup by line haul"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  arrivalToRegionalDepoEst: yup.string().when("status", {
    is: (val) => [12, 13, 14].includes(val),
    then: () =>
      yup
        .string()
        .required(
          "Please enter the estimated date of Arrival to regional depot"
        ),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  arrivalToRegionalDepoAct: yup.string().when("status", {
    is: (val) => [14].includes(val),
    then: () =>
      yup
        .string()
        .required("Please enter the actual date of Arrival to regional depot"),
    otherwise: () => yup.string().notRequired().nullable(),
  }),
  mrnId: yup.string().when("status", {
    is: (val) => ![1, 2, 3, 4, 6].includes(val),
    then: () => yup.string().required("Please enter the MRN ID"),
    otherwise: () => yup.string().notRequired(),
  }),
  ccLocationId: yup.number().when("status", {
    is: (val) => ![1, 2, 3, 4, 5, 6, 7, 8].includes(val),
    then: () => yup.number().required("Please select CC location"),
    otherwise: () => yup.number().nullable(),
  }),
  internationalShipperCompanyId: yup.number().when("status", {
    is: (val) => ![1, 2, 3].includes(val),
    then: () =>
      yup.number().required("Please select International shipper company"),
    otherwise: () => yup.number().nullable(),
  }),
  consigneeCompanyId: yup.number().when("status", {
    is: (val) => ![1, 2, 3].includes(val),
    then: () => yup.number().required("Please select Consignee company"),
    otherwise: () => yup.number().nullable(),
  }),
  groundHandlingCompanyId: yup.number().when("status", {
    is: (val) => ![1, 2, 3].includes(val),
    then: () => yup.number().required("Please select Ground handling company"),
    otherwise: () => yup.number().nullable(),
  }),
  regionalDeliveryDepotLocationId: yup.number().when("status", {
    is: (val) => [13, 14].includes(val),
    then: () => yup.number().required("Please select Regional delivery depot"),
    otherwise: () => yup.number().nullable(),
  }),
  typeOfCustomsClearanceId: yup.number().when("status", {
    is: (val) => [9, 10, 11, 12, 13, 14, 26].includes(val),
    then: () =>
      yup.number().required("Please select type of customs clearence"),
    otherwise: () => yup.number().nullable(),
  }),
  aeoCompanyId: yup.number().when("status", {
    is: (val) => [9, 10, 11, 12, 13, 14, 26].includes(val),
    then: () => yup.number().required("Please select AEO company"),
    otherwise: () => yup.number().nullable(),
  }),
});

const ShipmentInfoCreateTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shipmentData = useSelector((state) => state.shipment);
  const locations = useSelector((state) => state.locations.data);
  const companies = useSelector((state) => state.companies.data);
  const exportingCompanies = useSelector((state) => state.exportingCompanies);
  const ddVariables = useSelector((state) => state.variables);
  const systemEnums = useSelector((state) => state.enums);

  const forwardingCompanies = companies.filter(
    (item) =>
      item.companyTypeId ===
        ddVariables.COMPANY_TYPE.filter(
          (item) => item.value === "Forwarding"
        )?.[0]["id"] || null
  );

  const lastMileCompanies = companies.filter(
    (item) =>
      item.companyTypeId ===
        ddVariables.COMPANY_TYPE.filter(
          (item) => item.value === "Last mile"
        )?.[0]["id"] || null
  );

  const internationalShippingCompanies = companies.filter(
    (item) =>
      item.companyTypeId ===
        ddVariables.COMPANY_TYPE.filter(
          (item) => item.value === "International shipping"
        )?.[0]["id"] || null
  );

  const aeoCompanies = companies.filter(
    (item) =>
      item.companyTypeId ===
        ddVariables.COMPANY_TYPE.filter((item) => item.value === "AEO")?.[0][
          "id"
        ] || null
  );

  const transportationCompanies = companies.filter(
    (item) =>
      item.companyTypeId ===
        ddVariables.COMPANY_TYPE.filter(
          (item) => item.value === "Transportation"
        )?.[0]["id"] || null
  );

  const groundHandlingCompanies = companies.filter(
    (item) =>
      item.companyTypeId ===
        ddVariables.COMPANY_TYPE.filter(
          (item) => item.value === "Ground handling"
        )?.[0]["id"] || null
  );

  const loadingLocations = locations.filter(
    (item) =>
      item.locationTypeId ===
        ddVariables.LOCATION_TYPE.filter(
          (item) => item.value === "Loading"
        )?.[0]["id"] || null
  );

  const arrivalLocations = locations.filter(
    (item) =>
      item.locationTypeId ===
        ddVariables.LOCATION_TYPE.filter(
          (item) => item.value === "Arrival"
        )?.[0]["id"] || null
  );

  const ccLocations = locations.filter(
    (item) =>
      item.locationTypeId ===
        ddVariables.LOCATION_TYPE.filter((item) => item.value === "CC")?.[0][
          "id"
        ] || null
  );

  const regionalDepotLocations = locations.filter(
    (item) =>
      item.locationTypeId ===
        ddVariables.LOCATION_TYPE.filter(
          (item) => item.value === "Regional delivery depot"
        )?.[0]["id"] || null
  );

  const [consigneeAeoId, setConsigneeAeoId] = useState("");
  const [consigneeCountry, setConsigneeCountry] = useState("");
  const [consigneeAddress, setConsigneeAddress] = useState("");
  const [consigneeCity, setConsigneeCity] = useState("");
  const [consigneeZipCode, setConsigneeZipCode] = useState("");
  const [consigneeEuVat, setConsigneeEuVat] = useState("");
  const [consigneeEmail, setConsigneeEmail] = useState("");
  const [consigneePhone, setConsigneePhone] = useState("");
  const [consigneeTypeOfConsignee, setConsigneeTypeOfConsignee] = useState("");

  const defaultValues = {
    status: 1,
    exportingCompanyId: 0,
    shipmentId: "",
    slackChannelId: "",
    sealId: "",

    totalNrAirCargoPallets: 0,
    totalNrStandardPallets: 0,
    totalNrCartons: 0,
    totalNrCollectionParcels: 0,
    totalNrParcels: 0,
    totalNrItems: 0,
    loadingLocationShortName: "BUD",
    arrivalLocationShortName: "BUD",
    internationalShipperCompanyName: "QT LOGISTICS",

    customerTotalNrAirCargoPallets: 0,
    customerTotalNrStandardPallets: 0,
    customerTotalNrCartons: 0,
    customerTotalNrCollectionParcels: 0,
    customerTotalNrParcels: 0,
    customerTotalNrItems: 0,
    customerTotalNrItemLines: 0,

    regionalDeliveryDepotLocationId: 0,
    deliveryDepotLocationId: 0,

    loadingLocationId: 0,
    arrivalLocationId: 0,
    ccLocationId: 0,
    packagingInstructions: 0,
    labellingInstructions: 0,
    selectionInstructions: 0,

    customerShipmentReferenceId: "",

    internationalForwarderCompanyId: 0,
    internationalShipperCompanyId: 0,
    shippingVehicleId: "",
    shippingMethod: systemEnums?.shippingMethod ? Object.keys(systemEnums.shippingMethod)[0] : 'AIR',
    shippingCompanyShipmentId: "",
    labelColour: "",
    consigneeCompanyId: 0,
    serviceGroundHandling: false,
    serviceTemporaryWarehouse: false,
    servicePickupAndTransitCC: false,
    serviceNormalCc: false,
    serviceIossCc: false,
    serviceSorting: false,
    serviceRepackaging: false,
    serviceLabelling: false,
    serviceLineHaulToDestCountry: false,
    serviceLastMileDelivery: false,
    serviceReturnStorage: false,
    serviceNotes: "",

    totalWeight: 0,
    cbm: 0,
    mrnId: "",

    groundHandlingCompanyId: 0,
    pickupReferencePin: "",

    typeOfCustomsClearanceId: 0,
    aeoCompanyId: 0,
    arrivalTerminalInspection: false,
    borderControlInspection: false,
    ccInspection: false,
    inspectionNotes: "",

    calendarWeek: null,

    timeOfDepartureEst: null,
    timeOfDepartureAct: null,
    regOfShipmentFromCustomerEst: null,
    regOfShipmentFromCustomerAct: null,
    parcelListDataFeedEst: null,
    parcelListDataFeedAct: null,
    arrivalToEuEst: null,
    arrivalToEuAct: null,
    arrivalDocumentsEst: null,
    arrivalDocumentsAct: null,
    groundHandlingNotificationEst: null,
    groundHandlingNotificationAct: null,
    pickupEst: null,
    pickupAct: null,
    arrivalToCcEst: null,
    arrivalToCcAct: null,
    customsClearanceSubEst: null,
    customsClearanceSubAct: null,
    customsClearanceCompEst: null,
    customsClearanceCompAct: null,
    sortingRepackLabelEst: null,
    sortingRepackLabelAct: null,
    pickupByLineHaulEst: null,
    pickupByLineHaulAct: null,
    arrivalToRegionalDepoEst: null,
    arrivalToRegionalDepoAct: null,
    arrivalToFinalDepoEst: null,
    arrivalToFinalDepoAct: null,
    deliveryCompletedEst: null,
    deliveryCompletedAct: null,
    returnsBackToClientEst: null,
    returnsBackToClientAct: null,
    timeOfDepartureActEst: null,
    timeOfDepartureActAct: null,
    // lineHaulPartners: shipmentData?.shippingPartners || [],
    lastMileProviders: [],
  };

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const showFormErrors = (data) => {
    const errors = Object.values(data)
      .map((item) => item.message)
      .join(`\n`);
    dispatch(
      openErrorDialog({
        message: `Please fill out all required fields:\n${errors}`,
      })
    );
  };

  const onSubmit = (data) => {
    dispatch(create(data)).then((res) => {
      console.log('RESPONSE:', res);
      navigate(`/shipments/${res.payload.data.id}`);
      dispatch(openSnackbar({ message: `Shipment created.` }));
    });
  };

  function ExportingCompanyLink({ control }) {
    const exportingCompanyCountryId = useWatch({
      control,
      name: "exportingCompanyId",
    });

    const cdata = exportingCompanies.filter(
      (item) => item.id === exportingCompanyCountryId
    )?.[0];

    if (cdata) {
      return (
        <Link
          className="hover:text-sky-600 hover:underline underline"
          to={`/exporting-companies/${cdata.id}`}
        >
          {cdata.companyName}
        </Link>
      );
    }

    return null;
  }

  function ExportingCompanyCountry({ control }) {
    const exportingCompanyCountryId = useWatch({
      control,
      name: "exportingCompanyId",
    });

    const cdata = exportingCompanies.filter(
      (item) => item.id === exportingCompanyCountryId
    )[0];

    if (cdata) {
      const countryData = ddVariables.COUNTRY.filter(
        (item) => item.id === cdata.countryId
      )?.[0];

      return <p>{countryData?.value || `N/A`}</p>;
    }

    return <p>N/A</p>;
  }

  const consigneeCompanyIdW = useWatch({
    name: `consigneeCompanyId`,
    control: methods.control,
  });

  useEffect(() => {
    const companyData = companies.filter(
      (item) => item.id === consigneeCompanyIdW
    )[0];

    if (companyData) {
      setConsigneeAeoId(companyData.aeoId);
      setConsigneeCountry(companyData.countryId);
      setConsigneeAddress(companyData.address);
      setConsigneeCity(companyData.city);
      setConsigneeZipCode(companyData.zipCode);
      setConsigneeEuVat(companyData.euVat);
      setConsigneeEmail(companyData.contactPersonEmail);
      setConsigneePhone(companyData.contactPersonPhone);
      setConsigneeTypeOfConsignee(companyData.typeOfConsignee);
    }
  }, [companies, consigneeCompanyIdW]);

  // console.log(watch("exportingCompanyId")); // watch input value by passing the name of it

  if (!ddVariables || !systemEnums) return null;

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit, showFormErrors)}
          autoComplete="off"
        >
          <Paper>
            <Box width={"100%"} display="flex">
              <Box width={"40%"}>
                <InputLabel>{"Status"}</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      {[...ddVariables.SHIPMENT_STATUS]
                        .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
                        .map((item, idx) => {
                          return (
                            <MenuItem key={`statusKey-${idx}`} value={parseInt(item.id)}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                  control={methods.control}
                  name="status"
                />
              </Box>
              <Box width={"40%"}>
                <InputLabel>{"Customer"}</InputLabel>
                <Controller
                  control={methods.control}
                  name="exportingCompanyId"
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {exportingCompanies.map((item, idx) => (
                        <MenuItem
                          key={`exportingCompany-${idx}`}
                          value={item.id}
                        >
                          {item.companyName}
                        </MenuItem>
                      ))}
                      {fieldState.error && <p>{fieldState.error.message}</p>}
                    </Select>
                  )}
                />
              </Box>
              <Box width={"20%"} display="flex" justifyContent={"center"}>
                <Button type="submit">Save</Button>
              </Box>
            </Box>
          </Paper>

          <Box my={8}>
            <Typography variant="h6" component="h4">
              Basic information
            </Typography>
            <div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Customer name"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <ExportingCompanyLink control={methods.control} />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Exporting company country"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <ExportingCompanyCountry control={methods.control} />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Calendar week"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Typography>
                    W{DateTime.fromJSDate(new Date()).toFormat("WW")}
                  </Typography>
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Shipment ID"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="shipmentId"
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Customer shipment ID"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="customerShipmentReferenceId"
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Label colours"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="labelColour"
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Slack channel ID"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="slackChannelId"
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Seal ID"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="sealId"
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center"></div>
                <div className="w-4/12 text-center">CUSTOMER</div>
                <div className="w-4/12 text-center">CALCULATED</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center">
                  <InputLabel>{"Total nr of air cargo pallets"}</InputLabel>
                </div>
                <div className="w-4/12 mr-2">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="customerTotalNrAirCargoPallets"
                  />
                </div>
                <div className="w-4/12 ml-2 text-center">-</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center">
                  <InputLabel>{"Total nr of standard pallets"}</InputLabel>
                </div>
                <div className="w-4/12 mr-2">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="customerTotalNrStandardPallets"
                  />
                </div>
                <div className="w-4/12 ml-2 text-center">0</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center">
                  <InputLabel>{"Total nr of cartons"}</InputLabel>
                </div>
                <div className="w-4/12 mr-2">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="customerTotalNrCartons"
                  />
                </div>
                <div className="w-4/12 ml-2 text-center">0</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center">
                  <InputLabel>{"Total nr of collection parcels"}</InputLabel>
                </div>
                <div className="w-4/12 mr-2">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="customerTotalNrCollectionParcels"
                  />
                </div>
                <div className="w-4/12 ml-2 text-center">0</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center">
                  <InputLabel>{"Total nr of parcels"}</InputLabel>
                </div>
                <div className="w-4/12 mr-2">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="customerTotalNrParcels"
                  />
                </div>
                <div className="w-4/12 ml-2 text-center">0</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center">
                  <InputLabel>{"Total nr of item lines"}</InputLabel>
                </div>
                <div className="w-4/12 mr-2">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="customerTotalNrItemLines"
                  />
                </div>
                <div className="w-4/12 ml-2 text-center">0</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-6/12 self-center">
                  <InputLabel>{"Total nr of items"}</InputLabel>
                </div>
                <div className="w-4/12 mr-2">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="customerTotalNrItems"
                  />
                </div>
                <div className="w-4/12 ml-2 text-center">0</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Gross weight (kg)"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="totalWeight"
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                        InputProps={{
                          endAdornment: <>kg</>,
                          sx: { cursor: "default" },
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"CBM (m3)"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="cbm"
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                        InputProps={{
                          endAdornment: <>cbm</>,
                          sx: { cursor: "default" },
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Box>

          <Box my={8}>
            <Typography variant="h6" component="h4">
              Main locations
            </Typography>
            <div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Loading location"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="loadingLocationId"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {loadingLocations.map((item, idx) => (
                          <MenuItem
                            key={`loadingLocation-${idx}`}
                            value={item.id}
                          >
                            {item.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Arrival location"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="arrivalLocationId"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {arrivalLocations.map((item, idx) => (
                          <MenuItem
                            key={`arrivalLocation-${idx}`}
                            value={item.id}
                          >
                            {item.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"CC location"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="ccLocationId"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {ccLocations.map((item, idx) => (
                          <MenuItem key={`ccLocation-${idx}`} value={item.id}>
                            {item.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Regional delivery depot"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="regionalDeliveryDepotLocationId"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {regionalDepotLocations.map((item, idx) => (
                          <MenuItem
                            key={`regDepotLocation-${idx}`}
                            value={item.id}
                          >
                            {item.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Delivery depot"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="deliveryDepotLocationId"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {regionalDepotLocations.map((item, idx) => (
                          <MenuItem
                            key={`deliveryDepotLocation-${idx}`}
                            value={item.id}
                          >
                            {item.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
            </div>
          </Box>

          <div className="my-4">
            <Typography variant="h6" component="h4">
              Services
            </Typography>
            <div>
              <div>
                <Controller
                  name="serviceGroundHandling"
                  control={methods.control}
                  // rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      error={false}
                      checked={value}
                      onChange={onChange}
                      label="Service - Ground handling"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="serviceTemporaryWarehouse"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Temporary storage"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="servicePickupAndTransitCC"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Pickup & transit to CC"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceIossCc"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - IOSS CC"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceNormalCc"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Normal CC"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceSorting"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Sorting"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceRepackaging"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Repackaging"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceLabelling"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      size={"small"}
                      label="Service - Labelling"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceLineHaulToDestCountry"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Line Haul to destination country"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceLastMileDelivery"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Last mile delivery"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="serviceReturnStorage"
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      checked={value}
                      onChange={onChange}
                      error={false}
                      label="Service - Return storage"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>

              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Packaging instructions"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="packagingInstructions"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {ddVariables.PACKAGING_INSTRUCTIONS.map((item, idx) => (
                          <MenuItem key={`packInstr-${idx}`} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Labelling instructions"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="labellingInstructions"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {ddVariables.LABELLING_INSTRUCTIONS.map((item, idx) => (
                          <MenuItem key={`labInstr-${idx}`} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Selection instructions"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="selectionInstructions"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {ddVariables.SELECTION_INSTRUCTIONS.map((item, idx) => (
                          <MenuItem key={`labInstr-${idx}`} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>

              <div>
                <Controller
                  name="serviceNotes"
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      multiline
                      fullWidth
                      rows={5}
                      label="Service notes"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <Box my={8}>
            <Typography variant="h6" component="h4">
              Shipping to EU
            </Typography>
            <Box>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Exporting company"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {exportingCompanies.map((item, idx) => (
                          <MenuItem
                            key={`exportingCompany-${idx}`}
                            value={item.id}
                          >
                            {item.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="exportingCompanyId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"International forwarder company"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {forwardingCompanies.map((item, idx) => (
                          <MenuItem key={`company-${idx}`} value={item.id}>
                            {item.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="internationalForwarderCompanyId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"International shipper"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-standard-label"
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        label="International shipper"
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {internationalShippingCompanies.map((item, idx) => (
                          <MenuItem key={`company-${idx}`} value={item.id}>
                            {item.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="internationalShipperCompanyId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Shipping vehicle ID / name"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="shippingVehicleId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Shipping method"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        label="Shipping method to EU"
                      >
                        {Object.entries(systemEnums.shippingMethod).map(
                          ([key, value], idx) => (
                            <MenuItem
                              key={`loadingLocation-${idx}`}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                    control={methods.control}
                    name="shippingMethod"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>
                    {"Shipping company shipment ID (AWB)"}
                  </InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="shippingCompanyShipmentId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Consignee name"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {forwardingCompanies.map((item, idx) => (
                          <MenuItem key={`conscompany-${idx}`} value={item.id}>
                            {item.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="consigneeCompanyId"
                  />
                  <Box sx={{ fontSize: 12 }}>
                    AeoId: {consigneeAeoId} <br />
                    Country: {consigneeCountry} <br />
                    Address: {consigneeAddress} <br />
                    City: {consigneeCity} <br />
                    ZipCode: {consigneeZipCode} <br />
                    EuVat: {consigneeEuVat} <br />
                    Email: {consigneeEmail} <br />
                    Phone: {consigneePhone} <br />
                    TypeOfConsignee: {consigneeTypeOfConsignee} <br />
                  </Box>
                </div>
              </div>
            </Box>
          </Box>

          <Box my={8}>
            <Typography variant="h6" component="h4">
              Arrival to EU & Pickup
            </Typography>
            <Box>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Arrival location"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {arrivalLocations.map((item, idx) => (
                          <MenuItem
                            key={`arrivalLocation-${idx}`}
                            value={item.id}
                          >
                            {item.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="arrivalLocationId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Ground handling agency"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {groundHandlingCompanies.map((item, idx) => (
                          <MenuItem
                            key={`exportingCompany-${idx}`}
                            value={item.id}
                          >
                            {item.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="groundHandlingCompanyId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Pickup reference / PIN"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="pickupReferencePin"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"MRN ID"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field, fieldState }) => (
                      <TextField
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        fullWidth
                        variant="standard"
                        type="text"
                      />
                    )}
                    control={methods.control}
                    name="mrnId"
                  />
                </div>
              </div>
            </Box>
          </Box>

          <Box my={8}>
            <Typography variant="h6" component="h4">
              EU shipping partners
            </Typography>
            <Box>
              {shipmentData?.shippingToEuPartners?.length
                ? shipmentData.shippingToEuPartners.map((item, idx) => (
                    <LineHaulBlock
                      key={`linehaul-${idx}`}
                      partnerType={`shippingToEuPartners`}
                      partnerData={item || null}
                      lineHaulId={idx}
                      companies={transportationCompanies}
                      locations={locations}
                      shippingMethods={systemEnums.shippingMethod}
                      handleRemove={() =>
                        dispatch(removeShippingToEuPartner({ idx: idx }))
                      }
                    />
                  ))
                : null}
            </Box>
            <div>
              <Button onClick={() => dispatch(addShippingToEuPartners())}>
                Add new
              </Button>
            </div>
          </Box>

          <Box my={8}>
            <Typography variant="h6" component="h4">
              Customs clearance
            </Typography>
            <Box>
              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"Type of customs clearance"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {ddVariables.CUSTOMS_CLEARANCE_TYPE.map((item, idx) => (
                          <MenuItem key={`ccKey-${idx}`} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="typeOfCustomsClearanceId"
                  />
                </div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"CC location"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    control={methods.control}
                    name="ccLocationId"
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {ccLocations.map((item, idx) => (
                          <MenuItem key={`ccLocation-${idx}`} value={item.id}>
                            {item.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-5/12 self-center">
                  <InputLabel>{"AEO company"}</InputLabel>
                </div>
                <div className="w-7/12">
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        <MenuItem value={0}>Please select</MenuItem>
                        {aeoCompanies.map((item, idx) => (
                          <MenuItem key={`company-${idx}`} value={item.id}>
                            {item.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="aeoCompanyId"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <Typography>Inspection</Typography>
              </div>
              <div>
                <Controller
                  name="arrivalTerminalInspection"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      error={false}
                      checked={value}
                      onChange={onChange}
                      label="Arrival terminal inspection"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="borderControlInspection"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      error={false}
                      checked={value}
                      onChange={onChange}
                      label="Border control inspection"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="ccInspection"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <MuiCheckbox
                      error={false}
                      checked={value}
                      onChange={onChange}
                      label="CC inspection"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="inspectionNotes"
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      multiline
                      rows={5}
                      label="Inspection notes"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                      }}
                    />
                  )}
                />
              </div>
            </Box>
          </Box>

          <div className="my-8">
            <Typography variant="h6" component="h4">
              Line haul
            </Typography>
            <div>
              {shipmentData?.lineHaulPartners?.length
                ? shipmentData.lineHaulPartners.map((item, idx) => (
                    <LineHaulBlock
                      key={`linehaul-${idx}`}
                      partnerType={`lineHaulPartners`}
                      partnerData={item || null}
                      lineHaulId={idx}
                      companies={transportationCompanies}
                      locations={locations}
                      shippingMethods={systemEnums.shippingMethod}
                      handleRemove={() =>
                        dispatch(removeLineHaulBlock({ idx: idx }))
                      }
                    />
                  ))
                : null}
            </div>
            <div>
              <Button onClick={() => dispatch(addLineHaulBlock())}>
                Add new
              </Button>
            </div>
          </div>

          <div className="my-8">
            <Typography variant="h6" component="h4">
              Last mile delivery
            </Typography>
            {shipmentData?.lastMileDeliveryPartners?.length
              ? shipmentData.lastMileDeliveryPartners.map((item, idx) => (
                  <LastMileDeliveryPartnerBlock
                    key={`lmdp-${idx}`}
                    partnerType={`lastMileDeliveryPartners`}
                    partnerData={item || null}
                    partnerId={idx}
                    companies={lastMileCompanies}
                    locations={locations}
                  />
                ))
              : null}
            <div>
              <Button
                onClick={() => dispatch(addLastMileDeliveryPartnerBlock())}
              >
                Add new
              </Button>
            </div>
          </div>

          <Box my={8}>
            <Typography variant="h6" component="h4">
              Key dates
            </Typography>
            <Box>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center"></div>
                <div className="w-4/12 text-center">ESTIMATED</div>
                <div className="w-4/12 text-center">ACTUAL</div>
              </div>

              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>
                    {"Registration of shipment from customer"}
                  </InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="regOfShipmentFromCustomerEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="regOfShipmentFromCustomerAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Parcel list data feed"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="parcelListDataFeedEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="parcelListDataFeedAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Arrival documents"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalDocumentsEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalDocumentsAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Arrival to EU"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalToEuEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalToEuAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>
                    {"Ground handling notification for pickup"}
                  </InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="groundHandlingNotificationEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="groundHandlingNotificationAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Pickup"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="pickupEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterLuxon}
                          adapterLocale={navigator.language}
                        >
                          <DateTimePicker
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            variant="standard"
                            ampm={false}
                            fullWidth
                            label={`${timeZone} time`}
                          />
                        </LocalizationProvider>
                        <span>{fieldState.error?.message}</span>
                      </>
                    )}
                    control={methods.control}
                    name="pickupAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Arrival to CC"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterLuxon}
                          adapterLocale={navigator.language}
                        >
                          <DateTimePicker
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            variant="standard"
                            ampm={false}
                            fullWidth
                            label={`${timeZone} time`}
                          />
                        </LocalizationProvider>
                        <span>{fieldState.error?.message}</span>
                      </>
                    )}
                    control={methods.control}
                    name="arrivalToCcEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterLuxon}
                          adapterLocale={navigator.language}
                        >
                          <DateTimePicker
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            variant="standard"
                            ampm={false}
                            fullWidth
                            label={`${timeZone} time`}
                          />
                        </LocalizationProvider>
                        <span>{fieldState.error?.message}</span>
                      </>
                    )}
                    control={methods.control}
                    name="arrivalToCcAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Customs clearance submission"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterLuxon}
                          adapterLocale={navigator.language}
                        >
                          <DateTimePicker
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            variant="standard"
                            ampm={false}
                            fullWidth
                            label={`${timeZone} time`}
                          />
                        </LocalizationProvider>
                        <span>{fieldState.error?.message}</span>
                      </>
                    )}
                    control={methods.control}
                    name="customsClearanceSubEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterLuxon}
                          adapterLocale={navigator.language}
                        >
                          <DateTimePicker
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            variant="standard"
                            ampm={false}
                            fullWidth
                            label={`${timeZone} time`}
                          />
                        </LocalizationProvider>
                        <span>{fieldState.error?.message}</span>
                      </>
                    )}
                    control={methods.control}
                    name="customsClearanceSubAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Customs clearance completion"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="customsClearanceCompEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="customsClearanceCompAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Sorting / repacking / labeling"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="sortingRepackLabelEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="sortingRepackLabelAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Pickup by line haul"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="pickupByLineHaulEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="pickupByLineHaulAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Arrival to regional depot"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalToRegionalDepoEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalToRegionalDepoAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Arrival to final depot"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalToFinalDepoEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="arrivalToFinalDepoAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Delivery completed"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="deliveryCompletedEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="deliveryCompletedAct"
                  />
                </div>
              </div>
              <div className="relative mb-5 flex">
                <div className="w-4/12 self-center">
                  <InputLabel>{"Returns back to client"}</InputLabel>
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="returnsBackToClientEst"
                  />
                </div>
                <div className="w-4/12 text-center">
                  <Controller
                    render={({ field, fieldState }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        adapterLocale={navigator.language}
                      >
                        <DateTimePicker
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          variant="standard"
                          ampm={false}
                          fullWidth
                          label={`${timeZone} time`}
                        />
                      </LocalizationProvider>
                    )}
                    control={methods.control}
                    name="returnsBackToClientAct"
                  />
                </div>
              </div>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default ShipmentInfoCreateTab;
