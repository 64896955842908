import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

const StatusDialog = ({
  dialogOpen,
  handleClose,
  onSubmitStatusBulkUpdate,
  entityName,
  itemId,
  groupOptions,
  shipmentStatuses,
  ...props
}) => {
  const methods = useForm({});

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        methods.reset();
        handleClose(false);
      }}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => onSubmitStatusBulkUpdate(data))}
          autoComplete="off"
        >
          <DialogTitle>
            Bulk update {entityName.toLocaleLowerCase()} status
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can modify all {entityName.toLocaleLowerCase()} in this
              shipment.
            </DialogContentText>
            <div>
              <div>
                <Select
                  {...methods.register(`entityStatusId`)}
                  fullWidth
                  color="secondary"
                  variant="standard"
                  size="small"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {[...shipmentStatuses]
                    .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
                    .map((item, idx) => (
                      <MenuItem key={`statusKey-${idx}`} value={item.id}>
                        {item.value}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose(false);
                methods.reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default StatusDialog;
