import { Link, matchRoutes } from "react-router-dom";
import withRouter from "../../../services/withrouter";
import { useContext } from "react";
import AppContext from "../../../AppContext";

const BreadCrumb = (props) => {
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const matched = matchRoutes(routes, props.router.location.pathname)[0];

  return (
    <div className="flex text-xs mb-2">
      <span><Link className="hover:text-amber-500" to="/users">Users</Link></span>
      <span className="mx-1">{`>`}</span>
      <span>{matched.route.title}</span>
    </div>
  );
};

export default withRouter(BreadCrumb);
