import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getAllDynamicDataVariables = createAsyncThunk(
  "ddVariables/getVariables",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/dynamic-data/list/all`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  }
);

export const getEnums = createAsyncThunk("ddVariables/getEnums", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_APIURL}/api/v1/dynamic-data/enums`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );

  return response;
});

export const getById = createAsyncThunk(
  "shipments/getById",
  async ({ shipmentId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/${shipmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const create = createAsyncThunk("shipments/create", async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/v1/shipment`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
});

const variablesAdapter = createEntityAdapter({});

export const { selectAll: selectShipments, selectById: selectProjectById } =
  variablesAdapter.getSelectors((state) => state.variables);

const variablesSlice = createSlice({
  name: "variables",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDynamicDataVariables.fulfilled, (state, action) => {
      const variables = {};

      for (const item of action.payload.data) {
        if (!variables[item.systemEnum]) {
          variables[item.systemEnum] = [];
        }

        variables[item.systemEnum].push({
          id: item.id,
          value: item.value,
          shortName: item.shortName,
          ordering: item.ordering,
          properties: item.properties,
        });
      }

      return variables;
      // return action.payload.data.map(item => state.action.payload.data[0].systemEnum.push(item))
      // return action.payload.data;
    });
  },
});

const enumsSlice = createSlice({
  name: "enums",
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnums.fulfilled, (state, action) => {
      return action.payload.data;
      // return action.payload.data.map(item => state.action.payload.data[0].systemEnum.push(item))
      // return action.payload.data;
    });
  },
});

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    message: null,
  },
  reducers: {
    closeSnackbar(state) {
      return { open: false, message: null }; // Add new empty object to lineHaulBlocks
    },
    openSnackbar(state, action) {
      return { open: true, message: action.payload.message };
    },
  },
  extraReducers: (builder) => {},
});

const t1Slice = createSlice({
  name: "t1",
  initialState: {
    open: false,
  },
  reducers: {
    closeT1Dialog(state) {
      return { open: false }; // Add new empty object to lineHaulBlocks
    },
    openT1Dialog(state, action) {
      return { open: true };
    },
  },
  extraReducers: (builder) => {},
});

const errorDialogSlice = createSlice({
  name: "errorDialog",
  initialState: {
    open: false,
    title: "Error",
    message: null,
  },
  reducers: {
    closeErrorDialog(state) {
      return { open: false, message: null };
    },
    openErrorDialog(state, action) {
      return {
        open: true,
        message: action.payload.message,
        title: action.payload?.title || "Error",
      };
    },
  },
  extraReducers: (builder) => {},
});

export const { setShipmentsSearchText } = variablesSlice.actions;
export const { closeSnackbar, openSnackbar } = snackbarSlice.actions;
export const { closeT1Dialog, openT1Dialog } = t1Slice.actions;
export const { closeErrorDialog, openErrorDialog } = errorDialogSlice.actions;

export const errorDialog = errorDialogSlice.reducer;
export const snackbar = snackbarSlice.reducer;
export const enums = enumsSlice.reducer;
export const t1 = t1Slice.reducer;
export default variablesSlice.reducer;
