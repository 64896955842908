import {
  Button,
  CircularProgress,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DataTable from "../../../components/datatable/Datatable";
import { useNavigate } from "react-router-dom";
import {
  downloadBulkTExport,
  getAllCarton,
  getAllCollectionParcel,
  getAllPallet,
  getAllShipment,
  getParcelsByShipmentId,
} from "../store/shipmentSlice";
import {
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from "material-react-table";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { blue, grey } from "@mui/material/colors";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { forwardRef, memo, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import {
  exportAllToExcelByShipmentId,
  exportPageRowsToExcel,
  exportSpecificExcel,
  exportStlvExcel,
  importParcelExcel,
} from "../../parcels/store/parcelsSlice";
import MuiAutoComplete from "../../../components/autocomplete/Autocomplete";
import {
  disconnect,
  grouping,
  transfer,
  updateParcelBulkStatus,
} from "../../parcels/store/parcelSlice";
import { openErrorDialog, openSnackbar } from "../../../store/variablesSlice";
import StatusDialog from "./dialogs/StatusDialog";

const columns = [
  {
    header: "Parcel Id",
    // Cell: ({ renderedCellValue, row }) => (
    //   <Link to={`/parcels/${row.id}`}>{renderedCellValue}</Link>
    // ),
    accessorKey: "parcelCustomerId",
  },
  {
    header: "LM Tracking ID",
    accessorKey: "parcelTrackingId",
  },
  {
    header: "Status",
    accessorKey: "statusName",
  },
  {
    header: "Original shipment ID",
    accessorKey: "originalShipmentName",
  },
  {
    header: "Actual shipment ID",
    accessorKey: "actualShipmentName",
  },
  {
    header: "Actual pallet ID",
    accessorFn: (row) => `${row.palletCustomerId || "-"}`,
  },
  {
    header: "Actual carton ID",
    accessorKey: "cartonCustomerId",
  },
  {
    header: "Actual coll. parcel ID",
    accessorKey: "collectionParcelCustomerId",
  },
  {
    header: "Nr of items",
    accessorKey: "nrOfItems",
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
  },
  {
    header: "Recipient Country",
    accessorKey: "recipientCountry",
  },
  {
    header: "Recipient City",
    accessorKey: "recipientCity",
  },
  {
    header: "Recipient street name",
    accessorKey: "recipientStreetName",
  },
  {
    header: "Recipient house nr",
    accessorKey: "recipientHouseNumber",
  },
  {
    header: "Recipient additional addr info",
    accessorKey: "recipientAdditionalAddressText",
  },
  {
    header: "LM partner",
    accessorKey: "lastMileDeliveryPartnerName",
  },
];

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ParcelListTable = ({
  shipmentId,
  customerShipmentReferenceId,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ddVariables = useSelector((state) => state.variables);
  const [dialogImportOpen, setDialogImportOpen] = useState(false);
  const [exportKzLoading, setExportKzLoading] = useState(false);
  const [exportMsLoading, setExportMsLoading] = useState(false);
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialog2Open, setDialog2Open] = useState(false);
  const [dialog3Open, setDialog3Open] = useState(false);
  const [idsToTransfer, setIdsToTransfer] = useState([]);

  const exportToExcel = ({ excelType, shipmentId }) => {
    return async () => {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${excelType.toUpperCase()}-${customerShipmentReferenceId}-"export.xlsx`;

      exportSpecificExcel({ shipmentId: shipmentId, excelType: excelType })
        .then(async (res) => {
          if (res.status > 299) {
            // error handling
            res.data = JSON.parse(await res.data.text());
            dispatch(openErrorDialog({ message: res.data.message }));
          } else {
            // success blob file
            link.href = URL.createObjectURL(new Blob([res.data]));
            link.click();
          }
        })
        .catch(async (e) => {
          dispatch(openErrorDialog({ message: `Response error` }));
        });
    };
  };

  const handleExportStlvExcel = ({ shipmentId }) => {
    return async () => {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${customerShipmentReferenceId}-"STLV-export.xlsx`;

      exportStlvExcel({ shipmentId: shipmentId })
        .then((res) => {
          link.href = URL.createObjectURL(new Blob([res.data]));
          link.click();
        })
        .catch((e) => {
          dispatch(openErrorDialog({ message: e.response.data.message }));
        });
    };
  };

  const handleExportAllTExcel = (days) => {
    return async () => {
      const link = document.createElement("a");
      link.target = "_blank";

      dispatch(downloadBulkTExport(days))
        .then((res) => {
          const contentDisposition = res.payload.headers["content-disposition"];

          let fileName = "unknown";
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=([^;]+)/);
            if (fileNameMatch && fileNameMatch.length === 2) {
              fileName = fileNameMatch[1].trim();
            }
          }

          link.download = fileName;
          link.href = URL.createObjectURL(new Blob([res.payload.data]));
          link.click();

          const excludedShipmentAwbs =
            res.payload.headers["x-excluded-shipments"];
          if (excludedShipmentAwbs) {
            dispatch(
              openErrorDialog({
                message: `These shipments excluded from export due missing some mandatory datas: ${[
                  ...new Set(JSON.parse(excludedShipmentAwbs)),
                ].join("\n")}`,
              })
            );
          }
        })
        .catch((e) => {
          dispatch(openErrorDialog({ message: JSON.stringify(e) }));
        });
    };
  };

  const exportAllByShipmentToExcel = ({ shipmentId }) => {
    return async () => {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${customerShipmentReferenceId}-"all-export.xlsx`;

      exportAllToExcelByShipmentId({ shipmentId: shipmentId })
        .then((res) => {
          link.href = URL.createObjectURL(new Blob([res.data]));
          link.click();
        })
        .catch((e) => console.log(e));
    };
  };

  const exportPageRowsDataToExcel = async (
    data,
    customerShipmentReferenceId
  ) => {
    try {
      const res = await exportPageRowsToExcel(data);
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${customerShipmentReferenceId}-tab-export.xlsx`;
      link.href = URL.createObjectURL(new Blob([res.data]));
      link.click();
    } catch (e) {
      console.error("Hiba történt az exportálás közben:", e);
    }
  };

  /*
  const cartonOptions = () => {
    getAllCarton()
      .then((result) =>
        result.map((item) => ({
          value: item.id,
          label: item.shipmentId,
        }))
      )
      .catch((e) => console.log(e));
  };
*/
  const handleUpload = async (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 0) {
      for (const file of files) {
        const formData = new FormData();
        formData.append(`uploadedFile`, file);
        formData.append(`shipmentId`, shipmentId);

        await dispatch(importParcelExcel(formData))
          .then(async (results) => {
            setDialogImportOpen(false);

            if (results.payload.status > 299) {
              // error handling
              dispatch(
                openErrorDialog({ message: results.payload.data.message })
              );
            } else {
              dispatch(openSnackbar({ message: `Parcel import successful` }));
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } else {
      console.error("No files selected");
    }
  };

  const methods = useForm();

  function MenuTransitions({ handleExportRows }) {
    // const createHandleMenuClick = (menuItem) => {
    //   return () => {
    //     console.log(`Clicked on ${menuItem}`);
    //   };
    // };

    return (
      <Dropdown>
        <MenuButton>Export...</MenuButton>
        <Menu className="z-10" sx={{ backgroundColor: "white" }}>
          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={exportAllByShipmentToExcel({
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export all
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={() => {
                exportPageRowsDataToExcel(
                  handleExportRows(),
                  customerShipmentReferenceId
                );
              }}
              variant="contained"
              disableElevation
            >
              Export tab
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={exportToExcel({
                excelType: "kz",
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export KZ
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={exportToExcel({
                excelType: "kz2",
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export KZ 2
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={exportToExcel({
                excelType: "ms",
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export MS
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={exportToExcel({
                excelType: "temu",
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export Temu
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={handleExportAllTExcel(7)}
              variant="contained"
              disableElevation
            >
              Export Temu Bulk
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={exportToExcel({
                excelType: "vam15",
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export VAM15
            </Button>
          </MenuItem>

          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={handleExportStlvExcel({
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export STLV
            </Button>
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  const handleClose = () => {
    setDialogOpen(false);
    setDialog2Open(false);
    setDialog3Open(false);
    setDialogImportOpen(false);
  };

  const onSubmit = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (
        value == null ||
        value === false ||
        value === undefined ||
        value === ""
      ) {
        console.log(`key: ${key} value: ${value}`);
        delete data[key];
      }
    });
    dispatch(transfer({ ...data, ids: idsToTransfer })).then((res) => {
      console.log(res);
      setDialogOpen(false);
      if (res.payload.status === 200) {
        dispatch(openSnackbar({ message: `Parcels transferred successful` }));
      }
    });
    console.log({ ...data, ids: idsToTransfer });
  };

  const onSubmitDisconnect = (data) => {
    dispatch(disconnect({ ids: idsToTransfer }))
      .then((res) => console.log(res), setDialog2Open(false))
      .then(() => dispatch(openSnackbar({ message: `Parcels disconnected` })));
    console.log({ ids: idsToTransfer });
  };

  const onSubmitGroupInto = (data) => {
    dispatch(grouping({ ids: idsToTransfer, ...data }))
      .then(
        (res) => console.log(res),
        setDialog3Open(false),
        dispatch(
          openSnackbar({
            message: `Parcels grouped into ${
              data.groupEntityCustomerRefName
            } ${data.groupEntityType.toLocaleLowerCase()}`,
          })
        )
      )
      .catch((e) => {
        setDialog3Open(false);
        dispatch(
          openSnackbar({
            message: `Error occured`,
          })
        );
      });
    console.log({ ids: idsToTransfer, data: data });
    // setDialog3Open(false);
  };

  const onSubmitStatusBulkUpdate = (data) => {
    dispatch(
      updateParcelBulkStatus({
        status: data.entityStatusId,
        shipmentId: shipmentId,
      })
    )
      .then((res) => console.log(res), setStatusDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Parcels status updated` }))
      );
  };

  return (
    <>
      <div className="w-full">
        <DataTable
          fetchData={getParcelsByShipmentId}
          endpointUrl={`/api/v1/shipment/${shipmentId}/parcels`}
          predefinedColumns={columns}
          enableRowActions
          positionActionsColumn={"last"}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Actions", //change header text
              size: 50, //make actions column wider
            },
          }}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
          }}
          muiTableContainerProps={{ sx: { minHeight: "400px" } }}
          enableColumnActions={false}
          muiTableBodyRowProps={({ row }) => ({
            //implement row selection click events manually
            onClick: () => {
              // console.log("PARCELL id:", JSON.stringify(row));
              navigate(`/parcels/${row.id}`, { replace: true });
            },
            sx: {
              cursor: "pointer",
            },
          })}
          renderTopToolbar={({ table }) => {
            // const handleDeactivate = () => {
            //   table.getSelectedRowModel().flatRows.map((row) => {
            //     alert("deactivating " + row.getValue("name"));
            //   });
            // };
            const handleImport = () => {
              setDialogImportOpen(true);
            };

            const handleExportRows = () => {
              const rowData = table.getFilteredRowModel().rows;
              const rowsData = rowData.map((row) => row.original);

              return rowsData;
            };

            const handleTransfer = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setDialogOpen(true);
            };

            const handleDisconnect = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setDialog2Open(true);
            };

            const handleGroupIntoNew = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setDialog3Open(true);
            };

            const handleStatusUpdate = (rows) => {
              setStatusDialogOpen(true);
            };

            return (
              <div className="flex gap-2 p-2 justify-end bg-opacity-95">
                <div className="flex">
                  <Button
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() => handleStatusUpdate()}
                    sx={{ marginRight: 1 }}
                  >
                    Status update
                  </Button>
                  <Button
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() => handleImport()}
                    sx={{ marginRight: 1 }}
                  >
                    Import updates
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleTransfer(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Transfer
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleGroupIntoNew(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Group into new
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleDisconnect(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Disconnect
                  </Button>
                  <div className="flex gap-2">
                    {MenuTransitions({ handleExportRows: handleExportRows })}
                  </div>
                </div>
                <MrtToggleFiltersButton table={table} />
                <MrtShowHideColumnsButton table={table} />
              </div>
            );
          }}
        />
      </div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
            <DialogTitle>Transfer</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Warning: this action may remove the parcel from this shipment
                <br />
                Parcel(s) ({idsToTransfer.join(",")}) transfer to:
              </DialogContentText>

              <div className="w-[40%]">
                <MuiAutoComplete
                  label={"Shipment"}
                  fetchData={getAllShipment}
                  elementFormId={"actualShipmentId"}
                  optionId={"id"}
                  optionValue={"shipmentId"}
                />

                <MuiAutoComplete
                  label={"Pallet"}
                  fetchData={getAllPallet}
                  elementFormId={"actualPalletId"}
                  optionId={"id"}
                  optionValue={"palletCustomerId"}
                />

                <MuiAutoComplete
                  label={"Carton"}
                  fetchData={getAllCarton}
                  elementFormId={"actualCartonId"}
                  optionId={"id"}
                  optionValue={"cartonCustomerId"}
                />

                <MuiAutoComplete
                  label={"Collection Parcel"}
                  fetchData={getAllCollectionParcel}
                  elementFormId={"actualCollectionParcelId"}
                  optionId={"id"}
                  optionValue={"collectionParcelCustomerId"}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Transfer</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <Dialog open={dialog2Open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmitDisconnect)}
            autoComplete="off"
          >
            <DialogTitle>Disconnect from shipment</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Warning: this action will remove the parcel from this shipment
                <br />
                Parcel(s) ({idsToTransfer.join(",")}) transfer to UNALLOCATED
                SHIPMENT.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Disconnect</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <Dialog open={dialog3Open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmitGroupInto)}
            autoComplete="off"
          >
            <DialogTitle>Group into new</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Warning: this action will create new entity (shipment, carton,
                etc.) and assign selected parcels to it
                <br />
                Parcel(s) ({idsToTransfer.join(",")}) will be grouped.
              </DialogContentText>
              <div>
                <div>
                  <InputLabel>{"Select parent element type"}</InputLabel>
                  <Select
                    {...methods.register(`groupEntityType`)}
                    fullWidth
                    color="secondary"
                    variant="standard"
                    size="small"
                    defaultValue={"Carton"}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    label="Status"
                  >
                    {["Carton"].map((item, idx) => (
                      <MenuItem key={`statusKey-${idx}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <TextField
                    {...methods.register(`groupEntityCustomerRefName`)}
                    fullWidth
                    label={`Entity reference id`}
                    placeholder="Eg: CAR-12345"
                    variant="standard"
                    type="text"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Group</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <Dialog open={dialogImportOpen} onClose={handleClose}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmitGroupInto)}
            autoComplete="off"
          >
            <DialogTitle>Import parcel updates</DialogTitle>
            <DialogContent>
              <DialogContentText></DialogContentText>
              <div>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept=".xlsx"
                    onChange={(e) => handleUpload({ ...e })}
                  />
                </Button>
              </div>
            </DialogContent>
            <DialogActions></DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <StatusDialog
        methods={methods}
        dialogOpen={statusDialogOpen}
        entityName={`Parcels`}
        handleClose={setStatusDialogOpen}
        onSubmitStatusBulkUpdate={onSubmitStatusBulkUpdate}
        idsToTransfer={idsToTransfer}
        shipmentStatuses={ddVariables.SHIPMENT_STATUS}
      />
    </>
  );
};

export default memo(ParcelListTable);
