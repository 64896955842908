import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useYupValidationResolver } from "../../../../shared-components/yupResolver";

const RenameDialog = ({
  dialogOpen,
  handleClose,
  onSubmitRename,
  entityName,
  itemId,
  groupOptions,
  ...props
}) => {
  const schema = yup.object().shape({
    entityCustomerRefName: yup
      .string()
      .required("You must enter a reference name")
      .min(5, "The reference name must be at least 5 characters"),
  });

  const methods = useForm({
    resolver: useYupValidationResolver(schema),
  });

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        methods.reset();
        handleClose(false);
      }}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => onSubmitRename(data))}
          autoComplete="off"
        >
          <DialogTitle>Rename</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can modify this item reference name.
            </DialogContentText>
            <div>
              <div>
                <TextField
                  {...methods.register(`entityCustomerRefName`)}
                  fullWidth
                  error={!!methods.formState.errors.entityCustomerRefName}
                  helperText={
                    methods.formState.errors.entityCustomerRefName?.message
                  }
                  label={`New reference name`}
                  placeholder="Eg: CAR-12345"
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose(false);
                methods.reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Rename</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default RenameDialog;
