import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import MuiAutoComplete from "../../../../components/autocomplete/Autocomplete";
import {
  getAllCarton,
  getAllCollectionParcel,
  getAllPallet,
  getAllShipment,
} from "../../store/shipmentSlice";

const TransferDialog = ({
  dialogOpen,
  handleClose,
  methods,
  transferFn,
  entityName,
  idsToTransfer,
  ...props
}) => {

  return (
    <Dialog open={dialogOpen} onClose={() => handleClose(false)}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(transferFn)} autoComplete="off">
          <DialogTitle>Transfer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Warning: this action may remove the {entityName} from this shipment
              <br />
              {entityName}(s) ({idsToTransfer.join(",")}) transfer to:
            </DialogContentText>

            <div className="w-[40%]">
              <MuiAutoComplete
                label={"Shipment"}
                fetchData={getAllShipment}
                elementFormId={"actualShipmentId"}
                optionId={"id"}
                optionValue={"shipmentId"}
              />

              {props.palletAutocompleteAllowed ? (
                <MuiAutoComplete
                  label={"Pallet"}
                  fetchData={getAllPallet}
                  elementFormId={"actualPalletId"}
                  optionId={"id"}
                  optionValue={"palletCustomerId"}
                />
              ) : null}

              {props.cartonAutocompleteAllowed ? (
                <MuiAutoComplete
                  label={"Carton"}
                  fetchData={getAllCarton}
                  elementFormId={"actualCartonId"}
                  optionId={"id"}
                  optionValue={"cartonCustomerId"}
                />
              ) : null}

              {props.collectionParcelAutocompleteAllowed ? (
                <MuiAutoComplete
                  label={"Collection Parcel"}
                  fetchData={getAllCollectionParcel}
                  elementFormId={"actualCollectionParcelId"}
                  optionId={"id"}
                  optionValue={"collectionParcelCustomerId"}
                />
              ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)}>Cancel</Button>
            <Button type="submit">Transfer</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default TransferDialog;
