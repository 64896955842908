import { Button, TextField } from "@mui/material";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { useDispatch } from "react-redux";

const CommentBlock = (props) => {
  const methods = useForm();
  // const dispatch = useDispatch();

  const onSubmit = (data) => {
    // dispatch()
    console.log({ ...data });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <TextField {...props}/>
          <Button type="submit">Save</Button>
        </form>
      </FormProvider>
    </>
  );
};

export default memo(CommentBlock);
