import { useDispatch, useSelector } from "react-redux";
import withRouter from "../../services/withrouter";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { memo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BreadCrumb from "./components/BreadCrumb";
import { createCompany } from "./store/companiesSlice";
import { openSnackbar } from "../../store/variablesSlice";

const schema = yup.object().shape({
  companyName: yup
    .string()
    .required("You must enter a company name")
    .min(5, "The company name must be at least 5 characters"),
});

const CompanyAdd = (props) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.variables.COUNTRY);
  const companyTypes = useSelector((state) => state.variables.COMPANY_TYPE);

  const defaultValues = {
    companyName: "",
    companyTypeId: 0,
    countryId: 0,
    city: "",
    address: "",
    houseNumber: "",
    zipCode: "",
    euVat: "",
    companyIdentifier: "",
    contactPersonName: "",
    contactPersonPhone: "",
    contactPersonEmail: "",
    aeoId: "",
    typeOfConsignee: 0,
    shortName: "",
    notes: "",
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("form post data:", data);
    dispatch(createCompany(data))
      .then(() => dispatch(openSnackbar({ message: `Company created.` })))
      .catch((e) =>
        dispatch(
          openSnackbar({ message: `Error: ${e.error} (${e.statusCode})` })
        )
      );
  };

  if (!countries) return null;

  return (
    <div className="w-full">
      <BreadCrumb />
      <div className="mb-[1.5rem]">Create new company</div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <Paper className="p-4 w-[50%]">
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Company name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="companyName"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Company type"}</InputLabel>
              </div>
              <div className="w-7/12 self-center">
                <Controller
                  control={methods.control}
                  name="companyTypeId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {companyTypes.map((item, idx) => (
                        <MenuItem key={`companyType-${idx}`} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Country"}</InputLabel>
              </div>
              <div className="w-7/12 self-center">
                <Controller
                  control={methods.control}
                  name="countryId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {countries.map((item, idx) => (
                        <MenuItem key={`country-${idx}`} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"City"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="city"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Address"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="address"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"House number"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="houseNumber"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"ZIP code"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="zipCode"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"EU VAT"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="euVat"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Company Number"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="companyIdentifier"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Contact person name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="contactPersonName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Contact person phone"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="contactPersonPhone"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Contact person email"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="contactPersonEmail"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"AEO Id"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="aeoId"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Type of consignee"}</InputLabel>
              </div>
              <div className="w-7/12 self-center">
                <Controller
                  control={methods.control}
                  name="typeOfConsignee"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {/*exportingCompanies.map((item, idx) => (
                        <MenuItem
                          key={`exportingCompany-${idx}`}
                          value={item.id}
                        >
                          {item.companyName}
                        </MenuItem>
                      ))*/}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Short name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="shortName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <Controller
                control={methods.control}
                name="notes"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={false}
                    multiline
                    fullWidth
                    rows={5}
                    label="Service notes"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                    }}
                  />
                )}
              />
            </div>
            <div className="w-[20%] flex justify-center">
              <Button type="submit">Create</Button>
            </div>
          </Paper>
        </form>
      </FormProvider>
    </div>
  );
};

export default withRouter(memo(CompanyAdd));
