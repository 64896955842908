import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const MuiAutoComplete = ({ fetchData, label, elementFormId, optionId, optionValue, ...props }) => {
  const [open, setOpen] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const { register, setValue } = useFormContext();
  const loading = open && optionsList.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let response;

      try {
        const cartonsData = await fetchData();
        response = cartonsData.data?.data || cartonsData.data;
        // console.log("response:", response);
      } catch (e) {
        console.log(e);
      }

      if (active) {
        setOptionsList(response);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, fetchData]);

  useEffect(() => {
    if (!open) {
      setOptionsList([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...register(elementFormId)}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      options={optionsList}
      onChange={(e, data) => {
        setValue(elementFormId, data?.[optionId] || null);
      }}
      isOptionEqualToValue={(option, value) => option[optionId] === value[optionId]}
      getOptionLabel={(option) =>
        option[optionValue] ? option[optionValue] : ""
      }
      renderOption={(props, option) => {
        // console.log('RO PROPS:', props);
        // console.log('RO option:', option);
        return (
          <li {...props} key={`${elementFormId}-${option[optionId]}`}>
            {option[optionValue]}
          </li>
        );
      }}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          size={props?.size || 'medium'}
          label={label}
          InputProps={{
            ...params.InputProps,
            //endAdornment: (
            //  <>
            //    {loading ? <CircularProgress color="inherit" size={20} /> : null}
            //    {params.InputProps.endAdornment}
            //  </>
            //),
          }}
        />
      )}
    />
  );
};

export default MuiAutoComplete;
