import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getUsers = createAsyncThunk("users/getUsers", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_APIURL}/api/v1/user`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      // withCredentials: true,
    }
  );

  return response;
});

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async ({ userId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/user/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ userId, ...payload }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/user/${userId}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({ userId }) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_APIURL}/api/v1/user/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/user`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

const containersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectProjectById } =
  containersAdapter.getSelectors((state) => state.users);

const containersSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export const { setUsersSearchText } = containersSlice.actions;

export default containersSlice.reducer;
