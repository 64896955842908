import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getLocations = createAsyncThunk(
  "locations/getLocations",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/location`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        // withCredentials: true,
      }
    );

    return response;
  }
);

export const getById = createAsyncThunk(
  "locations/getById",
  async ({ locationId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/location/${locationId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const createLocation = createAsyncThunk(
  "locations/create",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/location`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updateLocation = createAsyncThunk(
  "locations/update",
  async ({ payload, locationId }) => {
    console.log('locationId at updateLocation:', locationId);
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/location/${locationId}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

const locationsAdapter = createEntityAdapter({});

export const { selectAll: selectLocations, selectById: selectProjectById } =
  locationsAdapter.getSelectors((state) => state.locations);

const locationsSlice = createSlice({
  name: "locations",
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocations.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export const { setLocationsSearchText } = locationsSlice.actions;

export default locationsSlice.reducer;
