import { Box, Button, ListItemIcon, MenuItem, lighten } from "@mui/material";
import { AccountCircle, Send } from "@mui/icons-material";
import DataTable from "../../../components/datatable/Datatable";
import { getParcelsByShipmentId } from "../store/shipmentSlice";
import {
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from "material-react-table";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TransferDialog from "./dialogs/TransferDialog";
import { openSnackbar } from "../../../store/variablesSlice";
import DisconnectDialog from "./dialogs/DisconnectDialog";
import GroupIntoDialog from "./dialogs/GroupIntoDialog";
import RenameDialog from "./dialogs/RenameDialog";
import DeleteDialog from "./dialogs/DeleteDialog";
import {
  updatePallet,
  updatePalletBulkStatus,
} from "../../pallets/store/palletAction";
import StatusDialog from "./dialogs/StatusDialog";

const columns = [
  {
    header: "Pallet ID",
    accessorKey: "palletCustomerId",
  },
  {
    header: "Status",
    accessorKey: "statusName",
  },
  {
    header: "Original shipment ID",
    accessorKey: "originalShipmentName",
  },
  {
    header: "Actual shipment ID",
    accessorKey: "actualShipmentName",
  },
  {
    header: "Weight",
    accessorKey: "sumWeight",
  },
  {
    header: "Nr of cartons",
    accessorKey: "nrOfCartons",
  },
  {
    header: "Nr of collection parcels",
    accessorKey: "nrOfCollectionParcels",
  },
  {
    header: "Nr of parcels",
    accessorKey: "nrOfParcels",
  },
  {
    header: "Nr of items",
    accessorKey: "nrOfItems",
  },
];

const PalletListTable = ({ shipmentId, setTabValue, ...props }) => {
  const dispatch = useDispatch();
  const userAuthData = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [idsToTransfer, setIdsToTransfer] = useState([]);
  const shipmentsData = useSelector((state) => state.shipments);
  const ddVariables = useSelector((state) => state.variables);
  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const [groupIntoDialogOpen, setGroupIntoDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm();

  const onSubmit = (data) => {
    console.log("ok");
    /*
    Object.entries(data).forEach(([key, value]) => {
      if (
        value == null ||
        value === false ||
        value === undefined ||
        value === ""
      ) {
        console.log(`key: ${key} value: ${value}`);
        delete data[key];
      }
    });
    dispatch(transfer({ ...data, ids: idsToTransfer })).then((res) => {
      console.log(res);
      handleClose(false);
      if (res.payload.status === 200) {
        dispatch(openSnackbar({ message: `Pallets transferred successful` }));
      }
    });
    console.log({ ...data, ids: idsToTransfer });
    */
  };

  const onSubmitDisconnect = (data) => {
    console.log("ok");
    /*
    dispatch(disconnect({ ids: idsToTransfer }))
      .then((res) => console.log(res), setDisconnectDialogOpen(false))
      .then(() => dispatch(openSnackbar({ message: `Pallets disconnected` })));
    console.log({ ids: idsToTransfer });
    */
  };

  const onSubmitRename = (data) => {
    dispatch(
      updatePallet({
        data: { palletCustomerId: data.entityCustomerRefName },
        palletId: idsToTransfer[0],
      })
    )
      .then((res) => console.log(res), setRenameDialogOpen(false))
      .then(() => dispatch(openSnackbar({ message: `Pallet renamed` })));
  };

  const onSubmitStatusBulkUpdate = (data) => {
    dispatch(
      updatePalletBulkStatus({
        status: data.entityStatusId,
        shipmentId: shipmentId,
      })
    )
      .then((res) => console.log(res), setRenameDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Pallets status updated` }))
      );
  };

  const onSubmitDelete = (data) => {
    console.log("ok");
    /*
    dispatch(disconnect({ ids: idsToTransfer }))
      .then((res) => console.log(res), setDisconnectDialogOpen(false))
      .then(() => dispatch(openSnackbar({ message: `Pallets disconnected` })));
    console.log({ ids: idsToTransfer });
    */
  };

  return (
    <>
      <Box width={"100%"}>
        <DataTable
          fetchData={getParcelsByShipmentId}
          endpointUrl={`/api/v1/shipment/${shipmentId}/pallets`}
          predefinedColumns={columns}
          enableRowActions
          positionActionsColumn={"last"}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
          }}
          enableColumnActions={false}
          muiTableBodyRowProps={({ row }) => ({
            //implement row selection click events manually
            onClick: () => {
              setTabValue("2");
              // console.log("PARCELL id:", JSON.stringify(row));
            },
            sx: {
              cursor: "pointer",
            },
          })}
          renderRowActionMenuItems={({ closeMenu, row }) => {
            const menuItems = [
              <MenuItem
                key={0}
                onClick={() => {
                  // View profile logic...
                  setIdsToTransfer([row.id]);
                  setRenameDialogOpen(true);
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                Rename
              </MenuItem>,
            ];

            if (userAuthData.accessRightId >= 1) {
              menuItems.push(
                <MenuItem
                  key={1}
                  onClick={() => {
                    // View profile logic...
                    setIdsToTransfer([row.id]);
                    setDeleteDialogOpen(true);
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Send />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              );
            }

            return menuItems;
          }}
          renderTopToolbar={({ table }) => {
            const handleTransfer = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setOpen(true);
            };

            const handleDisconnect = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setDisconnectDialogOpen(true);
            };

            const handleStatusUpdate = (rows) => {
              setStatusDialogOpen(true);
            };

            return (
              <Box
                sx={(theme) => ({
                  backgroundColor: lighten(
                    theme.palette.background.default,
                    0.05
                  ),
                  display: "flex",
                  gap: "0.5rem",
                  p: "8px",
                  justifyContent: "end",
                })}
              >
                <Box>
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <Button
                      color="info"
                      disableElevation
                      variant="contained"
                      onClick={() =>
                        handleStatusUpdate(table.getSelectedRowModel().rows)
                      }
                    >
                      Status update
                    </Button>
                    <Button
                      disabled={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                      }
                      //only export selected rows
                      color="info"
                      disableElevation
                      variant="contained"
                      onClick={() =>
                        handleTransfer(table.getSelectedRowModel().rows)
                      }
                    >
                      Transfer
                    </Button>
                  </Box>
                </Box>
                <MrtToggleFiltersButton table={table} />
                <MrtShowHideColumnsButton table={table} />
              </Box>
            );
          }}
        />
      </Box>
      <TransferDialog
        methods={methods}
        dialogOpen={open}
        entityName={`Pallets`}
        handleClose={setOpen}
        idsToTransfer={idsToTransfer}
        palletAutocompleteAllowed={false}
        cartonAutocompleteAllowed={false}
        collectionParcelAutocompleteAllowed={false}
      />

      <DisconnectDialog
        methods={methods}
        dialogOpen={disconnectDialogOpen}
        entityName={`Pallets`}
        handleClose={setDisconnectDialogOpen}
        onSubmitDisconnect={onSubmitDisconnect}
        idsToTransfer={idsToTransfer}
      />

      <RenameDialog
        methods={methods}
        dialogOpen={renameDialogOpen}
        entityName={`Pallets`}
        handleClose={setRenameDialogOpen}
        onSubmitRename={onSubmitRename}
        idsToTransfer={idsToTransfer}
      />

      <StatusDialog
        methods={methods}
        dialogOpen={statusDialogOpen}
        entityName={`Pallets`}
        handleClose={setStatusDialogOpen}
        onSubmitStatusBulkUpdate={onSubmitStatusBulkUpdate}
        idsToTransfer={idsToTransfer}
        shipmentStatuses={ddVariables.SHIPMENT_STATUS}
      />

      <DeleteDialog
        methods={methods}
        dialogOpen={deleteDialogOpen}
        entityName={`Pallets`}
        handleClose={setDeleteDialogOpen}
        onSubmitDelete={onSubmitDelete}
        idsToTransfer={idsToTransfer}
      />
    </>
  );
};

export default PalletListTable;
