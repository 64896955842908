import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import DataTable from "../../../components/datatable/Datatable";
import { getParcelsByShipmentId } from "../store/shipmentSlice";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { Dropdown } from "@mui/base/Dropdown";
import {
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from "material-react-table";
import { blue, grey } from "@mui/material/colors";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { forwardRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import TransferDialog from "./dialogs/TransferDialog";
import DisconnectDialog from "./dialogs/DisconnectDialog";
import GroupIntoDialog from "./dialogs/GroupIntoDialog";
import { AccountCircle, Send } from "@mui/icons-material";
import {
  deleteCarton,
  disconnectCarton,
  groupingCarton,
  transferCarton,
  updateCarton,
  updateCartonBulkStatus,
} from "../../cartons/store/cartonAction";
import { useDispatch, useSelector } from "react-redux";
import RenameDialog from "./dialogs/RenameDialog";
import DeleteDialog from "./dialogs/DeleteDialog";
import { openSnackbar } from "../../../store/variablesSlice";
import StatusDialog from "./dialogs/StatusDialog";

const columns = [
  {
    header: "Carton ID",
    // Cell: ({ renderedCellValue, row }) => (
    //   <Link to={`/parcel/${row.id}`}>{renderedCellValue}</Link>
    // ),
    accessorKey: "cartonCustomerId",
  },
  {
    header: "Status",
    accessorKey: "statusName",
  },
  {
    header: "Original shipment ID",
    accessorKey: "originalShipmentName",
  },
  {
    header: "Actual shipment ID",
    accessorKey: "actualShipmentName",
  },
  {
    header: "Original pallet ID",
    accessorKey: "originalPalletName",
  },
  {
    header: "Actual pallet ID",
    accessorKey: "actualPalletName",
  },
  {
    header: "Weight",
    accessorKey: "sumWeight",
  },
  {
    header: "Nr of collection parcels",
    accessorKey: "nrOfCollectionParcels",
  },
  {
    header: "Nr of parcels",
    accessorKey: "nrOfParcels",
  },
  {
    header: "Nr of items",
    accessorKey: "nrOfItems",
  },
];

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const CartonListTable = ({ shipmentId, ...props }) => {
  const userAuthData = useSelector((state) => state.auth);
  const ddVariables = useSelector((state) => state.variables);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const [groupIntoDialogOpen, setGroupIntoDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [idsToTransfer, setIdsToTransfer] = useState([]);
  const dispatch = useDispatch();

  function MenuTransitions() {
    // const createHandleMenuClick = (menuItem) => {
    //   return () => {
    //     console.log(`Clicked on ${menuItem}`);
    //   };
    // };

    const exportAllByShipmentToExcel = ({ shipmentId }) => {
      return async () => {
        const link = document.createElement("a");
        link.target = "_blank";
        // link.download = `${customerShipmentReferenceId}-"all-export.xlsx`;

        // exportAllToExcelByShipmentId({ shipmentId: shipmentId })
        //   .then((res) => {
        //     link.href = URL.createObjectURL(new Blob([res.data]));
        //     link.click();
        //   })
        //   .catch((e) => console.log(e));
      };
    };

    return (
      <Dropdown>
        <MenuButton>Export...</MenuButton>
        <Menu sx={{ backgroundColor: "white" }}>
          <MenuItem sx={{ padding: 0 }}>
            <Button
              color="info"
              fullWidth
              onClick={exportAllByShipmentToExcel({
                shipmentId: shipmentId,
              })}
              variant="contained"
              disableElevation
            >
              Export all
            </Button>
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  const onSubmitTransfer = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (
        value == null ||
        value === false ||
        value === undefined ||
        value === ""
      ) {
        console.log(`key: ${key} value: ${value}`);
        delete data[key];
      }
    });
    dispatch(transferCarton({ ...data, ids: idsToTransfer })).then(
      (res) => console.log(res),
      setTransferDialogOpen(false)
    );
    console.log({ ...data, ids: idsToTransfer });
  };

  const onSubmitDisconnect = () => {
    dispatch(disconnectCarton({ ids: idsToTransfer }))
      .then((res) => console.log(res), setDisconnectDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Carton(s) disconnected` }))
      );
  };

  const onSubmitGroupInto = (data) => {
    dispatch(groupingCarton({ ...data, ids: idsToTransfer }))
      .then((res) => console.log(res), setGroupIntoDialogOpen(false))
      .then(() => dispatch(openSnackbar({ message: `Carton(s) regrouped` })));
  };

  const onSubmitRename = (data) => {
    dispatch(
      updateCarton({
        data: { cartonCustomerId: data.entityCustomerRefName },
        cartonId: idsToTransfer[0],
      })
    )
      .then((res) => console.log(res), setDisconnectDialogOpen(false))
      .then(() => dispatch(openSnackbar({ message: `Carton renamed` })));
  };

  const onSubmitDelete = () => {
    dispatch(deleteCarton({ cartonId: idsToTransfer[0] }))
      .then((res) => console.log(res), setDeleteDialogOpen(false))
      .then(() => dispatch(openSnackbar({ message: `Carton deleted` })));
  };

  const onSubmitStatusBulkUpdate = (data) => {
    dispatch(
      updateCartonBulkStatus({
        status: data.entityStatusId,
        shipmentId: shipmentId,
      })
    )
      .then((res) => console.log(res), setStatusDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Cartons status updated` }))
      );
  };

  const methods = useForm();

  return (
    <>
      <Box width={"100%"}>
        <DataTable
          fetchData={getParcelsByShipmentId}
          endpointUrl={`/api/v1/shipment/${shipmentId}/cartons`}
          predefinedColumns={columns}
          enableRowActions
          positionActionsColumn={"last"}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
          }}
          enableColumnActions={false}
          muiTableBodyRowProps={({ row }) => ({
            //implement row selection click events manually
            onClick: () => {
              console.log("CARTONS id:", JSON.stringify(row));
            },
            sx: {
              cursor: "pointer",
            },
          })}
          renderRowActionMenuItems={({ closeMenu, row }) => {
            const menuItems = [
              <MenuItem
                key={0}
                onClick={() => {
                  // View profile logic...
                  setIdsToTransfer([row.id]);
                  setRenameDialogOpen(true);
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                Rename
              </MenuItem>,
            ];

            if (userAuthData.accessRightId >= 1) {
              menuItems.push(
                <MenuItem
                  key={1}
                  onClick={() => {
                    // View profile logic...
                    setIdsToTransfer([row.id]);
                    setDeleteDialogOpen(true);
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Send />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              );
            }

            return menuItems;
          }}
          renderTopToolbar={({ table }) => {
            const handleTransfer = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setTransferDialogOpen(true);
            };

            const handleDisconnect = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setDisconnectDialogOpen(true);
            };

            const handleGroupIntoNew = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setGroupIntoDialogOpen(true);
            };

            const handleStatusUpdate = (rows) => {
              setStatusDialogOpen(true);
            };

            return (
              <div className="flex gap-2 p-2 justify-end bg-opacity-95">
                <div className="flex">
                  <Button
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleStatusUpdate(table.getSelectedRowModel().rows)
                    }
                  >
                    Status update
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleTransfer(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Transfer
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleGroupIntoNew(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Group into new
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleDisconnect(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Disconnect
                  </Button>
                  <div className="flex gap-2">{MenuTransitions()}</div>
                </div>
                <MrtToggleFiltersButton table={table} />
                <MrtShowHideColumnsButton table={table} />
              </div>
            );
          }}
        />
      </Box>

      <TransferDialog
        methods={methods}
        dialogOpen={transferDialogOpen}
        entityName={`Cartons`}
        handleClose={setTransferDialogOpen}
        transferFn={onSubmitTransfer}
        idsToTransfer={idsToTransfer}
        palletAutocompleteAllowed={true}
        cartonAutocompleteAllowed={false}
        collectionParcelAutocompleteAllowed={false}
      />

      <DisconnectDialog
        methods={methods}
        dialogOpen={disconnectDialogOpen}
        entityName={`Cartons`}
        handleClose={setDisconnectDialogOpen}
        onSubmitDisconnect={onSubmitDisconnect}
        idsToTransfer={idsToTransfer}
      />

      <GroupIntoDialog
        methods={methods}
        dialogOpen={groupIntoDialogOpen}
        entityName={`Cartons`}
        handleClose={setGroupIntoDialogOpen}
        onSubmitGroupInto={onSubmitGroupInto}
        idsToTransfer={idsToTransfer}
        groupOptions={["Pallet"]}
      />

      <RenameDialog
        methods={methods}
        dialogOpen={renameDialogOpen}
        entityName={`Cartons`}
        handleClose={setRenameDialogOpen}
        onSubmitRename={onSubmitRename}
        idsToTransfer={idsToTransfer}
      />

      <StatusDialog
        methods={methods}
        dialogOpen={statusDialogOpen}
        entityName={`Cartons`}
        handleClose={setStatusDialogOpen}
        onSubmitStatusBulkUpdate={onSubmitStatusBulkUpdate}
        idsToTransfer={idsToTransfer}
        shipmentStatuses={ddVariables.SHIPMENT_STATUS}
      />

      <DeleteDialog
        methods={methods}
        dialogOpen={deleteDialogOpen}
        entityName={`Cartons`}
        handleClose={setDeleteDialogOpen}
        onSubmitDelete={onSubmitDelete}
        idsToTransfer={idsToTransfer}
      />
    </>
  );
};

export default CartonListTable;
