import { Link } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "react-redux";
import MenuItemButton from "./MenuItemButton";
import { Icon } from "@mui/material";

const drawerWidth = 240;

const navigationItems = [
  {
    label: "Dashboard",
    icon: "dashboard",
    url: "/",
  },
  {
    label: "Shipments",
    icon: "package_2",
    url: "/shipments",
  },
  {
    label: "Planner",
    icon: "package_2",
    url: "/planner",
  },
  {
    label: "Users",
    icon: "group",
    url: "/users",
    role: 1,
  },
  {
    label: "Locations",
    icon: "location_on",
    url: "/locations",
    role: 1,
  },
  {
    label: "Companies",
    icon: "store",
    url: "/companies",
    role: 1,
  },
];

const Sidebar = (props) => {
  const userAuthData = useSelector((state) => state.auth);
  const { window } = props;
  const [drawer, setDrawer] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  /*
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  */

  useEffect(() => {
    setDrawer(
      <div>
        <Toolbar />
        <Divider />
        <List>
          {navigationItems.map((item, index) => {
            if (item.role && userAuthData.accessRightId !== item.role) {
              return null;
            } else {
              return (
                <MenuItemButton
                  key={`sbar-menuitem-${index}`}
                  icon={item.icon}
                  url={item.url}
                  label={item.label}
                />
              );
            }
          })}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={"/logout"}>
              <ListItemIcon>
                <Icon
                  className="material-symbols-outlined"
                  sx={{ color: "#fff", fontSize: 20 }}
                >
                  logout
                </Icon>
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    );
  }, [userAuthData.accessRightId]);

  const container = undefined;

  return (
    <>
      {/* <!-- Sidenav --> */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#444",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "inherit",
          },
          "& .MuiTypography-root": { color: "#fff", fontSize: "0.8rem" },
          "& .MuiSvgIcon-root": { color: "#fff", fontSize: "1rem" },
          "& .MuiListItemButton-root:hover, & .MuiListItemButton-root.active": {
            backgroundColor: "rgb(0 0 0 / 13%)",
          },
        }}
        className={"bg-slate-600"}
        open
      >
        {drawer}
      </Drawer>
      {/* <!-- Sidenav --> */}
    </>
  );
};

export default memo(Sidebar);
