import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getCompanies = createAsyncThunk(
  "companies/getCompanies",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/company`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        // withCredentials: true,
      }
    );

    return response;
  }
);

export const getById = createAsyncThunk(
  "companies/getById",
  async ({ companyId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/company/${companyId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const getByCategoryId = createAsyncThunk(
  "companies/getByCategoryId",
  async ({ categoryId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/company/category/${categoryId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const createCompany = createAsyncThunk(
  "companies/create",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/company`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updateCompany = createAsyncThunk(
  "companies/update",
  async ({ payload, companyId }) => {
    console.log("companyId at updateLocation:", companyId);
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/company/${companyId}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

const companiesAdapter = createEntityAdapter({});

export const { selectAll: selectCompanies, selectById: selectProjectById } =
  companiesAdapter.getSelectors((state) => state.companies);

const companiesSlice = createSlice({
  name: "companies",
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export const { setCompaniesSearchText } = companiesSlice.actions;

export default companiesSlice.reducer;
