import { useDispatch } from "react-redux";
import withRouter from "../../services/withrouter";
import { memo, useEffect, useState } from "react";
import { getParcelById, getParcelFiles } from "./store/parcelSlice";
import { getLocations } from "../locations/store/locationsSlice";
import { getCompanies } from "../companies/store/companiesSlice";
import Tab from "@mui/material/Tab";
import ParcelInfoBlock from "./tabs/ParcelInfo";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CommentBlock from "../shipments/components/CommentBlock";
import ParcelDocumentTab from "./tabs/ParcelDocument";
import { getExportingCompanies } from "../exporting-companies/store/exportingCompaniesSlice";

const ParcelView = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState("1");
  const parcelId = props.params.parcelId;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const runInitialTasks = async () => {
      await Promise.all([
        dispatch(getParcelById({ parcelId: Number(parcelId) })),
        dispatch(getLocations()),
        dispatch(getCompanies()),
        dispatch(getExportingCompanies()),
        // dispatch(getAllDynamicDataVariables()),
        // dispatch(getEnums()),
      ]);
    };

    runInitialTasks().then(() =>
      dispatch(getParcelFiles({ parcelId: Number(parcelId) })).then(() =>
        setLoading(false)
      )
    );
  }, [parcelId, dispatch]);

  if (loading) return null;

  return (
    <div className="w-full">
      <TabContext value={tabValue} sx={{ width: "80%" }}>
        <div className="border-b border-divider">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Parcel info" value="1" />
            <Tab label="Documents" value="2" />
          </TabList>
        </div>
        <div className="flex">
          <TabPanel value="1" sx={{ width: "80%" }}>
            <ParcelInfoBlock />
          </TabPanel>
          <TabPanel value="2" sx={{ width: "80%" }}>
            <ParcelDocumentTab parcelId={Number(parcelId)} />
          </TabPanel>

          <div className="w-[20%] mt-[1rem]">
            <div>
              <CommentBlock
                label={"Comments / notes"}
                multiline
                rows={6}
                fullWidth
                InputProps={{ style: { fontSize: 12, resize: 'both' } }}
                InputLabelProps={{
                  style: { fontSize: 15, paddingRight: 15 }
                }}
              />
            </div>
            <div>History</div>
          </div>
        </div>
      </TabContext>
    </div>
  );
};

export default withRouter(ParcelView);
