import { useDispatch, useSelector } from "react-redux";
import withRouter from "../../services/withrouter";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { memo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLocation } from "./store/locationsSlice";
import { openSnackbar } from "../../store/variablesSlice";

const schema = yup.object().shape({
  locationName: yup
    .string()
    .required("You must enter a location name")
    .min(5, "The location name must be at least 5 characters"),
  email: yup.string().required("You must enter email address"),
  countryId: yup.number().required("You must select a country"),
  locationTypeId: yup.number().required("You must select a location type"),
  city: yup.string().required("You must enter a city name"),
  zip: yup.string().required("You must enter a ZIP code"),
  address: yup.string().required("You must enter address"),
  houseNumber: yup.string().required("You must enter house number"),
  phone: yup.string().required("You must enter a phone number")
});

const LocationAdd = (props) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.variables.COUNTRY);
  const locationTypes = useSelector((state) => state.variables.LOCATION_TYPE);

  const defaultValues = {
    locationName: "",
    locationHuName: "",
    locationTypeId: 0,
    email: "",
    countryId: 0,
    city: "",
    zip: "",
    address: "",
    houseNumber: "",
    phone: "",
    opened: "",
    checkIn: "",
    requirements: "",
    website: "",
    destinationPort: "",
    shortName: "",
    // attachment: [""],
    notes: "",
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("form post data:", data);
    dispatch(createLocation(data))
      .then(() => dispatch(openSnackbar({ message: `Location created.` })))
      .catch((e) =>
        dispatch(
          openSnackbar({ message: `Error: ${e.error} (${e.statusCode})` })
        )
      );
  };

  if (!countries || !locationTypes) return null;

  return (
    <div className="w-full">
      <div className="mb-[1.5rem]">Create new location</div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <Paper className="p-4 w-[50%]">
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Location name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="locationName"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>
            
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Location HU name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="locationHuName"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Location type"}</InputLabel>
              </div>
              <div className="w-7/12 self-center">
                <Controller
                  control={methods.control}
                  name="locationTypeId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {locationTypes.map((item, idx) => (
                        <MenuItem key={`locType-${idx}`} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Email"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Country"}</InputLabel>
              </div>
              <div className="w-7/12 self-center">
                <Controller
                  control={methods.control}
                  name="countryId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value={0}>Please select</MenuItem>
                      {countries.map((item, idx) => (
                        <MenuItem key={`country-${idx}`} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"City"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="city"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"ZIP code"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="zip"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Address"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="address"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"House number"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="houseNumber"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Phone number"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="phone"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Opened"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="opened"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Check in"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="checkIn"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Requirements"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="requirements"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Website"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="website"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Destination port"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="destinationPort"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Short name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="shortName"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <Controller
                control={methods.control}
                name="notes"
                render={({ field, fieldState }) => (
                  <TextField
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    multiline
                    fullWidth
                    rows={5}
                    label="Service notes"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.8rem" },
                    }}
                  />
                )}
              />
            </div>
            <div className="w-[20%] flex justify-center">
              <Button type="submit">Create</Button>
            </div>
          </Paper>
        </form>
      </FormProvider>
    </div>
  );
};

export default withRouter(memo(LocationAdd));
