import { Box, InputLabel, Typography } from "@mui/material";

const ParcelItemBox = (props) => {
  const itemData = props.itemData;

  return (
    <Box marginBottom={2}>
        <Box display={'flex'}>
            <InputLabel>Item HS code: </InputLabel>
            <Typography>{itemData.itemHsCode}</Typography>
        </Box>
        <Box display={'flex'}>
            <InputLabel>Item name: </InputLabel>
            <Typography>{itemData.itemName}</Typography>
        </Box>
        <Box display={'flex'}>
            <InputLabel>Item qty: </InputLabel>
            <Typography>{itemData.itemQuantity}</Typography>
        </Box>
        <Box display={'flex'}>
            <InputLabel>Item value: </InputLabel>
            <Typography>{itemData.itemValue} {itemData.itemValueCurrency}</Typography>
        </Box>
        <Box display={'flex'}>
            <InputLabel>Item weight: </InputLabel>
            <Typography>{itemData.itemWeight} kg</Typography>
        </Box>
    </Box>
  );
};

export default ParcelItemBox;
