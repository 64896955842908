import { Button } from "@mui/material";
import { useState } from "react";
import T1Dialog from "./dialogs/T1Dialog";

const T1Button = ({ shipmentId }) => {
  const [openT1Dialog, setOpenT1Dialog] = useState(false);

  return (
    <>
      <div className="content-center">
        <Button onClick={() => setOpenT1Dialog(true)}>T1 Request</Button>
      </div>
      <T1Dialog
        dialogOpen={openT1Dialog}
        handleClose={() => setOpenT1Dialog(false)}
        shipmentId={shipmentId}
      />
    </>
  );
};

export default T1Button;
