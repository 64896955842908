import { connect, useDispatch, useSelector } from "react-redux";
// import { matchRoutes } from 'react-router-config'
import { useEffect, useState } from "react";
import withRouter from "../withrouter";
import { useNavigate } from "react-router";
import { signInWithToken } from "../../pages/login/store/loginSlice";
import { logout, setSession } from "../jwtservice";
import axios from "axios";
import Login from "../../pages/login";

const Authorization = (props) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const [accessGranted, setAccessGranted] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const location = useLocation();
  const token = window.localStorage.getItem("jwt_access_token");

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return new Promise((resolve, reject) => {
        if (
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          // if you ever get an unauthorized response, logout the user
          // this.emit('onAutoLogout', 'Invalid access_token')
          // this.setSession(null)
          setSession(null);
          logout(navigate);
          return false;
        }
        throw err;
      });
    }
  );

//  if (!token && location.pathname !== '/login') {
//    navigate("/login", { replace: true });
//    // return false;
//  }

  useEffect(() => {
    
    const redirectRoute = async () => {
      /*
            User is guest
            Redirect to Login Page
            */
      // if (!userRole || userRole.length === 0) {
      // if (!token && pathname !== "/login") {
      if (!token) {
        navigate("/login", { replace: true });
        setAccessGranted(false);
        setLoading(false);
      } else {
        if (!authData.email) {
          try {
            dispatch(signInWithToken({ token: token }))
              .then((response) => {
                  setSession(token);
                  setAccessGranted(true);
                  setLoading(false);
//                  navigate(pathname);
              })
              .catch((e) => console.log("ERRRR:", e));
          } catch (e) {
            navigate("/login", { replace: true });
            setAccessGranted(false);
            setLoading(false);
          }
        } else {
          // setSession(token);
          setAccessGranted(true);
          setLoading(false);
//          navigate(pathname);
        }
      }
    };

    redirectRoute();
  }, [authData.email, dispatch, token, navigate]);

  if (loading) return null;

  // navigate(pathname);

  return accessGranted ? <>{props.children}</> : <Login {...props} />;
};

function mapStateToProps({ auth }) {
  return {
    // userRole: auth.user.role,
  };
}

export default withRouter(connect(mapStateToProps)(Authorization));
// Authorization.contextType = AppContext
