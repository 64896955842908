/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShipmentTrucker,
  setTruckerDialogClose,
} from "../../pages/planner/store/plannerSlice";
import { getByCategoryId } from "../../pages/companies/store/companiesSlice";
import { Controller, FormProvider, useForm } from "react-hook-form";

const PlannerTruckerDialog = () => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState(null);
  const { truckerDialogOpen, shipmentMergeId, shippingType } = useSelector(
    (state) => state.planner
  );

  const defaultValues = {
    companyId: "",
    shippingVehicleId: "",
    truckerPhoneNumber: "",
  };

  const methods = useForm({
    defaultValues,
    // resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getByCategoryId({ categoryId: 55 })).then((res) => {
      console.log("res:", res);
      setCompanies(res.payload.data);
    });
  }, [dispatch]);

  const handleClose = () => {
    dispatch(setTruckerDialogClose());
    methods.reset(defaultValues);
  };

  const handleAdd = () => {
    // Add logic for adding the selected option
    dispatch(setTruckerDialogClose());
  };

  const onSubmit = (data) => {
    console.log("form post data:", {
      ...data,
      mergedShipmentId: shipmentMergeId,
    });
    dispatch(
      setShipmentTrucker({
        ...data,
        mergedShipmentId: shipmentMergeId,
        shippingType: shippingType.toString(),
      })
    ).then((res) => {
      console.log("res:", res);
      dispatch(setTruckerDialogClose());
      methods.reset(defaultValues);
    });
  };

  return (
    <>
      <Dialog
        open={truckerDialogOpen}
        fullWidth
        onClose={handleClose}
        sx={{ minWidth: "25rem" }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              Trucker
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => handleClose()}
                sx={{ ml: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Controller
                control={methods.control}
                name="companyId"
                render={({ field, fieldState }) => (
                  <Select {...field} size="small">
                    {companies &&
                      companies.map((company) => {
                        return (
                          <MenuItem key={company.id} value={company.id}>
                            {company.companyName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              />
              <div>
                <Controller
                  control={methods.control}
                  name="shippingVehicleId"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      size="small"
                      placeholder="Shipping vehicle ID"
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={methods.control}
                  name="truckerPhoneNumber"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      size="small"
                      placeholder="Trucker phone number"
                    />
                  )}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Add</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default memo(PlannerTruckerDialog);
