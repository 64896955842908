import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { closeErrorDialog } from "../../../../store/variablesSlice";

const ErrorDialog = ({
  dialogOpen,
  ...props
}) => {
  const dispatch = useDispatch();
  const errorDialogSettings = useSelector((state) => state.errorDialog);
  const { open, message, title } = errorDialogSettings;

  const handleClose = () => {
    dispatch(closeErrorDialog())
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(false);
      }}
    >
      <FormProvider>
        <form
          autoComplete="off"
        >
          <DialogTitle>{title || 'Error'}</DialogTitle>
          <DialogContent>
            <DialogContentText className="whitespace-break-spaces">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose(false);
              }}
            >
              Acknowledge
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ErrorDialog;
