/* eslint-disable react/display-name */
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPickupPlannerList,
  mergeShipments,
  setTruckerDialogOpen,
  unmergeShipments,
} from "./store/plannerSlice";
import { Button, Checkbox, TextField, styled } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime, Settings } from "luxon";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { updatePlannerData } from "../shipments/store/shipmentSlice";
import { useForm } from "react-hook-form";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

Settings.defaultZone = "Europe/Budapest";

const CustomizedDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    font-size: 13px;
  }
`;

const StyledButton = styled(Button)(() => ({
  color: "#FFF" /* Királykék szín */,
  backgroundColor: "#13b2ec",
  textTransform: "none",
  ":hover": {
    color: "#FFF" /* Királykék szín */,
    backgroundColor: "#0ba7e0",
  },
}));

const CustomDatePicker = ({ date, ...props }) => {
  return (
    <CustomizedDateTimePicker
      // {...field}
      // error={!!fieldState.error}
      // helperText={fieldState.error?.message}
      value={date ? DateTime.fromISO(date) : null}
      variant="standard"
      ampm={false}
      disableOpenPicker
      fullWidth
      label={`CET time`}
      sx={{
        ...(props.sx || null),
        fontSize: "11px",
        "& .MuiInputLabel-root": {
          fontSize: "12px",
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
          fontSize: "16px",
        },
      }}
      slotProps={{
        textField: {
          size: "small",
          sx: {
            fontSize: "25px",
          },
          variant: "standard",
        },
        InputProps: {
          fontWeight: "bold",
          fontSize: "21px",
          sx: {
            fontWeight: "bold",
          },
        },
      }}
    />
  );
};

const Planner = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedShipmentIds, setSelectedShipmentIds] = useState([]);
  const pickupShipments =
    useSelector((state) => state.planner.pickupPlanner) || [];

  useEffect(() => {
    console.log("pickup planner list useEffect meghívódik!");
    dispatch(getPickupPlannerList()).then(() => {
      setLoading(false);
    });
  }, []);

  const defaultValues = {};

  const methods = useForm({
    defaultValues,
    // resolver: yupResolver(schema),
  });

  const handleCheckBox = (checked, shipmentId) => {
    if (checked) {
      setSelectedShipmentIds([...selectedShipmentIds, shipmentId]);
    } else {
      setSelectedShipmentIds(
        selectedShipmentIds.filter((id) => id !== shipmentId)
      );
    }
  };

  const handleUpdateShipmentDate = ({ data, shipmentId }) => {
    console.log("shipmentId:", shipmentId);
    console.log("data:", data);

    dispatch(
      updatePlannerData({
        payload: data,
        shipmentId: shipmentId,
      })
    ).then(() => {
      console.log("ok");
    });
  };

  const handleMerge = () => {
    console.log("selectedShipmentIds:", selectedShipmentIds);
    dispatch(mergeShipments({ shipmentIds: selectedShipmentIds }));
  };

  const handleUnmerge = () => {
    console.log("unmerge:", selectedShipmentIds);
    dispatch(unmergeShipments({ shipmentId: selectedShipmentIds[0] }));
  };

  const DateInputField = memo(({ fieldName, date, isAct, shipmentId }) => {
    const [val, setVal] = useState(
      date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd HH:mm") : ""
    );

    return (
      <InputMask
        mask="9999-99-99 99:99"
        value={val}
        maskChar=""
        name="inputmask"
        // placeholder="YYYY-MM-DD HH:mm"
        onChange={(e) => {
          setVal(e.target.value);
        }}
        onBlur={(e) => {
          return e.target.value
            ? handleUpdateShipmentDate({
                data: { [fieldName]: e.target.value },
                shipmentId: shipmentId,
              })
            : false;
        }}
      >
        {(inputProps) => (
          <input
            {...inputProps}
            type="text"
            className="date-input"
            style={{
              fontWeight: isAct ? "bold" : "normal",
              fontSize: "12px",
              width: "95%",
            }}
          />
        )}
      </InputMask>
    );
  });

  const RowItem = memo(({ data, idx }) => {
    const [plannerNote, setPlannerNote] = useState(data.plannerNote || "");

    const truckerArea = () => {
      if (data.mergedShipmentId) {
        if (data.mergedShipmentId !== data.id) {
          return "";
        } else {
          return (
            <>
              <StyledButton
                onClick={() =>
                  dispatch(
                    setTruckerDialogOpen({
                      shipmentMergeId: data.mergedShipmentId,
                      shippingType: 1,
                    })
                  )
                }
              >
                {data.shipmentPartners.length
                  ? "Change trucker"
                  : "Add trucker"}
              </StyledButton>
              {data.shipmentPartners.length
                ? data.shipmentPartners.map((partner, idx) => {
                    return (
                      <div key={`partner-${idx}`}>
                        {`${partner.companyName}\n${
                          partner.shippingVehicleId || "-"
                        } | ${partner.truckDriverPhone || "-"}`}
                      </div>
                    );
                  })
                : ""}
            </>
          );
        }
      } else {
        return data.shipmentPartners.map((partner, idx) => {
          return (
            <div key={`partner-${idx}`}>
              {`${partner.companyName}\n
              ${partner.shippingVehicleId || ""} | ${
                partner.truckDriverPhone || ""
              }`}
            </div>
          );
        });
      }
    };

    return (
      <div
        key={`row-${idx}`}
        style={{ backgroundColor: data.mergeColor || "" }}
        className={`planner-row flex py-2 items-center`}
        data-shipment-id={data.id}
      >
        <span className="w-12 inline-block">
          <Checkbox
            onChange={(e) =>
              handleCheckBox(e.target.checked ? true : false, data.id)
            }
            value={data.id}
            checked={selectedShipmentIds.includes(data.id)}
            name={data.id}
            data-shipment-id={data.id}
          />
        </span>
        <span className="w-52 inline-block">
          <div>
            <b>
              <Link to={`/shipments/${data.id}`}>
                {data.customerShipmentReferenceId}
              </Link>
            </b>
          </div>
          <div>
            {data.arrivalLocationName} | {data.exportingCompanyName}
          </div>
          <div>
            {data.grossWeight ? parseFloat(data.grossWeight) : "-"} kg |{" "}
            {data.cbm || "-"} cbm
          </div>
          <div>
            {data.cartonsCnt} ctn | {data.parcelsCnt} parcels
          </div>
          <div>
            {data.internationalShipperCompanyName} | {data.shippingVehicleId} |{" "}
            {data.groundHandlingCompanyName}
          </div>
          <div>NOA</div>
        </span>
        <span className="w-28 inline-block">{data.status}</span>
        <span className="w-10 inline-block">
          <div className="mb-4">EST:</div>
          <div>ACT:</div>
        </span>
        <span className="w-28 inline-block">
          <div key={`arrivalToEuEst-${idx}`}>
            <DateInputField
              date={data.arrivalToEuEst}
              shipmentId={data.id}
              fieldName={"arrivalToEuEst"}
            />
          </div>
          <div key={`arrivalToEuAct-${idx}`}>
            <DateInputField
              isAct
              date={data.arrivalToEuAct}
              shipmentId={data.id}
              fieldName={"arrivalToEuAct"}
            />
          </div>
        </span>
        <span className="w-28 inline-block">
          <div>
            <DateInputField
              date={data.groundHandlingNotificationEst}
              shipmentId={data.id}
              fieldName={"groundHandlingNotificationEst"}
            />
          </div>
          <div>
            <DateInputField
              isAct
              date={data.groundHandlingNotificationAct}
              shipmentId={data.id}
              fieldName={"groundHandlingNotificationAct"}
            />
          </div>
        </span>
        <span className="w-28 inline-block">
          <div>
            <DateInputField
              date={data.pickupEst}
              shipmentId={data.id}
              fieldName={"pickupEst"}
            />
          </div>
          <div>
            <DateInputField
              isAct
              date={data.pickupAct}
              shipmentId={data.id}
              fieldName={"pickupAct"}
            />
          </div>
        </span>
        <span className="w-28 inline-block">
          <div>
            <DateInputField
              date={data.arrivalToCcEst}
              shipmentId={data.id}
              fieldName={"arrivalToCcEst"}
            />
          </div>
          <div>
            <DateInputField
              isAct
              date={data.arrivalToCcAct}
              shipmentId={data.id}
              fieldName={"arrivalToCcAct"}
            />
          </div>
        </span>
        <span className="w-48 inline-block text-center">{truckerArea()}</span>
        <span className="w-64 inline-block">
          <TextField
            multiline
            fullWidth
            value={plannerNote}
            rows={2}
            sx={{
              "& .MuiInputBase-root": {
                padding: "0.5rem",
                lineHeight: "1rem",
              },
              "& .MuiInputBase-input": {
                fontSize: "12px",
              },
            }}
            onChange={(e) => {
              setPlannerNote(e.target.value);
            }}
            onBlur={(e) => {
              handleUpdateShipmentDate({
                data: {
                  plannerNote: e.target.value,
                },
                shipmentId: data.id,
              });
            }}
          />
        </span>
      </div>
    );
  });

  const RenderedRows = () => {
    return (
      pickupShipments.length &&
      pickupShipments.map((data, idx) => {
        return <RowItem key={`row-${idx}`} data={data} idx={idx} />;
      })
    );
  };

  // if (loading) return null;
  console.log("rendering, truckerDialogOpen:");

  return (
    <div className="w-[fit-content]">
      <div>Planner</div>
      <div>
        <Button
          onClick={handleMerge}
          disabled={selectedShipmentIds.length > 1 ? false : true}
        >
          Merge
        </Button>
        <Button
          onClick={handleUnmerge}
          disabled={selectedShipmentIds.length ? false : true}
        >
          Unmerge
        </Button>
      </div>
      <div className="head-title">
        <span className="w-12 inline-block"></span>
        <span className="w-52 inline-block">Shipment</span>
        <span className="w-28 inline-block">Status</span>
        <span className="w-10 inline-block"></span>
        <span className="w-28 inline-block">Arrival</span>
        <span className="w-28 inline-block">NOA</span>
        <span className="w-28 inline-block">Pickup</span>
        <span className="w-28 inline-block">Arrival to CC</span>
        <span className="w-48 inline-block">Trucker</span>
        <span className="w-64 inline-block">Notes</span>
      </div>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={navigator.language}
      >
        <RenderedRows />
      </LocalizationProvider>
    </div>
  );
};

export default Planner;
