import { useDispatch, useSelector } from "react-redux";
import withRouter from "../../services/withrouter";
import { useEffect, useState } from "react";
import DataTable from "../../components/datatable/Datatable";
import { Box, Button, lighten } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const columns = [
  {
    accessorKey: "locationName",
    header: "Name",
  },
  {
    accessorKey: "locationTypeName",
    header: "Location type",
  },
  {
    accessorKey: "city",
    header: "City",
  },
  {
    accessorKey: "address",
    header: "Address",
  },
  {
    accessorKey: "phone",
    header: "Phone",
  },
  {
    accessorKey: "opened",
    header: "Opened",
  },
  {
    accessorKey: "shortName",
    header: "Short name",
  },
];

const LocationsList = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationsData = useSelector((state) => state.locations);
  const locationTypes = useSelector((state) => state.variables.LOCATION_TYPE);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  // const [dialogOpen, setDialogOpen] = useState(false);

  // useEffect(() => {
  //   console.log("locations list useEffect meghívódik!");
  //   dispatch(getLocations()).then(() => {
  //     setLoading(false);
  //   });
  // }, [dispatch]);

  //if (loading) return null;

  return (
    <Box width={"100%"}>
      Locations List{" "}
      <DataTable
        endpointUrl={`/api/v1/location`}
        predefinedColumns={columns}
        enableDensityToggle={false}
        initialState={{
          density: "compact",
        }}
        // enableTopToolbar={false}
        enableColumnActions={false}
        muiTableBodyRowProps={({ row }) => ({
          //implement row selection click events manually
          onClick: () => {
            navigate(`/locations/${row.id}`);
            // console.log("Location id:", JSON.stringify(row));
          },
          sx: {
            cursor: "pointer",
          },
        })}
        renderTopToolbar={({ table }) => {
          return (
            <Box
              sx={(theme) => ({
                backgroundColor: lighten(
                  theme.palette.background.default,
                  0.05
                ),
                display: "flex",
                gap: "0.5rem",
                p: "8px",
                justifyContent: "end",
              })}
            >
              <Box>
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                  <Button
                    color="info"
                    // onClick={handleDeactivate}
                    variant="contained"
                    disableElevation
                    component={Link}
                    to="/locations/add"
                  >
                    New
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        }}
      />
      {/*locationsData.map((item, idx) => {
        return <div key={idx}>{JSON.stringify(item)}</div>;
      })*/}
    </Box>
  );
};

export default withRouter(LocationsList);
