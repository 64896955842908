import { useDispatch } from "react-redux";
import withRouter from "../../services/withrouter";
import { memo, useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { getLocations } from "../locations/store/locationsSlice";
import { getCompanies } from "../companies/store/companiesSlice";
import CommentBlock from "./components/CommentBlock";
import ShipmentTitle from "./components/ShipmentTitle";
import { cleanState } from "./store/shipmentSlice";
import { getExportingCompanies } from "../exporting-companies/store/exportingCompaniesSlice";
import ShipmentInfoCreateTab from "./tabs/ShipmentInfoCreate";


const MemoizedShipmentInfoTab = memo((props) => {
  return <ShipmentInfoCreateTab />;
});

const ShipmentCreate = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const runInitialTasks = async () => {
      await Promise.all([
        dispatch(getLocations()),
        dispatch(getCompanies()),
        dispatch(getExportingCompanies()),
        // dispatch(getShipments()),
        // dispatch(getAllDynamicDataVariables()),
        // dispatch(getEnums()),
      ]);
    };

    dispatch(cleanState());
    runInitialTasks().then(() => setLoading(false));
  }, [dispatch]);

  if (loading) return null;

  console.log("shipmentCreate render after LOADING");
  return (
    <Box width={"100%"}>
      <ShipmentTitle />
      <TabContext value={tabValue} sx={{ width: "80%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Shipment info" value="1" />
            <Tab label="Documents" value="2" disabled />
            <Tab label="Parcel list" value="3" disabled />
          </TabList>
        </Box>
        <div className="flex">
          <TabPanel value="1" sx={{ width: "80%" }}>
            <MemoizedShipmentInfoTab loading={loading} />
          </TabPanel>
          {tabValue === "3" ? null : (
            <div className="w-[20%] mt-3">
              <div>
                <CommentBlock
                  label={"Comments / notes"}
                  multiline
                  rows={6}
                  fullWidth
                />
              </div>
              <div>History</div>
            </div>
          )}
        </div>
      </TabContext>
      {/*locationsData.map((item, idx) => {
        return <div key={idx}>{JSON.stringify(item)}</div>;
      })*/}
    </Box>
  );
};

export default withRouter(memo(ShipmentCreate));
