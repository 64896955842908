import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

const DeleteFileDialog = ({
  dialogOpen,
  handleClose,
  onSubmitDelete,
  ...props
}) => {
  const methods = useForm({});

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        methods.reset();
        handleClose(false);
      }}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => onSubmitDelete(data))}
          autoComplete="off"
        >
          <DialogTitle>Delete file</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure want to delete this file?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose(false);
                methods.reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Delete</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default DeleteFileDialog;
