import { useDispatch, useSelector } from "react-redux";
import withRouter from "../../services/withrouter";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import * as yup from "yup";
import { useYupValidationResolver } from "../../shared-components/yupResolver";
import BreadCrumb from "./components/BreadCrumb";
import { openSnackbar } from "../../store/variablesSlice";
import { createUser } from "./store/usersSlice";
import { getCompanies } from "../companies/store/companiesSlice";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("You must enter a first name")
    .min(2, "First name must be at least 2 characters"),
  lastName: yup
    .string()
    .required("You must enter a last name")
    .min(2, "Last name must be at least 2 characters"),
  email: yup
    .string()
    .required("You must enter email address")
    .email("Invalid email address")
    .min(2, "Last name must be at least 2 characters"),
  password: yup
    .string()
    .required("You must set password")
    .min(8, "Password must be at least 8 characters")
    .oneOf([yup.ref("passwordAgain")], "Passwords must match"),
  passwordAgain: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const userTypes = [
  { id: 0, value: "Normal" },
  { id: 1, value: "Superadmin" },
];

const UserAdd = (props) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getCompanies()).then((res) => {
      setCompanies(res.payload.data);
      setLoading(false);
    });
  }, [dispatch]);

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordAgain: "",
    accessRightId: 0,
    companyId: 0,
  };

  const methods = useForm({
    values: defaultValues,
    resolver: useYupValidationResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(createUser({ ...data }))
      .then(() => dispatch(openSnackbar({ message: `User created.` })))
      .catch((e) =>
        dispatch(
          openSnackbar({ message: `Error: ${e.error} (${e.statusCode})` })
        )
      );
  };

  if (loading) return null;

  return (
    <div className="w-full">
      <BreadCrumb />
      <div className="mb-[1.5rem]">Add user</div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <Paper className="p-4 w-[50%]">
            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"First name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="firstName"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Last name"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="lastName"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Email address"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="text"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Password"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="password"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="password"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Password again"}</InputLabel>
              </div>
              <div className="w-7/12">
                <Controller
                  control={methods.control}
                  name="passwordAgain"
                  render={({ field, fieldState }) => (
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      fullWidth
                      variant="standard"
                      type="password"
                    />
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"User type"}</InputLabel>
              </div>
              <div className="w-7/12 self-center">
                <Controller
                  control={methods.control}
                  name="accessRightId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      {userTypes.map((item, idx) => (
                        <MenuItem key={`userType-${idx}`} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="relative mb-5 flex">
              <div className="w-5/12 self-center">
                <InputLabel>{"Company"}</InputLabel>
              </div>
              <div className="w-7/12 self-center">
                <Controller
                  control={methods.control}
                  name="companyId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      color="secondary"
                      variant="standard"
                      size="small"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      {companies.data.map((item, idx) => (
                        <MenuItem key={`company-${idx}`} value={item.id}>
                          {item.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="w-[20%] flex justify-center">
              <Button type="submit">Create</Button>
            </div>
          </Paper>
        </form>
      </FormProvider>
    </div>
  );
};

export default withRouter(memo(UserAdd));
