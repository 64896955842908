import withRouter from "../../services/withrouter";
import { Box, Button, lighten } from "@mui/material";
import DataTable from "../../components/datatable/Datatable";
import { Link, useNavigate } from "react-router-dom";
import { useMemo } from "react";

const UsersList = (props) => {
  const navigate = useNavigate();
  const columns = useMemo(() => [
    {
      accessorKey: "firstName",
      header: "First name",
    },
    {
      accessorKey: "lastName",
      header: "Last name",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "isActive",
      header: "Active",
    },
  ], []);

  return (
    <Box width={"100%"}>
      Users
      <DataTable
        endpointUrl={`/api/v1/user`}
        predefinedColumns={columns}
        enableDensityToggle={false}
        initialState={{
          density: "compact",
        }}
        // enableTopToolbar={false}
        enableColumnActions={false}
        muiTableBodyRowProps={({ row }) => ({
          //implement row selection click events manually
          onClick: () => {
            navigate(`/users/${row.id}`);
          },
          sx: {
            cursor: "pointer",
          },
        })}
        renderTopToolbar={({ table }) => {
          return (
            <Box
              sx={(theme) => ({
                backgroundColor: lighten(
                  theme.palette.background.default,
                  0.05
                ),
                display: "flex",
                gap: "0.5rem",
                p: "8px",
                justifyContent: "end",
              })}
            >
              <Box>
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                  <Button
                    color="info"
                    // onClick={handleDeactivate}
                    variant="contained"
                    disableElevation
                    component={Link}
                    to="/users/add"
                  >
                    New
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default withRouter(UsersList);
