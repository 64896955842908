import { combineReducers } from "@reduxjs/toolkit";
import auth from "../pages/login/store/loginSlice";
import users from "../pages/users/store/usersSlice";
import companies from "../pages/companies/store/companiesSlice";
import exportingCompanies from "../pages/exporting-companies/store/exportingCompaniesSlice";
import parcel from "../pages/parcels/store/parcelSlice";
import shipment from "../pages/shipments/store/shipmentSlice";
import shipments from "../pages/shipments/store/shipmentsSlice";
import locations from "../pages/locations/store/locationsSlice";
import dashboard from "../pages/dashboard/store/dashboardSlice";
import variables, { enums, errorDialog, snackbar, t1 } from "./variablesSlice";
import planner from "../pages/planner/store/plannerSlice";
// import i18n from './i18nSlice'

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    //		i18n,
    locations,
    companies,
    exportingCompanies,
    shipment,
    shipments,
    users,
    variables,
    enums,
    errorDialog,
    snackbar,
    t1,
    parcel,
    dashboard,
    planner,
    //		accessGroups,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "auth/user/userLoggedOut") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
