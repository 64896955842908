import withRouter from "../../services/withrouter";
import DataTable from "../../components/datatable/Datatable";
import { Box, Button } from "@mui/material";
import { getShipments } from "./store/shipmentsSlice";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import {
  MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from "material-react-table";

const columns = [
  {
    header: "Shipment ID",
    accessorFn: (row) => `${row.shipmentId}`,
    Cell: ({ renderedCellValue, row }) => (
      <Link to={`/shipments/${row.id}`}>{renderedCellValue}</Link>
    ),
    accessorKey: "shipmentId",
  },
  {
    header: "Customer Shipment Id",
    accessorKey: "customerShipmentReferenceId",
  },
  {
    header: "Customer",
    accessorFn: (row) => `${row.exportingCompanyName}`,
    Cell: ({ renderedCellValue, row }) => (
      <Link to={`/company/${row.original.exportingCompanyId}`}>
        {renderedCellValue}
      </Link>
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
  },
  {
    accessorKey: "regionalDeliveryDepotLocationName",
    header: "Regional depot",
  },
  {
    accessorKey: "grossWeight",
    header: "Total weight",
  },
  {
    accessorKey: "cartonsNr",
    header: "Nr of cartons",
  },
  {
    accessorKey: "nrOfParcels",
    header: "Nr of parcels",
  },
  {
    accessorKey: "deliveryCountries",
    header: "Delivery countries",
  },
  {
    header: "Arrived since X days",
    accessorFn: (row) => {
      const date1 = DateTime.local();
      const date2 = DateTime.fromJSDate(new Date(row.arrivalToEuAct));

      return `${
        row.arrivalToEuAct
          ? Math.ceil(date1.diff(date2, "days").values.days)
          : "-"
      }`;
    },
  },
  {
    header: "Arrival to EU (est)",
    accessorFn: (row) =>
      `${DateTime.fromISO(row.arrivalToEuEst).toFormat("yyyy-LL-dd TT")}`,
  },
  {
    header: "Arrival to EU (act)",
    accessorFn: (row) =>
      `${
        row.arrivalToEuAct
          ? DateTime.fromISO(row.arrivalToEuAct).toFormat("yyyy-LL-dd TT")
          : "-"
      }`,
  },
  {
    header: "Pickup date (act)",
    accessorFn: (row) =>
      `${
        row.pickupDateAct
          ? DateTime.fromISO(row.pickupDateAct).toFormat("yyyy-LL-dd TT")
          : "-"
      }`,
  },
  {
    header: "Arrival to CC act",
    accessorFn: (row) =>
      `${
        row.ccDateAct
          ? DateTime.fromISO(row.ccDateAct).toFormat("yyyy-LL-dd TT")
          : "-"
      }`,
  },
  {
    header: "Depot arrival date (act)",
    accessorFn: (row) =>
      `${
        row.depotArrivalDateAct
          ? DateTime.fromISO(row.depotArrivalDateAct).toFormat("yyyy-LL-dd TT")
          : "-"
      }`,
  },
  {
    header: "Last delivery date (act)",
    accessorFn: (row) =>
      `${
        row.lastDeliveryDateAct
          ? DateTime.fromISO(row.lastDeliveryDateAct).toFormat("yyyy-LL-dd TT")
          : "-"
      }`,
  },
];

const ShipmentsList = (props) => {
  // const dispatch = useDispatch();
  // const shipmentsData = useSelector((state) => state.shipments);
  // const [loading, setLoading] = useState(true);
  // const [page, setPage] = useState(1);
  // const [dialogOpen, setDialogOpen] = useState(false);

  // useEffect(() => {
  //   console.log("locations list useEffect meghívódik!");
  //   dispatch(getLocations()).then(() => {
  //     setLoading(false);
  //   });
  // }, [dispatch]);

  //if (loading) return null;

  return (
    <Box width={"100%"}>
      Shipments List{" "}
      <DataTable
        endpointUrl={`/api/v1/shipment`}
        fetchData={getShipments}
        predefinedColumns={columns}
        enableDensityToggle={false}
        enableRowNumbers
        initialState={{
          density: "compact",
          // columnVisibility: { createdAt: false },
          sorting: [
            {
              id: "createdAt", //sort by age by default on page load
              desc: false,
            },
          ],
        }}
        enableColumnActions={false}
        muiTableBodyRowProps={({ row }) => ({
          //implement row selection click events manually
          onClick: () => {
            // console.log("SHIPMENT id:", JSON.stringify(row));
            // console.log("SHIPMENT row clicked");
          },
          sx: {
            cursor: "pointer",
          },
        })}
        renderTopToolbar={({ table }) => {
          return (
            <div className="flex m-1 justify-end">
              <MrtGlobalFilterTextField table={table} />
              <MrtToggleGlobalFilterButton table={table} />
              <MrtToggleFiltersButton table={table} />
              <MrtShowHideColumnsButton table={table} />
              <div className="ml-1">
                <Button
                  color="info"
                  variant="contained"
                  disableElevation
                  component={Link}
                  to="/shipments/add"
                >
                  New
                </Button>
              </div>
            </div>
          );
        }}
        // renderTopToolbar={(data) => {
        //   console.log('TABLE:', data);
        //   // console.log('TABLE getPageCount:', table.getPageCount());
        //   // return (
        //   // <div>itt{table.getRowModel}</div>
        //   // )
        // }}
      />
      {/*locationsData.map((item, idx) => {
        return <div key={idx}>{JSON.stringify(item)}</div>;
      })*/}
    </Box>
  );
};

export default withRouter(ShipmentsList);
