import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

const DataTable = ({ fetchData, endpointUrl, predefinedColumns, ...props }) => {
  //data and fetching state
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState(props?.initialState?.sorting || []);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 200,
  });

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchDataa = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(endpointUrl, `${process.env.REACT_APP_APIURL}`);
      url.searchParams.set(
        "start",
        `${pagination.pageIndex * pagination.pageSize}`
      );
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      try {
        const accessToken = localStorage.getItem("jwt_access_token");
        const response = await axios(url.href, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        // const response = await dispatch(fetchData());
        // console.log("DT RESP:", response);
        // const data = await response.json();
        const data = await response.data;
        setData(data.data);
        setRowCount(data.totalRows);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchDataa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(() => predefinedColumns, [predefinedColumns]);
  //create columns from data
  /*
  const columns = useMemo(
    () => 
      data.length
        ? Object.keys(data[0]).map((columnId) => ({
            header: columnNames[columnId] ?? columnId,
            accessorKey: columnId,
            id: columnId,
          }))
        : [],
    [data]
  );
    */
  const table = useMaterialReactTable({
    columns,
    enableStickyHeader: true,
    enableStickyFooter: true,
    data,
    muiTableContainerProps: { sx: { maxHeight: '80vh' } },
    ...props,
    enableRowSelection: true,
    //  muiTableBodyRowProps: ({ row }) => ({
    //      //implement row selection click events manually
    //      onClick: () => { console.log('row id:', JSON.stringify(row) )},
    //      sx: {
    //        cursor: 'pointer',
    //      },
    //    }),
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: true,
      ...props?.initialState,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 200],
    },
    muiTableHeadCellProps: {
      //easier way to create media queries, no useMediaQuery hook needed.
      sx: {
        fontSize: {
          xs: "10px",
          sm: "11px",
          md: "12px",
          lg: "12px",
          xl: "12px",
        },
      },
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  if (isLoading) {
    return null;
  }

  return <MaterialReactTable table={table} />;
};

export default DataTable;
