import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getParcelsList = createAsyncThunk(
  "parcels/getParcelsList",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        // withCredentials: true,
      }
    );

    return response;
  }
);

export const getParcelById = createAsyncThunk(
  "parcels/getParcelById",
  async ({ parcelId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/parcel/${parcelId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const getParcelsWithStatus = createAsyncThunk(
  "parcels/getParcelsWithStatus",
  async ({ parcelId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/dashboard`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const create = createAsyncThunk("parcels/create", async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/v1/shipment`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
});

export const exportSpecificExcel = async ({ shipmentId, excelType }) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/v1/parcel/export`,
    { shipmentId: shipmentId, excelType: excelType },
    {
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: (s) => s <= 500,
      responseType: "blob",
    }
  );

  return response;
};

export const exportStlvExcel = async ({ shipmentId }) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/v1/parcel/export-stlv`,
    { shipmentId: shipmentId },
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );

  return response;
};

export const exportAllToExcelByShipmentId = async ({
  shipmentId,
  excelType,
}) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/v1/parcel/export-all/${shipmentId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );

  return response;
};

export const exportPageRowsToExcel = async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/excel/generate-from-json`,
    { content: payload },
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );

  return response;
};

export const importParcelExcel = createAsyncThunk(
  "parcels/upload",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/excel/import-decisions`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        validateStatus: (s) => s <= 500,
      }
    );

    return response;
  }
);

const parcelsAdapter = createEntityAdapter({});

export const { selectAll: selectParcels, selectById: selectParcelById } =
  parcelsAdapter.getSelectors((state) => state.parcels);

const parcelsSlice = createSlice({
  name: "parcels",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParcelsList.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export const { setShipmentsSearchText } = parcelsSlice.actions;

export default parcelsSlice.reducer;
