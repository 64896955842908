import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../../../store/variablesSlice";
import { sendT1Request } from "../../store/shipmentSlice";
import { useState } from "react";

const T1Dialog = ({ dialogOpen, handleClose, shipmentId, ...props }) => {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.data);
  const companyTypes = useSelector((state) => state.variables.COMPANY_TYPE);
  const [buttonLoading, setButtonLoading] = useState(false);
  const schema = yup.object().shape({
    companyId: yup
      .number()
      .required("Please select a company")
      .moreThan(0, "Please select a valid company"),
  });

  const aeoCompanyTypeId = companyTypes.find((item) => item.value === "AEO");
  const t1Companies = companies.filter(
    (item) => item.companyTypeId === aeoCompanyTypeId.id
  );

  const methods = useForm({
    defaultValues: {
      companyId: 0,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmitT1 = (data) => {
    setButtonLoading(true);
    data.id = parseInt(shipmentId);
    dispatch(sendT1Request(data)).then((res) => {
      setButtonLoading(false);
      dispatch(openSnackbar({ message: `T1 Request sent successfully.` }));
      handleClose(true);
    });
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        methods.reset();
        handleClose(false);
      }}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => handleSubmitT1(data))}
          autoComplete="off"
        >
          <DialogTitle>Send T1 Request</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select AEO company:
            </DialogContentText>
            <div>
              <div>
                <Select
                  // companyId
                  {...methods.register(`companyId`)}
                  defaultValue={0}
                  fullWidth
                  color="secondary"
                  variant="standard"
                  size="small"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value={0}>- Please select -</MenuItem>
                  {[...t1Companies]
                    .sort((a, b) => (a.companyName > b.companyName ? 1 : -1))
                    .map((item, idx) => (
                      <MenuItem key={`companyKey-${idx}`} value={item.id}>
                        {item.companyName}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose(false);
                methods.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                methods.formState.isSubmitting || !methods.formState.isValid || buttonLoading
              }
            >
              {buttonLoading && (
                <CircularProgress size={24} style={{ position: "absolute" }} />
              )}
              Send
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default T1Dialog;
