import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getPickupPlannerList = createAsyncThunk(
  "planner/pickupList",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/planner/pickup`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const setShipmentTrucker = createAsyncThunk(
  "planner/setShipmentTrucker",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/planner/set-trucker`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const mergeShipments = createAsyncThunk(
  "planner/mergeShipments",
  async ({ shipmentIds }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/planner/merge`,
      { ids: shipmentIds },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const unmergeShipments = createAsyncThunk(
  "planner/unmergeShipments",
  async ({ shipmentId }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/planner/unmerge`,
      { id: shipmentId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

const plannerSlice = createSlice({
  name: "planner",
  initialState: {
    pickupPlanner: [],
    ccPlanner: [],
    truckerDialogOpen: false,
    shipmentMergeId: false,
    shippingType: false,
  },
  reducers: {
    setTruckerDialogOpen: (state, action) => {
      console.log("setTruckerDialogOpen action:", action);
      return {
        ...state,
        truckerDialogOpen: true,
        shipmentMergeId: action.payload.shipmentMergeId,
        shippingType: action.payload.shippingType,
      };
    },
    setTruckerDialogClose: (state) => {
      state.truckerDialogOpen = false;
      state.shipmentMergeId = false;
      state.shippingType = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPickupPlannerList.fulfilled, (state, action) => {
      return { ...state, pickupPlanner: action.payload.data };
    });
    builder.addCase(mergeShipments.fulfilled, (state, action) => {
      console.log("action:", action);
      const pickups = state.pickupPlanner.map((shipment) => {
        if (action.meta.arg.shipmentIds.includes(shipment.id)) {
          return {
            ...shipment,
            mergedShipmentId: action.meta.arg.shipmentIds[0],
            mergeColor: action.payload.data.mergeColor,
          };
        }

        return shipment;
      });

      return {
        ...state,
        pickupPlanner: pickups,
      };
    });
    builder.addCase(unmergeShipments.fulfilled, (state, action) => {
      const shipmentItemData = state.pickupPlanner.filter(
        (shipment) => shipment.id === action.meta.arg.shipmentId
      );

      console.log("shipmentItemData:", shipmentItemData);

      const updatedPickupPlanner = state.pickupPlanner.map((shipment) => {
        if (
          shipment.mergedShipmentId === shipmentItemData[0].mergedShipmentId
        ) {
          return { ...shipment, mergedShipmentId: null, mergeColor: null };
        }
        return shipment;
      });

      return { ...state, pickupPlanner: updatedPickupPlanner };
    });
  },
});

export const {
  cleanState,
  addLineHaulBlock,
  addLastMileDeliveryPartnerBlock,
  addShippingToEuPartners,
  setTruckerDialogOpen,
  setTruckerDialogClose,
} = plannerSlice.actions;

export default plannerSlice.reducer;
