import { Box, Button, ListItemIcon, MenuItem } from "@mui/material";
import DataTable from "../../../components/datatable/Datatable";
import { getParcelsByShipmentId } from "../store/shipmentSlice";
import {
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from "material-react-table";
import TransferDialog from "./dialogs/TransferDialog";
import DisconnectDialog from "./dialogs/DisconnectDialog";
import GroupIntoDialog from "./dialogs/GroupIntoDialog";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { AccountCircle, Send } from "@mui/icons-material";
import RenameDialog from "./dialogs/RenameDialog";
import DeleteDialog from "./dialogs/DeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCollectionParcel,
  disconnectCollectionParcel,
  groupingCollectionParcel,
  transferCollectionParcel,
  updateCollectionParcel,
  updateCollectionParcelBulkStatus,
} from "../../cartons/store/collectionParcelAction";
import { openSnackbar } from "../../../store/variablesSlice";
import StatusDialog from "./dialogs/StatusDialog";

const columns = [
  {
    header: "Collection parcel ID",
    accessorKey: "collectionParcelCustomerId",
  },
  {
    header: "Status",
    accessorKey: "statusName",
  },
  {
    header: "Original shipment ID",
    accessorKey: "originalShipmentName",
  },
  {
    header: "Actual shipment ID",
    accessorKey: "actualShipmentName",
  },
  {
    header: "Original pallet ID",
    accessorFn: (row) => `${row.originalPalletName}`,
  },
  {
    header: "Actual pallet  ID",
    accessorFn: (row) => `${row.actualPalletName}`,
  },
  {
    header: "Original carton ID",
    accessorKey: "originalCartonName",
  },
  {
    header: "Actual carton ID",
    accessorKey: "actualCartonName",
  },
  {
    header: "Weight",
    accessorFn: (row) => `${row.totalWeight || 0}`,
  },
  {
    header: "Nr of parcels",
    accessorKey: "nrOfParcels",
  },
  {
    header: "Nr of items",
    accessorKey: "nrOfItems",
  },
  {
    header: "Recipient Name",
    accessorFn: (row) => `${row.recipientName || "-"}`,
  },
  {
    header: "Recipient Country Code",
    accessorFn: (row) => `${row.recipientCountry || "-"}`,
  },
  {
    header: "Recipient City",
    accessorFn: (row) => `${row.recipientCity || "-"}`,
  },
  {
    header: "Last mile delivery partner name",
    accessorFn: (row) => `${row.lastMileDeliveryPartnerName || "-"}`,
  },
];

const CollectionParcelListTable = ({ shipmentId, ...props }) => {
  const dispatch = useDispatch();
  const userAuthData = useSelector((state) => state.auth);
  const ddVariables = useSelector((state) => state.variables);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const [groupIntoDialogOpen, setGroupIntoDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [idsToTransfer, setIdsToTransfer] = useState([]);

  const onSubmitTransfer = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (
        value == null ||
        value === false ||
        value === undefined ||
        value === ""
      ) {
        console.log(`key: ${key} value: ${value}`);
        delete data[key];
      }
    });
    dispatch(transferCollectionParcel({ ...data, ids: idsToTransfer }))
      .then((res) => console.log(res), setTransferDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Collection parcel(s) transferred` }))
      );
    console.log({ ...data, ids: idsToTransfer });
  };

  const onSubmitDisconnect = () => {
    dispatch(disconnectCollectionParcel({ ids: idsToTransfer }))
      .then((res) => console.log(res), setDisconnectDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Collection parcels disconnected` }))
      );
    console.log({ ids: idsToTransfer });
  };

  const onSubmitGroupInto = (data) => {
    dispatch(groupingCollectionParcel({ ...data, ids: idsToTransfer }))
      .then((res) => console.log(res), setGroupIntoDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Collection parcel(s) regrouped` }))
      );
    console.log({ ids: idsToTransfer });
  };

  const onSubmitRename = (data) => {
    dispatch(
      updateCollectionParcel({
        data: { collectionParcelId: data.entityCustomerRefName },
        palletId: idsToTransfer[0],
      })
    )
      .then((res) => console.log(res), setRenameDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Collection parcel renamed` }))
      );
  };

  const onSubmitStatusBulkUpdate = (data) => {
    dispatch(
      updateCollectionParcelBulkStatus({
        status: data.entityStatusId,
        shipmentId: shipmentId,
      })
    )
      .then((res) => console.log(res), setStatusDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Collection parcels status updated` }))
      );
  };

  const onSubmitDelete = () => {
    console.log("ok");
    dispatch(deleteCollectionParcel({ collectionParcelId: idsToTransfer[0] }))
      .then((res) => console.log(res), setDisconnectDialogOpen(false))
      .then(() =>
        dispatch(openSnackbar({ message: `Collection parcel deleted` }))
      );
    console.log({ ids: idsToTransfer });
  };

  const methods = useForm();

  return (
    <>
      <Box width={"100%"}>
        <DataTable
          fetchData={getParcelsByShipmentId}
          endpointUrl={`/api/v1/shipment/${shipmentId}/collection-parcels`}
          predefinedColumns={columns}
          enableRowActions
          positionActionsColumn={"last"}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
          }}
          enableColumnActions={false}
          muiTableBodyRowProps={({ row }) => ({
            //implement row selection click events manually
            onClick: () => {
              console.log("COLLECTION PARCELS id:", JSON.stringify(row));
            },
            sx: {
              cursor: "pointer",
            },
          })}
          renderRowActionMenuItems={({ closeMenu, row }) => {
            const menuItems = [
              <MenuItem
                key={0}
                onClick={() => {
                  // View profile logic...
                  setIdsToTransfer([row.id]);
                  setRenameDialogOpen(true);
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                Rename
              </MenuItem>,
            ];

            if (userAuthData.accessRightId >= 1) {
              menuItems.push(
                <MenuItem
                  key={1}
                  onClick={() => {
                    // View profile logic...
                    setIdsToTransfer([row.id]);
                    setDeleteDialogOpen(true);
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Send />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              );
            }

            return menuItems;
          }}
          renderTopToolbar={({ table }) => {
            const handleTransfer = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setTransferDialogOpen(true);
            };

            const handleDisconnect = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setDisconnectDialogOpen(true);
            };

            const handleGroupIntoNew = (rows) => {
              const rowData = rows.map((row) => row.original);

              setIdsToTransfer(rowData.map((item) => item.id));
              setGroupIntoDialogOpen(true);
            };

            const handleStatusUpdate = (rows) => {
              setStatusDialogOpen(true);
            };

            return (
              <div className="flex gap-2 p-2 justify-end bg-opacity-95">
                <div className="flex">
                  <Button
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleStatusUpdate(table.getSelectedRowModel().rows)
                    }
                  >
                    Status update
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleTransfer(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Transfer
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleGroupIntoNew(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Group into new
                  </Button>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    color="info"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      handleDisconnect(table.getSelectedRowModel().rows)
                    }
                    sx={{ marginRight: 1 }}
                  >
                    Disconnect
                  </Button>
                </div>
                <MrtToggleFiltersButton table={table} />
                <MrtShowHideColumnsButton table={table} />
              </div>
            );
          }}
        />
      </Box>

      <TransferDialog
        methods={methods}
        dialogOpen={transferDialogOpen}
        entityName={`Collection parcels`}
        handleClose={setTransferDialogOpen}
        transferFn={onSubmitTransfer}
        idsToTransfer={idsToTransfer}
        palletAutocompleteAllowed={true}
        cartonAutocompleteAllowed={true}
        collectionParcelAutocompleteAllowed={false}
      />

      <DisconnectDialog
        methods={methods}
        dialogOpen={disconnectDialogOpen}
        entityName={`Collection parcels`}
        handleClose={setDisconnectDialogOpen}
        onSubmitDisconnect={onSubmitDisconnect}
        idsToTransfer={idsToTransfer}
      />

      <GroupIntoDialog
        methods={methods}
        dialogOpen={groupIntoDialogOpen}
        entityName={`Collection parcels`}
        handleClose={setGroupIntoDialogOpen}
        onSubmitGroupInto={onSubmitGroupInto}
        idsToTransfer={idsToTransfer}
        groupOptions={["Pallet", "Carton"]}
      />

      <RenameDialog
        methods={methods}
        dialogOpen={renameDialogOpen}
        entityName={`Collection parcels`}
        handleClose={setRenameDialogOpen}
        onSubmitRename={onSubmitRename}
        idsToTransfer={idsToTransfer}
      />

      <StatusDialog
        methods={methods}
        dialogOpen={statusDialogOpen}
        entityName={`Collection parcels`}
        handleClose={setStatusDialogOpen}
        onSubmitStatusBulkUpdate={onSubmitStatusBulkUpdate}
        idsToTransfer={idsToTransfer}
        shipmentStatuses={ddVariables.SHIPMENT_STATUS}
      />

      <DeleteDialog
        methods={methods}
        dialogOpen={deleteDialogOpen}
        entityName={`Collection parcels`}
        handleClose={setDeleteDialogOpen}
        onSubmitDelete={onSubmitDelete}
        idsToTransfer={idsToTransfer}
      />
    </>
  );
};

export default CollectionParcelListTable;
