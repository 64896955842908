import {
  Icon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { memo } from "react";

const MenuItemButton = ({ url, icon, label, ...props }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={url}
        className={pathname === url ? "active" : ""}
      >
        <ListItemIcon>
          {icon ? (
            <Icon
              className="material-symbols-outlined"
              sx={{ color: "#fff", fontSize: 20 }}
            >
              {icon}
            </Icon>
          ) : null}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
};

export default memo(MenuItemButton);
