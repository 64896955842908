import withRouter from "../../services/withrouter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getParcelsWithStatus } from "./store/dashboardSlice";
import { InputLabel } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Settings } from "luxon";
import MuiAutoComplete from "../../components/autocomplete/Autocomplete";
import { getAllShipment } from "../shipments/store/shipmentSlice";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { getAllExportingCompany } from "../exporting-companies/store/exportingCompaniesSlice";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

// Set Luxon Adapter timezone to user local TZ
Settings.defaultZone = timeZone;

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const shipmentsStatuses = useSelector(
    (state) => state?.variables?.SHIPMENT_STATUS
  );
  const parcelStats = useSelector((state) => state.dashboard.stats);
  const [loading, setLoading] = useState(true);

  const defaultValues = {
    dateFrom: null,
    dateTo: null,
    shipmentId: null,
    exportingCompanyId: null,
    awb: null,
  };

  const methods = useForm({ defaultValues });

  const shipmentId = useWatch({
    control: methods.control,
    name: "shipmentId",
  });

  const exportingCompanyId = useWatch({
    control: methods.control,
    name: "exportingCompanyId",
  });
  
  const awb = useWatch({
    control: methods.control,
    name: "awb",
  });

  const dateFrom = useWatch({
    control: methods.control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control: methods.control,
    name: "dateTo",
  });

  useEffect(() => {
    if (shipmentsStatuses) {
      dispatch(getParcelsWithStatus()).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, shipmentsStatuses]);

  const generatedRows = () => {
    return parcelStats.map((item, idx) => {
      const bgColor = item?.properties?.color
        ? `bg-[${item.properties.color}]`
        : null;

      return (
        <div
          key={`db-stat-${idx}`}
          className={`flex w-full stat-row ${bgColor ? bgColor : null}`}
        >
          <div className="min-w-96">{item.status}</div>
          <div className="min-w-[2rem] text-right">{item.count}</div>
          <div className="percentage ml-12 min-w-[3rem] text-right">
            {parseFloat(item.percent).toFixed(2)}%
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    dispatch(
      getParcelsWithStatus({
        shipmentId: shipmentId || awb,
        exportingCompanyId: exportingCompanyId,
        dateFrom: dateFrom,
        dateTo: dateTo,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, shipmentId, dateFrom, dateTo, exportingCompanyId, awb]);

  if (loading || !shipmentsStatuses) return null;

  return (
    <div className="block">
      <div>Dashboard</div>
      <div className="flex shadow-md text-base mt-8 p-4">
        <div className="">
          <div className="filters">
            <FormProvider {...methods}>
              <form autoComplete="off">
                <div className="relative flex">
                  <div className="relative mb-5 mr-2">
                    <InputLabel className="mb-2 !text-[0.8rem]">{"Date from"}</InputLabel>
                    <Controller
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterLuxon}
                          adapterLocale={navigator.language}
                        >
                          <DatePicker
                            {...field}
                            variant="standard"
                            fullWidth
                            // sx={{ width: 260 }}
                            slotProps={{
                              textField: {
                                size: "small",
                              },
                            }}
                            label={`${timeZone} time`}
                          />
                        </LocalizationProvider>
                      )}
                      control={methods.control}
                      name="dateFrom"
                    />
                  </div>

                  <div className="relative mb-5">
                    <InputLabel className="mb-2 !text-[0.8rem]">{"Date to"}</InputLabel>
                    <Controller
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterLuxon}
                          adapterLocale={navigator.language}
                        >
                          <DatePicker
                            {...field}
                            variant="standard"
                            fullWidth
                            slotProps={{
                              textField: {
                                size: "small",
                              },
                            }}
                            label={`${timeZone} time`}
                          />
                        </LocalizationProvider>
                      )}
                      control={methods.control}
                      name="dateTo"
                    />
                  </div>
                </div>

                <div className="relative mb-5 flex">
                  <MuiAutoComplete
                    label={"Shipment"}
                    fetchData={getAllShipment}
                    elementFormId={"shipmentId"}
                    optionId={"id"}
                    optionValue={"shipmentId"}
                    size={"small"}
                  />
                </div>

                <div className="relative mb-5 flex">
                  <MuiAutoComplete
                    label={"AWB"}
                    fetchData={getAllShipment}
                    elementFormId={"awb"}
                    optionId={"id"}
                    optionValue={"customerShipmentReferenceId"}
                    size={"small"}
                  />
                </div>

                <div className="relative mb-5 flex">
                  <MuiAutoComplete
                    label={"Customer"}
                    fetchData={getAllExportingCompany}
                    elementFormId={"exportingCompanyId"}
                    optionId={"id"}
                    optionValue={"companyName"}
                    size={"small"}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
          <div className="flex w-full">
            <div className="min-w-96"></div>
            <div className="min-w-[2rem] text-right"></div>
            <div className="percentage ml-12 min-w-[3rem] text-right"></div>
          </div>
          <div className="flex w-full stat-row">
            <div className="min-w-96"></div>
            <div className="min-w-[2rem] text-center">TOTAL</div>
            <div className="percentage ml-12 min-w-[3rem] text-center">
              TOTAL
            </div>
          </div>
          {generatedRows()}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Dashboard);
