import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useYupValidationResolver } from "../../../../shared-components/yupResolver";

const GroupIntoDialog = ({
  dialogOpen,
  handleClose,
  onSubmitGroupInto,
  entityName,
  idsToTransfer,
  groupOptions,
  ...props
}) => {
  const schema = yup.object().shape({
    groupEntityCustomerRefName: yup
      .string()
      .required("You must enter a reference name")
      .min(5, "The reference name must be at least 5 characters"),
  });

  const methods = useForm({
    resolver: useYupValidationResolver(schema),
  });

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        methods.reset();
        handleClose(false);
      }}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => onSubmitGroupInto(data))}
          autoComplete="off"
        >
          <DialogTitle>Group into new</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Warning: this action will create new entity (shipment, carton,
              etc.) and assign selected {entityName} to it
              <br />
              {entityName}(s) ({idsToTransfer.join(",")}) will be grouped.
            </DialogContentText>
            <div>
              <div>
                <InputLabel>{"Select parent element type"}</InputLabel>
                <Select
                  {...methods.register(`groupEntityType`)}
                  fullWidth
                  color="secondary"
                  variant="standard"
                  size="small"
                  defaultValue={groupOptions[0]}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {groupOptions.map((item, idx) => (
                    <MenuItem key={`statusKey-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <TextField
                  {...methods.register(`groupEntityCustomerRefName`)}
                  error={!!methods.formState.errors.groupEntityCustomerRefName}
                  helperText={
                    methods.formState.errors.groupEntityCustomerRefName?.message
                  }
                  fullWidth
                  label={`Entity reference id`}
                  placeholder="Eg: CAR-12345"
                  variant="standard"
                  type="text"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose(false);
                methods.reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Group</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default GroupIntoDialog;
