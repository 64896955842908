import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const transferCollectionParcel = createAsyncThunk(
  "collectionParcel/transfer",
  async (payload) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/collection-parcel/transfer`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const disconnectCollectionParcel = createAsyncThunk(
  "collectionParcel/disconnect",
  async (payload) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/collection-parcel/disconnect`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const groupingCollectionParcel = createAsyncThunk(
  "collectionParcel/grouping",
  async (payload) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/collection-parcel/grouping`,
      {
        collectionParcelsData: {
          ids: payload.ids,
        },
        groupEntityType: payload.groupEntityType,
        groupEntityCustomerRefName: payload.groupEntityCustomerRefName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updateCollectionParcel = createAsyncThunk(
  "collectionParcel/update",
  async ({ data, collectionParcelId }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/collection-parcel/${collectionParcelId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const deleteCollectionParcel = createAsyncThunk(
  "collectionParcel/delete",
  async ({ collectionParcelId }) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_APIURL}/api/v1/collection-parcel/${collectionParcelId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updateCollectionParcelBulkStatus = createAsyncThunk(
  "collectionParcel/updateCollectionParcelBulkStatus",
  async ({ shipmentId, status }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/collection-parcel/bulk-status`,
      {
        actualShipmentId: shipmentId,
        status: status,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);
