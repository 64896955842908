import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getParcelsWithStatus = createAsyncThunk(
  "parcels/getParcelsWithStatus",
  async (_, { getState }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/shipment/dashboard`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: _,
      }
    );

    const otherStateData = getState().variables.SHIPMENT_STATUS;

    const sortedArray = [...otherStateData];
    sortedArray.sort((a, b) => a.ordering - b.ordering);

    const statusWithData = [];
    if (response.data) {
      for (const status of sortedArray) {
        const filteredData = response.data.filter((item) => item.status === status.id)?.[0];

        statusWithData.push({
          status: status.value,
          count: filteredData?.package_count ? filteredData?.package_count : 0,
          percent: filteredData?.package_count ? filteredData?.package_count / filteredData.sumParcels * 100 : 0,
          properties: status.properties ? JSON.parse(status.properties) : null,
        })
      }
    }

    response.data = statusWithData;

    return response;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    stats: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParcelsWithStatus.fulfilled, (state, action) => {
      return { ...state, stats: action.payload.data };
    });
  },
});

export default dashboardSlice.reducer;
