import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const LastMileDeliveryPartnerBlock = ({
  partnerId,
  companies,
  locations,
  partnerType,
  ...props
}) => {
  const [contactPersonName, setContactPersonName] = useState(
    props?.partnerData.contactPersonName || ""
  );
  const [contactPersonPhone, setContactPersonPhone] = useState(
    props?.partnerData.contactPersonPhone || ""
  );
  const [contactPersonEmail, setContactPersonEmail] = useState(
    props?.partnerData.contactPersonEmail || ""
  );

  const { register } = useFormContext();

  const shipperCompanyId = useWatch({
    name: `${partnerType}[${partnerId}].shipperCompanyId`,
  });

  useEffect(() => {
    const companyData = companies.filter(
      (item) => item.id === shipperCompanyId
    )[0];

    if (companyData) {
      setContactPersonName(companyData.contactPersonName);
      setContactPersonPhone(companyData.contactPersonPhone);
      setContactPersonEmail(companyData.contactPersonEmail);
    }
  }, [companies, shipperCompanyId]);

  return (
    <Box>
      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Volume"}</InputLabel>
        </div>
        <TextField
            {...register(`${partnerType}[${partnerId}].totalNrAllocatedParcels`)}
            defaultValue={props.partnerData.totalNrAllocatedParcels || 0}
            fullWidth
            variant="standard"
            type="text"
          />
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Volume"}</InputLabel>
        </div>
        ... kg
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Label colour"}</InputLabel>
        </div>
        <TextField
            {...register(`${partnerType}[${partnerId}].labelColour`)}
            defaultValue={props.partnerData.labelColour || ""}
            fullWidth
            variant="standard"
            type="text"
          />
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Last mile delivery partner name"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Select
            {...register(
              `${partnerType}[${partnerId}].shipperCompanyId`
            )}
            fullWidth
            color="secondary"
            variant="standard"
            size="small"
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            <MenuItem value={0}>Please select</MenuItem>
            {companies.map((item, idx) => (
              <MenuItem key={`company-${idx}`} value={item.id}>
                {item.companyName}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Delivery countries"}</InputLabel>
        </div>
        <div className="w-7/12">
          <Select
            {...register(
              `${partnerType}[${partnerId}].deliveryCountries`
            )}
            defaultValue={[0]}
            fullWidth
            color="secondary"
            variant="standard"
            size="small"
            multiple
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            <MenuItem value={0}>Please select</MenuItem>
            {companies.map((item, idx) => (
              <MenuItem key={`company-${idx}`} value={item.id}>
                {item.companyName}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Contact person name"}</InputLabel>
        </div>
        <Typography>{contactPersonName}</Typography>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Contact person email"}</InputLabel>
        </div>
        <Typography>{contactPersonEmail}</Typography>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Contact person phone"}</InputLabel>
        </div>
        <Typography>{contactPersonPhone}</Typography>
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Regional delivery depot locations"}</InputLabel>
        </div>
        Regional delivery depot locations
      </div>

      <div className="relative mb-5 flex">
        <div className="w-5/12 self-center">
          <InputLabel>{"Delivery depot locations"}</InputLabel>
        </div>
        Delivery depot locations
      </div>
    </Box>
  );
};

export default LastMileDeliveryPartnerBlock;
