import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getParcelById = createAsyncThunk(
  "parcel/getById",
  async ({ parcelId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/parcel/${parcelId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const create = createAsyncThunk("parcel/create", async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_APIURL}/api/v1/parcel`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
});

export const update = createAsyncThunk(
  "parcel/update",
  async ({ payload, parcelId }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/parcel/${parcelId}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const transfer = createAsyncThunk("parcel/transfer", async (payload) => {
  const response = await axios.put(
    `${process.env.REACT_APP_APIURL}/api/v1/parcel/transfer`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
});

export const disconnect = createAsyncThunk(
  "parcel/disconnect",
  async (payload) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/parcel/disconnect`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const grouping = createAsyncThunk("parcel/grouping", async (payload) => {
  const response = await axios.put(
    `${process.env.REACT_APP_APIURL}/api/v1/parcel/grouping`,
    {
      parcelsData: {
        ids: payload.ids,
      },
      groupEntityType: payload.groupEntityType,
      groupEntityCustomerRefName: payload.groupEntityCustomerRefName,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
});

export const uploadDocument = createAsyncThunk(
  "parcel/upload",
  async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/api/v1/file`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  }
);

export const deleteFileByHash = createAsyncThunk(
  "parcel/deleteFileByHash",
  async ({ fileHash }) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_APIURL}/api/v1/file/${fileHash}`,
      {}
    );

    return response;
  }
);

export const getParcelFiles = createAsyncThunk(
  "parcel/getParcelFiles",
  async ({ parcelId }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/v1/parcel/${parcelId}/files`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

export const updateParcelBulkStatus = createAsyncThunk(
  "parcel/updateParcelBulkStatus",
  async ({ shipmentId, status }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/api/v1/parcel/bulk-status`,
      {
        actualShipmentId: shipmentId,
        status: status,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  }
);

const parcelSlice = createSlice({
  name: "parcel",
  initialState: null,
  reducers: {
    files: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getParcelById.fulfilled, (state, action) => {
      return action.payload.data;
    });
    builder.addCase(update.fulfilled, (state, action) => {
      return action.payload.data;
    });
    builder.addCase(getParcelFiles.fulfilled, (state, action) => {
      return { ...state, files: action.payload.data };
    });
    builder.addCase(deleteFileByHash.fulfilled, (state, action) => {
      return {
        ...state,
        files: state.files.filter(
          (file) => file.fileHash !== action.meta.arg.fileHash
        ),
      };
    });
  },
});

export default parcelSlice.reducer;
